import {
    DocAssistantAPInstance
} from "../../../../../components/api";
import {
    GUI
} from "../../../GrapUI.config.js";
import {
    SlideUpload,
    SlidesGallery,
} from "../app.gh.js";


// Create a new store instance.
export const SlidesStore = {
    namespaced: true,
    state() {
        return {
            activeSlideId: undefined,
            slides: [],
            pagination: {
                page: 1,
                pageSize: 10,
            },
            filter: {
                search: "",
                typeId: "",
            },
            // Timeouts
            updateTimeout: undefined,
            loadingMap: new Map()
        }
    },

    mutations: {
        setSlides(state, {
            items,
            reset
        } = {}) {
            if (reset)
                state.slides = items;

            else
                items.forEach(slide => {
                    const slideIndex = state.slides.findIndex(cs => cs.id === slide.id);

                    if (slideIndex !== -1)
                        state.slides.splice(slideIndex, 1, slide)
                    else
                        state.slides.push(slide)
                });
        },
        setPagination(state, pagination) {
            state.pagination = pagination;
        },
        setFilter(state, filter) {
            state.filter = filter;
        }
    },
    actions: {
        openSlidesGallery(props, {
            from,
        }) {
            GUI.scene.add(from, SlidesGallery);
        },
        openSlideCreation(props, {
            from,
        }) {
            GUI.scene.add(from, SlideUpload);
        },
        // Slides API 
        async GetSlidesList({
            commit,
            state
        }, {
            pagination,
            filter,
            reset,
            next
        } = {}) {
            if (reset) {
                pagination = pagination ? pagination : {}
                filter = filter ? filter : {}
            } else {
                pagination = pagination ? pagination : state.pagination
                filter = filter ? filter : state.filter
            }

            if (next)
                pagination.page++;


            const resp = await DocAssistantAPInstance
                .Slides
                .list(
                    pagination,
                    filter);

            commit('setSlides', {
                items: resp.items,
                reset
            });

            commit('setPagination', resp.pagination);
            commit('setFilter', filter);
        },
        async CreateNewSlide({
            dispatch
        }, {
            // from,
            newSlide
        }) {


            const formData = new FormData();
            formData.append('name', newSlide.name);

            if (newSlide.description)
                formData.append('description', newSlide.description);
            if (newSlide.tags && newSlide.tags.length)
                formData.append('tags', JSON.stringify(newSlide.tags));

            formData.append('file', newSlide.file.file);
            formData.append('fileMeta', JSON.stringify({
                ext: newSlide.file.ext,
                size: newSlide.file.size,
                sizeText: newSlide.file.sizeText,
                type: newSlide.file.type,
            }));


            // const slide =
            await DocAssistantAPInstance
                .Slides
                .create(formData, {
                    toast: {
                        message: "Slide Created",
                    },
                });



            dispatch("GetSlidesList", {
                reset: true
            });




            // GUI.scene.add(from, SlideDetails, {
            //     slideId: slide.id
            // });
        },
        async GetSlide({
            commit,
            state,
            getters
        }, {
            id,
            refresh
        }) {

            switch (true) {

                // when  loading in progress
                // -> just wait 
                case state.loadingMap.get(id) === 'LOADING': {
                    await new Promise((resolve) => {
                        let i = 0;

                        const handler = () => {
                            if (state.loadingMap.get(id) === 'COMPLETED') {
                                return resolve()
                            } else
                                setTimeout(handler, ++i + 100);
                        }

                        setTimeout(handler, 100);
                    });


                    break;
                }

                // when loaded and Cache Exists
                // -> return existed cache 
                case state.loadingMap.get(id) === 'COMPLETED' && !refresh: {
                    break;
                }

                // when  haven't been ever loaded
                //   OR
                // when  loaded but cache requires refresh
                default: {
                    state.loadingMap.set(id, 'LOADING');

                    const slide = await DocAssistantAPInstance.Slides
                        .get(id);

                    commit('setSlide', slide)

                    state.loadingMap.set(id, 'COMPLETED');

                    break;
                }
            }

            return getters.slideById(id);
        },

        async UpdateSlide({
            state,
            dispatch,
        }, {
            slide
        }) {


            if (state.updateTimeout)
                clearTimeout(state.updateTimeout)

            state.updateTimeout = setTimeout(async () => {
                // commit('setSlide', slide);

                await DocAssistantAPInstance.Slides.update(slide, {
                    toast: {
                        message: "Slide Updated",
                    },
                });
                dispatch('GetSlide', {
                    id: slide.id,
                    refresh: true
                });

                // commit('setSlide', updatedSlide)
            }, 1000);

        },

        async DeleteSlide({
            dispatch
        }, slide) {
            await DocAssistantAPInstance.Slides.delete(slide, {
                toast: {
                    message: "Slide Updated",
                },
            });

            dispatch('GetSlide', {
                id: slide.id,
                refresh: true
            });
        },

    }
}
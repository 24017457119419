import Vue from "vue";
import {
    FileAPIInstance
} from "../../../../../components/api";
import {
    GUI
} from "../../../GrapUI.config.js";
import {
    FolderFilePreview,
} from "../app.gh.js";


// Create a new store instance.
export const FileStore = {
    namespaced: true,
    state() {
        return {
            activeFileId: undefined,
            files: {},

            // Timeouts
            updateTimeout: undefined,
            loading: false,
            loadingMap: new Map()
        }
    },

    mutations: {
        setFile(state, {
            file,
        }) {
            Vue.set(state.files, file.id, file)
        },
        setActiveId(state, fileId) {
            state.activeFileId = fileId;
        }
    },
    actions: {
        openFileDetails({
            commit
        }, {
            from,
            file
        }) {

            const fromParams = from.relation && from.relation.params ? {
                ...from.relation.params
            } : undefined


            if (fromParams && fromParams.fromParams)
                delete fromParams.fromParams

            GUI.scene.add(from, FolderFilePreview, {
                fileId: file.id,
                u_type: file.u_type,
                fromParams
            });

            commit('setActiveId', file.id)
        },
        async uploadFile({
            dispatch,
            state
        }, {
            folderId,
            newFile
        }) {
            state.loading = true
            const formData = new FormData();
            formData.append('folderId', folderId);
            formData.append('file', newFile);


            try {
                await FileAPIInstance
                    .upload(formData, {
                        toast: {
                            message: "File Created",
                        },
                    });

                dispatch('FolderStore/GetFolder', {
                    id: folderId,
                    refresh: true
                }, {
                    root: true
                });

            } catch (error) {
                console.log('Error: ', error)
            }

            state.loading = false


        },
        async GetFile({
            commit,
            state,
        }, {
            id,
            u_type,
            refresh,
        }) {

            if (!id)
                id = 'home';

            switch (true) {

                // when  loading in progress
                // -> just wait 
                case state.loadingMap.get(id) === 'LOADING': {
                    await new Promise((resolve) => {
                        let i = 0;

                        const handler = () => {
                            if (state.loadingMap.get(id) === 'COMPLETED') {
                                return resolve()
                            } else
                                setTimeout(handler, ++i + 100);
                        }

                        setTimeout(handler, 100);
                    });


                    break;
                }

                // when loaded and Cache Exists
                // -> return existed cache 
                case state.loadingMap.get(id) === 'COMPLETED' && !refresh: {
                    break;
                }

                // when  haven't been ever loaded
                //   OR
                // when  loaded but cache requires refresh
                default: {
                    state.loadingMap.set(id, 'LOADING');
                    const file = await FileAPIInstance
                        .get(u_type, id);


                    commit('setFile', {
                        file,
                    })

                    state.loadingMap.set(id, 'COMPLETED');

                    break;
                }
            }
        },

        async UpdateFile({
            state,
            dispatch,
        }, {
            u_type,
            file
        }) {
            if (state.updateTimeout)
                clearTimeout(state.updateTimeout)

            state.updateTimeout = setTimeout(async () => {
                // commit('setFile', file);

                await FileAPIInstance.update(u_type, file, {
                    toast: {
                        message: "File Updated",
                    },
                });
                dispatch('GetFile', {
                    id: file.id,
                    refresh: true
                });

                // commit('setFile', updatedFile)
            }, 1000);

        },

        async DeleteFile({
            dispatch
        }, {
            file,
            u_type
        }) {
            await FileAPIInstance.delete(u_type, file, {
                toast: {
                    message: "File Updated",
                },
            });

            dispatch('GetFile', {
                id: file.id,
                refresh: true
            });
        },

    }
}
<template>
  <v-card color="transparent elevation-0 " :dark="dark">

    <h2 class="sign-in-name">{{ name }}</h2>
    <v-card-text>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-text-field
          v-model="email"
          :rules="emailRules"
          prepend-icon="icon-profile-male"
          label="E-mail"
          required
          class="sign-in-input"
        ></v-text-field>
        <v-text-field
          v-model="password"
          :rules="passwordRules"
          prepend-icon="icon-lock"
          label="Password"
          type="password"
          required
          class="sign-in-input"
        ></v-text-field>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        :disabled="!valid || pLoading"
        color="primary"
        class="mr-4"
        @click="validate"
        :loading="pLoading"
      >
        Sign In
      </v-btn>
    </v-card-actions>
  </v-card>
</template>



<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
      default: true,
    },
    name: {
      type: String,
      default: "Customer Portal",
    },
  },
  data() {
    return {
      pLoading: this.loading,

      valid: true,
      password: "",
      email: "",
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) =>
          (v && v.length >= 8) || "Password must be more than 8 characters",
      ],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
    };
  },

  methods: {
    validate() {
      const t = this.$refs.form.validate();
      if (t)
        this.$emit("onSubmit", {
          password: this.password,
          email: this.email,
        });
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
  watch: {
    loading(newVal) {
      this.pLoading = newVal;
    },
  },
};
</script>




<style lang="scss" scoped>
.sign-in-name {
  display: flex;
  justify-content: center;

  font-weight: 300;
  letter-spacing: 0.3em;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 20px;
}

.sign-in-input{

  &:focus{
    color: #000!important;
  }
  &:active{
    color: #000!important;
  }
}
</style>
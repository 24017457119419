// import Vue from "vue"
import {
    UserAPIInstance,
    AuthAPIInstance
} from "../../../components/api"
import {
    AuthHelper
} from "../../../components/helpers/auth.helper"

// Create a new store instance.
export const MainStore = {
    namespaced: true,
    state() {
        return {
            user: {
                profile: {},
                settings: {}
            },
            token: undefined,
            systemAlert: undefined,
            isSplash: false
        }
    },
    mutations: {
        increment(state) {
            state.count++
        },
        setUser(state, user) {
            state.user = user;
            // Object.assign(state.user, user);
        },
        resetUser(state) {
            state.user = {
                profile: {}
            }
        },
        setToken(state, token) {
            state.token = token;
        },
        resetToken(state) {
            state.token = undefined;
        },
        setSplash(state, isSplash) {
            state.isSplash = isSplash;
        },
        setSystemAlert(state, alert) {
            state.systemAlert = alert
        }
    },
    getters: {
        availableApps(state) {
            return state.user && state.user.availableApps ? state.user.availableApps : []
        },
        availableViews(state) {
            return state.user && state.user.availableViews ? state.user.availableViews : []
        }
    },
    actions: {
        async getUser({
            commit,
            dispatch
        }) {
            let user = await AuthHelper.getUser()

            //just get updated info
            if (user)
                user = await dispatch('getUserFromAPI', {
                    userId: user.id
                })

            commit('setUser', user)

            return user
        },

        async updateUser({
            dispatch
        }, updateUser) {
            //just get updated info
            await UserAPIInstance.update(updateUser, {
                toast: {
                    message: 'Your preferences updated.'
                }
            })

            const user = await dispatch('getUserFromAPI', {});

            return user;
        },

        async getToken({
            commit
        }) {

            const token = await AuthHelper.getToken()

            commit('setToken', token)

            return token
        },

        async getUserFromAPI({
            state,
            commit
        }, {
            userId
        } = {}) {
            const user = await UserAPIInstance.get(userId ? userId : state.user.id);

            AuthHelper.setUser(user);
            commit('setUser', user);

            return user;
        },


        async uploadUserAvatar({
            state,
            dispatch
        }, {
            formData
        }) {
            await UserAPIInstance.ProfileAPI.uploadAvatar(state.user, formData, {
                toast: {
                    message: 'Avatar Updated.'
                },

            });

            dispatch('getUserFromAPI');
        },

        async setAuth({
            commit
        }, {
            user,
            token
        }) {
            await AuthHelper.setToken(token);
            await AuthHelper.setUser(user);

            commit('setUser', user);
            commit('setToken', token)


        },
        async showSplash({
            commit
        }) {
            commit('setSplash', true);
            setTimeout(() => {
                commit('setSplash', false);
            }, 6000);
        },

        async logIn({
            dispatch,
        }, credentials) {
            const resp = await AuthAPIInstance.signIn(credentials, {
                toast: {
                    message: 'Success. Sign In completed.'
                }
            });

            await dispatch('setAuth', {
                user: resp.user,
                token: resp.token
            });

            await dispatch('showSplash')


        },

        async logOut({
            commit
        }) {
            await AuthHelper.setToken();
            await AuthHelper.setUser();

            commit('resetUser');
            commit('resetToken');

        },
        async signUpInvite({
            dispatch
        }, credentials) {

            const {
                user,
                token
            } = await AuthAPIInstance.signUpInvite(credentials);

            await dispatch('setAuth', {
                user,
                token
            });

            return user;
        }
    }
}
import {
    API
} from '../Api.class';


export class GUIAPI extends API {

    constructor(baseUrl) {
        super(baseUrl)
    }


    async apps(pagination, filter, meta) {
        this.loading = true

        return await this.__axiosInstance.get('api/v1/gui/apps', {
            meta,
            params: {
                ...pagination,
                ...filter
            }
        })

    }

    async views(pagination, filter, meta) {
        this.loading = true

        return await this.__axiosInstance.get('api/v1/gui/views', {
            meta,
            params: {
                ...pagination,
                ...filter
            }
        })

    }
    async permissions(pagination, filter, meta) {
        this.loading = true

        return await this.__axiosInstance.get('api/v1/gui/permissions', {
            meta,
            params: {
                ...pagination,
                ...filter
            }
        })

    }
}
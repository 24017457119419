import {
    API
} from '../Api.class';


export class ExperienceNoteAPI extends API {

    constructor(baseUrl) {
        super(baseUrl)

    }


    async list(pagination, filter, meta) {
        this.loading = true

        return await this.__axiosInstance.get('api/v1/knowledge-base/experience-notes', {
            meta,
            params: {
                ...pagination,
                ...filter
            }
        })

    }

    async get(id, meta) {
        this.loading = true

        return await this.__axiosInstance.get(`api/v1/knowledge-base/experience-notes/${id}`, {
            meta
        });

    }


    async create(body, meta) {
        this.loading = true;

        return await this.__axiosInstance.post('api/v1/knowledge-base/experience-notes', body, {
            meta
        });
    }


    async update(link, meta) {
        this.loading = true

        return await this.__axiosInstance.put(`api/v1/knowledge-base/experience-notes/${link.id}`, link, {
            meta
        });
    }

    async delete(link, meta) {
        this.loading = true

        return await this.__axiosInstance.delete(`api/v1/knowledge-base/experience-notes/${link.id}`, {
            meta
        });

    }

}
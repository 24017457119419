import {
    API
} from './Api.class';



export class AuthAPI extends API {

    constructor(baseUrl) {
        super(baseUrl)

    }


    async validateToken(token) {
        this.loading = true

        return await this.__axiosInstance.post(`api/v1/auth/token/verify`, {
            token
        });

    }

    async validateInvite(invite) {
        this.loading = true

        return await this.__axiosInstance.post(`api/v1/auth/invite/verify`, {
            invite
        });

    }

    async refreshToken(token) {
        this.loading = true

        return await this.__axiosInstance.post(`api/v1/auth/token/refresh`, {
            token
        });

    }


    async signIn(credentials, meta) {
        this.loading = true

        console.log('credentials: ', credentials)

        return await this.__axiosInstance.post(`api/v1/auth/sign-in`, credentials, {
            meta
        });

    }

    async signUpInvite(credentials, meta) {
        this.loading = true

        return await this.__axiosInstance.post(`api/v1/auth/invite/sign-up`, credentials, {
            meta
        });

    }


    async checkPermissions(permissions, user, meta) {
        this.loading = true

        return await this.__axiosInstance.post(`api/v1/permissions/check`, {
            userId: user.id,
            permissions
        }, {
            meta
        });

    }

}
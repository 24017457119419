<template>
  <div class="app-icon-container">
    <v-btn
      :id="'app-bar-' + value.key"
      @click="() => $emit('click')"
      @mouseover="
        () => {
          tooltip = true;
          $emit('mouseover', value);
        }
      "
      @mouseleave="
        () => {
          tooltip = false;
          $emit('mouseleave', value);
        }
      "
      class="mx-2 app-icon"
      dark
      small
      :color="value.display.color ? value.display.color : 'transparent app-icon'"
    >
      <v-icon dark>
        {{ value.display.icon }}
      </v-icon>
    </v-btn>
  </div>
</template>


<script>
export default {
  name: "AppIcon",
  props: {
    value: {
      default: () => ({}),
    },
    badge: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tooltip: false,
      user: {},
    };
  },
};
</script>


<style lang="scss" scoped>
.app-icon-container {
  display: flex;
  align-items: center;
  .app-icon {
    border-radius: 14px;
    transition: all 0.2s;
    -webkit-transition: all 0.2s;

    height: 50px;
    width: 50px;
    backdrop-filter: blur(30px);
    background: radial-gradient(
      circle,
      rgba(255, 255, 255, 0.1) 0%,
      rgba(255, 255, 255, 0.1) 50%,
      rgba(255, 255, 255, 0.1) 63%
    );

    &:hover {
      transform: scale(1.1);
    }
  }
  .views-options {
    padding: 20px;
    position: absolute;
    left: 50%;
    bottom: 60px;
    border: 1px solid;
    border-radius: 20px;
    backdrop-filter: blur(30px);
    display: flex;
    flex-direction: row;
  }
}
</style>
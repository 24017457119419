var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex- d-col justify-center"},[_c('v-expand-transition',{attrs:{"leave-absolute":""}},[(_vm.hovered && _vm.hoveredApp)?_c('div',{key:_vm.hoveredApp ? _vm.hoveredApp.display.name : 'key',staticClass:"app-description"},[_c('h3',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.hoveredApp.display.name))])]):_vm._e()]),_c('div',{staticClass:"desktop-form-container",class:{}},[_c('div',{staticClass:"app-loading-finish",class:{}}),_c('ul',{staticClass:"app-selection-container pa-0"},[_c('v-slide-x-transition',[(_vm.selectedApp)?_c('li',{key:'default',staticClass:"app-list app-selection-col"},[_c('AppIcon',{attrs:{"value":_vm.selectedApp},on:{"click":function($event){return _vm.onAppClose(_vm.selectedApp)}}})],1):_vm._e()]),_c('v-slide-x-transition',[(_vm.selectedApp)?_c('li',{key:'icon',staticClass:"app-list app-selection-col"},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-chevron-double-right ")])],1):_vm._e()]),_c('v-slide-x-reverse-transition',{attrs:{"leave-absolute":""}},[(_vm.windowWidth > 600 && !_vm.systemAlert)?_c('v-slide-x-reverse-transition',{staticClass:"app-selection-container-list pa-0",attrs:{"name":"app-list","group":"","tag":"ul"}},_vm._l((_vm.apps),function(app){return _c('li',{key:app.key,staticClass:"app-list app-selection-col"},[_c('AppIcon',{attrs:{"value":app},on:{"mouseover":(value) => {
                  _vm.hoveredApp = value;
                  _vm.hovered = true;
                },"mouseleave":() => {
                  _vm.hoveredApp = undefined;
                  _vm.hovered = false;
                },"&click":function($event){return (() => (_vm.selectedApp ? _vm.onViewSelected(app) : _vm.onAppSelected(app))).apply(null, arguments)}}})],1)}),0):(_vm.windowWidth <= 600 && !_vm.systemAlert)?_c('AppIcon',{staticClass:"master-icon",attrs:{"value":{
            display: {
              icon: 'mdi-square-rounded-outline',
            },
          }}}):(_vm.systemAlert)?_c('v-row',{staticClass:"alert-row pa-0 ma-0"},[_c('v-col',{staticClass:"pa-0 ma-0"},[_c('v-alert',{staticClass:"ma-0 py-0 system-alert",attrs:{"dense":"","text":"","prominent":"","type":_vm.systemAlert.type}},[_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"align":"center"}},[_c('v-col',{staticClass:"grow"},[_vm._v(" "+_vm._s(_vm.systemAlert.message)+" ")]),(false)?_c('v-col',{staticClass:"shrink pa-0"},[_c('v-btn',[_vm._v("Take action")])],1):_vm._e()],1)],1)],1)],1):_vm._e()],1),_c('v-slide-x-reverse-transition',[_c('li',{key:'default-2',staticClass:"profile app-list app-selection-col"},[_c('div',{staticClass:"profile-chip",on:{"click":function($event){return _vm.$emit('onProfileOpen')}}},[_c('v-avatar',[_c('img',{style:({
                  backgroundColor:
                    _vm.user.profile.avatar && _vm.user.profile.avatar.url
                      ? 'white'
                      : _vm.user.profile.color,
                }),attrs:{"src":_vm.user.profile.avatar && _vm.user.profile.avatar.url
                    ? _vm.user.profile.avatar.url
                    : _vm.user.profile.avatarURL,"lazy-src":_vm.user.profile.avatar && _vm.user.profile.avatar.url
                    ? _vm.user.profile.avatar.url
                    : _vm.user.profile.avatarURL,"alt":"User"}})]),_c('span',{staticClass:"user-name"},[_vm._v(" "+_vm._s(_vm.user.profile.name))])],1)])])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
import {
    AppGH
} from '../../../../../lib/graph-ui/corev2/entities/App.gh.js';
import {
    ViewGH
} from '../../../../../lib/graph-ui/corev2/entities/View.gh.js';





export const CustomersList = new ViewGH('CUSTOMERS_LIST', {
    display: {
        name: 'Customers List',
        icon: 'mdi-account-group-outline',
    },
    permissions: [
        'ALLOW_CREATE_CUSTOMER',
        'ALLOW_SEE_CUSTOMER_INVOICES'
    ],
    component: () => import('./views/CustomersList.vue')

});
export const CustomerDetails = new ViewGH('CUSTOMER_DETAILS', {
    display: {
        name: 'Customers Details',
        icon: 'mdi-account-tie',
    },
    component: () => import('./views/CustomerDetails.vue')

});
export const CustomerCreation = new ViewGH('CUSTOMER_CREATION', {
    display: {
        name: 'New Customer',
        icon: 'mdi-account-plus-outline',
    },


    component: () => import('./views/CustomerCreation.vue')

});

export const CustomerCompanyCreation = new ViewGH('CUSTOMER_COMPANY_CREATION', {
    display: {
        name: 'New Company',
        icon: 'mdi-office-building-plus-outline',
    },
    component: () => import('./views/customerCompany/CustomerCompanyCreation.vue')
});


export const CustomerCompanyList = new ViewGH('CUSTOMER_COMPANY_LIST', {
    name: 'Companies List',
    icon: 'mdi-office-building-outline',
    component: () => import('./views/customerCompany/CustomerCompanyList.vue')
});






export const CustomerManagement = new AppGH('CUSTOMER_MANAGEMENT', {
        display: {
            icon: 'mdi-account-group-outline',
            name: 'Customers',
            description: 'This app provide an ability to manage customers',
        },

        main: CustomersList
    })
    .use([
        CustomersList,
        CustomerDetails,
        CustomerCreation,
        CustomerCompanyCreation,
        CustomerCompanyList
    ])


console.log('CustomerManagement: ', CustomerManagement.components)
//|##########################################################|
//|                      GraphUI V2.0.0                      |
//|----------------------------------------------------------|
//|                © Copyright 2022 adaas.org                |
//|                All rights reserved ADAAS                 |
//|----------------------------------------------------------|
//| Created At: 11.16.2022                                   |
//| Created By: Andrew Tsariuk (andrew.t@adaas.org)          |
//|__________________________________________________________|
//|###########################################################

import {
    RenderingLevelGH
} from "./RenderingLevel.gh.js";

/* eslint-disable no-unused-vars */
import {
    SceneGH
} from "./Scene.gh.js";

/* eslint-enable no-unused-vars */

export class SceneManagerGH {


    /**
     * This class is responsible on all manipulation across the scene.
     * It contains all hidden logic of draw and other actions
     * 
     * @param {SceneGH} scene
     */
    constructor() {



    }

    /**
     * Should create if not exists a new Level. 
     * If level is already exists and is not empty will return a link to object
     * 
     * @param {RenderingLevelGH[]} levels 
     * @param {number} index 
     * @returns {RenderingLevelGH}
     */
    initLevel(levels, index = -1) {
        if (!levels[index]) {
            const newLength = levels.push(new RenderingLevelGH());
            return levels[newLength - 1];
        } else {
            return levels[index];
        }
    }


}
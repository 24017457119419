/* eslint-disable no-unused-vars */
import {
    GraphUI
} from "../GraphUI.class.js";
/* eslint-enable no-unused-vars */

export class GraphUILogger {

    /**
     * This class provides a simple wrapper over console.log method to provide more details in debug mode
     * 
     * 
     * @param {GraphUI} graphUI
     */
    constructor(graphUI) {

        /**
         * Main class 
         * @private 
         * @type {GraphUI}
         */
        this.__graphUI = graphUI;

    }

    /**
     * Will log data if debug=true
     * 
     * @param {string} alias 
     * @param  {...any} params 
     */
    log(alias, ...params) {
        if (this.__graphUI.config.debug)
            console.log(`\n[GraphUI-${alias}]: `, ...params)
    }

}
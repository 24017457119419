//|##########################################################|
//|                      GraphUI V2.0.0                      |
//|----------------------------------------------------------|
//|                © Copyright 2022 adaas.org                |
//|                All rights reserved ADAAS                 |
//|----------------------------------------------------------|
//| Created At: 11.16.2022                                   |
//| Created By: Andrew Tsariuk (andrew.t@adaas.org)          |
//|__________________________________________________________|
//|###########################################################


/* eslint-disable no-unused-vars */

import {
    ViewGH
} from './entities/View.gh.js';
import {
    RenderingViewGH
} from './render/RenderingView.gh.js';
import {
    RelationGH
} from './entities/Relation.gh.js';
import {
    BreadcrumbGH
} from './render/breadcrumbs/Breadcrumb.js';
import {
    GraphUI
} from './GraphUI.class.js';
/* eslint-enable no-unused-vars */

/**
 * @typedef {Object} GraphUIActionCallbackProps
 * @property {RelationGH} relation
 * @property {RenderingViewGH} rView
 * @property {BreadcrumbGH} breadcrumb
 * @property {ViewGH} view
 * 
 * 
 * @typedef {Object} GraphUIActionEmitOptions
 * @property {boolean} async
 * 
 * @callback GraphUIActionCallback
 * @param {GraphUIActionCallbackProps} action
 * @returns {Boolean} * 
 * 
 */
export class GraphUIActions {


    /**
     * This class provides a wrapper over all possible actions across GraphUI
     * 
     * @param {GraphUI} graphUI
     */
    constructor(graphUI) {

        /**
         * @private
         * @type {Map<string, Set<GraphUIActionCallback>>}
         */
        this.__handlers = new Map();

        /**
         * Main class 
         * @private 
         * @type {GraphUI}
         */
        this.__graphUI = graphUI;

        /**
         * unique identifier to log data properly
         * 
         * @private
         * @type {string}
         */
        this.__logAlias = 'Actions'

    }



    /**
     * This is a wrapper over actions that provides an ability to listen for actions  
     * All actions works in a pubSub Model and will be emitted to all listeners asynchronously 
     * 
     * @param {'move'|'remove','initialized'} action 
     * @param {GraphUIActionCallback} handler 
     * @returns {GraphUIActions} 
     */
    subscribe(action, handler) {
        // get all listeners associated with provided action 
        let listeners = this.__handlers.get(action);

        // if listeners are empty or not exists just return default
        if (!listeners) {
            listeners = new Set()
            listeners.add(handler);
            this.__handlers.set(action, listeners);

        } else {
            //attach a new handler to the set
            listeners.add(handler);
        }


        return this;
    }

    /**
     * Should remove listener from the actions store
     * @param {'move'|'remove','initialized'} action 
     * @param {GraphUIActionCallback} handler 
     * @returns {GraphUIActions} 
     */
    unsubscribe(action, handler) {
        // get all listeners associated with provided action 
        const listeners = this.__handlers.get(action);

        // if listeners are empty or not exists just return default
        if (!listeners)
            return this;

        //detach a handler from set
        listeners.delete(handler);

        return this;
    }


    /**
     * Will fire actions and pass it to all listeners
     * 
     * @param {'move'|'remove','initialized'} action 
     * @param {GraphUIActionCallbackProps} props 
     * @param {GraphUIActionEmitOptions} opts 
     * @returns {Promise<any>|undefined} 
     */
    emit(action, props, opts={}) {
        this.__graphUI.logger.log(this.__logAlias, 'EMIT: ', action)

        // get all listeners associated with provided action 
        const listeners = this.__handlers.get(action);

        if (!listeners)
            return;

        if (opts.async) {
            const promises = [];
            for (const handler of listeners.values()) {
                promises.push(handler(props));
            }
            return Promise.all(promises);

        } else {
            for (const handler of listeners.values()) {
                handler(props);
            }
        }


    }

}
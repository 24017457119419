var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-section"},[_c('div',{staticClass:"header",class:{
      right: _vm.right,
    }},[(_vm.editable)?_c('v-speed-dial',{staticClass:"editable-section",class:{
        right: _vm.right,
      },attrs:{"open-on-hover":true,"direction":_vm.right ? 'left' : 'right',"transition":_vm.right ? 'slide-x-reverse-transition' : 'slide-x-transition'},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-btn',{staticClass:"form-section-action-btn",attrs:{"color":"white","dark":"","icon":""},model:{value:(_vm.fab),callback:function ($$v) {_vm.fab=$$v},expression:"fab"}},[(_vm.fab)?_c('v-icon',[_vm._v(" mdi-close ")]):_c('v-icon',[_vm._v(" "+_vm._s(_vm.icon)+" ")])],1)]},proxy:true}],null,false,1019943357),model:{value:(_vm.fab),callback:function ($$v) {_vm.fab=$$v},expression:"fab"}},_vm._l((_vm.actions),function(action,i){return _c('v-btn',{key:i,staticClass:"form-section-action-btn list",style:({
          background: `
            radial-gradient(
              circle,
              rgba(0, 0, 0, 0.1) 0%,
              ${_vm.hexToRgba(action.color, 0.3)} 100%
            )
          `,
        }),attrs:{"icon":"","dark":""},on:{"click":function($event){return _vm.onAction(action)},"mouseover":() => {
            _vm.activeAction = action;
            _vm.actionName = action.name;
          }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(action.icon))])],1)}),1):_c('v-icon',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(_vm.icon))]),(!_vm.right)?_c('v-slide-x-transition',{attrs:{"leave-absolute":"","hide-on-leave":""}},[(_vm.fab)?_c('div',{key:_vm.actionName,staticClass:"action-description"},[_c('h2',[_vm._v(_vm._s(_vm.actionName))]),_c('span',[_vm._v(" "+_vm._s(_vm.activeAction ? _vm.activeAction.description : "-"))])]):_vm._e()]):_c('v-slide-x-transition',{attrs:{"leave-absolute":"","hide-on-leave":""}},[(_vm.fab)?_c('div',{key:_vm.actionName,staticClass:"action-description right"},[_c('h2',[_vm._v(_vm._s(_vm.actionName))]),_c('span',[_vm._v(" "+_vm._s(_vm.activeAction ? _vm.activeAction.description : "-"))])]):_vm._e()]),_c('v-slide-x-transition',[(_vm.tag === 'h3')?_c('h3',{staticClass:"subtitle-1 label",class:{
          right: _vm.right,
          center: _vm.center,
          editable: _vm.editable,
          icon: _vm.icon,
        },style:({
          opacity: !_vm.fab ? 1 : 0,
        })},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),(_vm.tag === 'h2')?_c('h2',{staticClass:"section-header",class:{
          right: _vm.right,
          center: _vm.center,
          editable: _vm.editable,
          icon: _vm.icon,
        },style:({
          opacity: !_vm.fab ? 1 : 0,
        })},[_vm._v(" "+_vm._s(String(_vm.label).toUpperCase())+" ")]):_vm._e()])],1),(_vm.underline)?_c('v-divider'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
import {
    API
} from '../Api.class';


export class PresentMasterAPI extends API {

    constructor(baseUrl) {
        super(baseUrl)

    }



    async create(body, meta) {
        this.loading = true;

        return await this.__axiosInstance.post('api/v1/doc-assistant/present-master', body, {
            meta
        });
    }

}
//|##########################################################|
//|                      GraphUI V2.0.0                      |
//|----------------------------------------------------------|
//|                © Copyright 2022 adaas.org                |
//|                All rights reserved ADAAS                 |
//|----------------------------------------------------------|
//| Created At: 11.16.2022                                   |
//| Created By: Andrew Tsariuk (andrew.t@adaas.org)          |
//|__________________________________________________________|
//|###########################################################


/* eslint-disable no-unused-vars */
import {
    GraphUI
} from "../GraphUI.class.js";
import {
    RendererHelper
} from "../helpers/renderer.helper.js";
import {
    BreadcrumbGH
} from "../render/breadcrumbs/Breadcrumb.js";
import {
    RenderingLevelGH
} from "../render/RenderingLevel.gh.js";
import {
    RenderingViewGH
} from "../render/RenderingView.gh.js";
import {
    RelationGH
} from "./Relation.gh.js";
import {
    StoreDriverAbstract
} from "./storeDrivers/StoreDriver.abstract.js";
import {
    WebDriver
} from "./storeDrivers/WebDriver.gh.js";
import {
    ViewGH
} from "./View.gh.js";
/* eslint-enable no-unused-vars */



export class RelationStoreGH {

    /**
     * This is a store of Relations.
     * It may use DB or Web storage like localtorage
     * 
     * @param {GraphUI} graphUI
     */
    constructor(graphUI) {

        /**
         * Store Driver, it depends on the provided parameter
         * 
         * @type {StoreDriverAbstract}
         */
        this.driver;

        /**
         * Main class 
         * @private 
         * @type {GraphUI}
         */
        this.__graphUI = graphUI;

        //Prepare a proper driver
        this.__identifyDriver();
    }


    /**
     * Should return a new set of data based on the provided view
     * Will return a new set of renderingLevels that will describe the destination view
     * 
     * Also this method will combine previous display and received data to one view.
     * It may be possible to have not relevant or more relevant views already opened
     * And therefore it's important to make sure that there will not be duplicates and ordering 
     * will be right
     *  
     * @param {BreadcrumbGH} breadcrumb - the set of Views from where Relations should be started
     * @param {RenderingLevelGH} previousLevel - the set of Rendering Views that is already displayed
     * @param {import("./storeDrivers/StoreDriver.abstract.js").StoreDriverOptions} options - the set Options that should applied for get operation
     * @returns {RenderingViewGH[]}
     */
    async get(breadcrumb, previousLevel, options) {
        //receive all relations for the initial data
        const receivedData = await this.driver.get(
            previousLevel.views,
            options
        );

        //convert relations to Rendering Views
        const rViewsNew = receivedData.map(relation => relation.toRView());


        //TODO: remove if not used
        // its needed to know from what index to start 
        // const startIndex = RendererHelper.findIndexByHeight(previousLevel.views, breadcrumb.heightOffset)

        // const mergedData = await this.driver.merge(
        //     previousLevel.views,
        //     rViewsNew,
        //     startIndex
        // );

        return rViewsNew;
    }


    /**
     * Should add relation to the store
     * 
     * 
     * @param {RelationGH} relation 
     */
    async add(relation) {
        await this.driver.add(relation)
    }


    /**
     * 
     * @param {ViewGH} from 
     * @param {ViewGH} to 
     * @param {Object} params 
     * @returns {RelationGH}
     */
    async find(from, to, params) {
        return await this.driver.find(from, to, params);
    }

    /**
     * Will update received relation
     * @param {RelationGH} relation 
     */
    async update(relation) {
        return await this.driver.update(relation)
    }

    /**
     * 
     * Will remove relation from the store
     * @param {RelationGH} relation 
     */
    async remove(relation) {
        return await this.driver.remove(relation)
    }



    /**
     * This Factory method uses to identify a proper driver
     * 
     * @private 
     */
    __identifyDriver() {

        switch (this.__graphUI.config.driver) {
            case 'neo4j':
                //TODO implement neo4J driver
                throw new Error('Noe4J driver does not created yet.')

            case 'web':
                //Will use a local storage driver     
                this.driver = new WebDriver(this.__graphUI);
                break;

            default:
                // byt default will be used Web driver
                this.driver = new WebDriver();
                break;
        }
    }



}
//|##########################################################|
//|                      GraphUI V2.0.0                      |
//|----------------------------------------------------------|
//|                © Copyright 2022 adaas.org                |
//|                All rights reserved ADAAS                 |
//|----------------------------------------------------------|
//| Created At: 11.16.2022                                   |
//| Created By: Andrew Tsariuk (andrew.t@adaas.org)          |
//|__________________________________________________________|
//|###########################################################

import {
    GraphUIError
} from "../../common/Error.gh.js";
import {
    GRAPH_UI_ERRORS
} from "../../constants/errors.gh.js";

/* eslint-disable no-unused-vars */
import {
    ViewGH
} from "../View.gh.js";
import {
    GraphUI
} from "../../GraphUI.class.js";
import {
    RelationGH
} from "../Relation.gh.js";
import {
    RenderingViewGH
} from "../../render/RenderingView.gh.js";
/* eslint-enable no-unused-vars */

/**
 * 
 * @typedef {Object} StoreDriverOptions
 * @property {number} limit - the limit number of relations to receive
 * @property {Object<string, 'asc'|'desc'>} orderBy the ordering by custom properties
 * @property {Object<string, string>} filter - custom filter e.g. userId
 * @property {RelationGH[]} exclude - custom filter e.g. userId
 * 
 */
export class StoreDriverAbstract {


    /**
     * This is an abstract class for all possible drivers
     * This set of classes is responsible to store and operate over relations 
     * @param {GraphUI} graphUI
     */
    constructor(graphUI) {

        /**
         * @protected
         * @type {GraphUI}
         */
        this.__graphUI = graphUI

    }

    /**
     * 
     * @param {RenderingViewGH[]} views
     * @param {StoreDriverOptions} options 
     * @returns {RelationGH[]}
     */
    async get(rViews, options) {
        rViews;
        options;
        throw new GraphUIError(GRAPH_UI_ERRORS.METHOD_NOT_IMPLEMENTED);
    }

    /**
     * 
     * @param {ViewGH} from 
     * @param {ViewGH} to 
     * @param {Object} params 
     */
    async find(from, to, params) {
        from;
        to;
        params;
        throw new GraphUIError(GRAPH_UI_ERRORS.METHOD_NOT_IMPLEMENTED);
    }



    /**
     * Should be used to store a new specific relation
     * 
     * @param {RelationGH} relation
     */
    async add(relation) {
        relation;
        throw new GraphUIError(GRAPH_UI_ERRORS.METHOD_NOT_IMPLEMENTED);
    }


    /**
     * Should be used to remove a specific relations
     * @param {RelationGH} relation 
     */
    async remove(relation) {
        relation;
        throw new GraphUIError(GRAPH_UI_ERRORS.METHOD_NOT_IMPLEMENTED);

    }


    /**
     * should be used to update  GraphUI Relation parameters and configurations
     * 
     * @param {RelationGH} relation 
     */
    async update(relation) {
        relation;
        throw new GraphUIError(GRAPH_UI_ERRORS.METHOD_NOT_IMPLEMENTED);

    }

    /**
     * Should merge old and new views into one array
     * 
     * @param {RenderingViewGH[]} oldViews 
     * @param {RenderingViewGH[]} newViews 
     * @param {number} startIndex - the index from where to start merge operation
     * @param {StoreDriverOptions} options 
     */
    async merge(oldViews, newViews, startIndex, options) {
        oldViews;
        newViews;
        options;
        throw new GraphUIError(GRAPH_UI_ERRORS.METHOD_NOT_IMPLEMENTED);
    }



    /**
     * Will prepare default values for all parameters
     * 
     * @protected
     * @param {StoreDriverOptions} options 
     * @returns {StoreDriverOptions}
     */
    __prepareOptions(options = {}) {

        options.limit = options.limit ? options.limit : 4;
        options.orderBy = options.orderBy ? options.orderBy : {
            weight: 'desc',
            created: 'desc',
            key: 'asc',
        };

        return options;
    }

}
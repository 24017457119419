

<template>
  <div
    class="sign-in-form-container bg-grey-gradient"
    :class="{ 'loading-finish': selectedPortal }"
  >
    <v-img class="logo" v-if="dark" src="/static/images/logos/logo.png">
    </v-img>
    <v-img class="logo" v-else src="/static/images/logos/logo-light.png">
    </v-img>
    <div class="portal-loading-finish"></div>
    <v-fade-transition>
      <SignInFormVue
        v-if="!signInCompleted"
        @onSubmit="signIn"
        name="Sign In"
        class="pa-10 mt-10"
      ></SignInFormVue>
    </v-fade-transition>
  </div>
</template>



<script>
import SignInFormVue from "../components/wad/molecules/signIn/SignInForm.vue";

export default {
  data: () => ({
    dark: true,
    signInCompleted: false,

    selectedPortal: null,
    showSplash: false,
  }),
  mounted() {},
  components: {
    SignInFormVue,
  },
  methods: {
    async signIn(credentials) {
      await this.$store.dispatch("MainStore/logIn", credentials);

      setTimeout(() => {
        this.$router.push("/desktop");
      }, 4500);
    },
  },
};
</script>

<style lang="scss" scoped>
.loading-finish {
  width: 100% !important;
}

.portal-loading-finish {
  display: none;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: white;
  z-index: 9;
}

.portal-loading-finish.open-portal {
  animation: circle 3s;
  display: block;
}

@keyframes circle {
  0% {
    clip-path: circle(10%);
  }
  50% {
    clip-path: circle(45%);
  }
  100% {
    clip-path: circle(100%);
  }
}

.sign-in-form-container {
  // background: linear-gradient(to bottom, #000, transparent);
  backdrop-filter: blur(15px);

  position: fixed;
  right: 0;
  width: 35%;
  height: 100%;
  -webkit-transition: width 1s ease-in-out;
  -moz-transition: width 1s ease-in-out;
  -o-transition: width 1s ease-in-out;
  transition: width 1s ease-in-out;

  .sign-in-tools {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 40px;
    position: absolute;
    bottom: 0;

    .change-btn {
      cursor: pointer;
    }
  }

  .logo {
    margin-top: 25px;
    width: 300px;
    margin: 0 auto;
    -webkit-transition: width 1s ease-in-out;
    -moz-transition: width 1s ease-in-out;
    -o-transition: width 1s ease-in-out;
    transition: width 1s ease-in-out;
  }

  .portal-list {
    transition: all 0.5s;
  }
  .portal-list-enter, .portal-list-leave-to
/* .card-leave-active for <2.1.8 */ {
    opacity: 0;
    // transform: scale(0);
    opacity: 0;
    transform: translateX(330px);
  }
  .portal-list-enter-to {
    opacity: 1;
    transform: scale(1);
  }

  .portal-list-leave-active {
    /*position: absolute;*/
  }

  .portal-list-move {
    opacity: 1;
    transition: all 0.5s;
  }

  .portal-selection-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 40px !important;
    .portal-selection-col {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  @media (max-width: 864px) {
    width: 100%;
  }
}

.sign-in-completed {
  width: 100%;

  .logo {
    width: 700px;
  }
}
</style>




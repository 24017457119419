import {
    API
} from '../Api.class';


export class ServiceDeliverableAPI extends API {

    constructor(baseUrl) {
        super(baseUrl)
    }


    async list(pagination, filter, serviceId) {
        this.loading = true

        return await this.__axiosInstance.get(`api/v1/services/${serviceId}/deliverables`, {
            params: {
                ...pagination,
                ...filter
            }
        })
    }

    async get(serviceId, id) {
        this.loading = true
        return await this.__axiosInstance.get(`api/v1/services/${serviceId}/deliverables/${id}`);
    }


    async attach(serviceId, deliverable = {}) {
        return await this.__axiosInstance.post(`api/v1/services/${serviceId}/deliverables/${deliverable.id}`)
    }

    async update(serviceId, deliverable = {}) {
        return await this.__axiosInstance.put(`api/v1/services/${serviceId}/deliverables/${deliverable.id}`, deliverable)
    }

    async detach(serviceId, deliverable) {
        return await this.__axiosInstance.delete(`api/v1/services/${serviceId}/deliverables/${deliverable.id}`)
    }

}
// import Vue from "vue";
import {
    DocAssistantAPInstance
} from "../../../../../components/api";
import {
    GUI
} from "../../../GrapUI.config.js";
import {
    DocAssistantMain,
    ProposalAssistant,

} from "../app.gh.js";


export const DocAssistantStore = {
    namespaced: true,
    state() {
        return {
            docAssistantItems: [],
            pagination: {
                page: 1,
                pageSize: 10,
            },
            filter: {
                search: "",
                typeId: "",
            },

            // Timeouts
            updateTimeout: undefined,
            loadingMap: new Map()
        }
    },

    mutations: {
        setHistory(state, {
            items,
            reset
        } = {}) {
            if (reset)
                state.docAssistantItems = items;

            else
                items.forEach(docAssistantItem => {
                    const docAssistantItemIndex = state.docAssistantItems.findIndex(cs => cs.id === docAssistantItem.id);

                    if (docAssistantItemIndex !== -1)
                        state.docAssistantItems.splice(docAssistantItemIndex, 1, docAssistantItem)
                    else
                        state.docAssistantItems.push(docAssistantItem)
                });
        },
        setPagination(state, pagination) {
            state.pagination = pagination;
        },
        setFilter(state, filter) {
            state.filter = filter;
        }
    },
    actions: {

        openDocAssistant(props, {
            from,
        }) {
            const fromParams = from.relation && from.relation.params ? {
                ...from.relation.params
            } : undefined

            if (fromParams && fromParams.fromParams)
                delete fromParams.fromParams

            GUI.scene.add(from, DocAssistantMain, {
                fromParams
            });
        },
        openProposalAssistant(prop, {
            from,
            lead,
        }) {
            const fromParams = from.relation && from.relation.params ? {
                ...from.relation.params
            } : undefined


            if (fromParams && fromParams.fromParams)
                delete fromParams.fromParams

            GUI.scene.add(from, ProposalAssistant, {
                leadId: lead ? lead.id : undefined,
                fromParams
            });

        },

        async history({
            commit,
            state
        }, {
            pagination,
            filter,
            reset,
            next
        } = {}) {
            if (reset) {
                pagination = pagination ? pagination : {}
                filter = filter ? filter : {}
            } else {
                pagination = pagination ? pagination : state.pagination
                filter = filter ? filter : state.filter
            }

            if (next)
                pagination.page++;


            const resp = await DocAssistantAPInstance
                .list(
                    pagination,
                    filter);

            commit('setHistory', {
                items: resp.items,
                reset
            });

            commit('setPagination', resp.pagination);
            commit('setFilter', filter);
        },

    }
}
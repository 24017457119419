

<template>
  <v-row
    id="appsContainer"
    ref="appsContainer"
    no-gutters
    class="d-flex flex-nowrap apps-wrapper"
  >
    <!-- <v-slide-y-transition
      group
      v-for="level in levels"
      tag="div"
      :key="level.id"
      :id="level.id"
      :hide-on-leave="true"
      :cols="4"
      :style="{
        width: level.width + 'px',
      }"
      class="apps-col d-flex flex-column flex-grow-1 flex-shrink-0"
    > -->
    <TransitionGroup
      name="y-list"
      tag="div"
      v-for="level in levels"
      :key="level.id"
      :id="level.id"
      :cols="4"
      :style="{
        width: level.width + 'px',
      }"
      class="apps-col y-list d-flex flex-column flex-grow-1 flex-shrink-0"
      appear
    >
      <component
        class="y-list-item"
        v-for="view in level.views"
        :key="view.id"
        :id="view.id"
        :value="view"
        @onActivate="(e) => $emit('onActivate', e, view)"
        @onAdd="(from, to) => $emit('onAdd', from, to)"
        @onMaximize="(e) => $emit('onMaximize', e, level)"
        @onClose="(e) => $emit('onClose', view)"
        @onExpand="(e) => $emit('onExpand', view)"
        @onCollapse="(e) => $emit('onCollapse', e)"
        :is="view.view.key"
      ></component>
      <!-- </v-slide-y-transition> -->
    </TransitionGroup>
  </v-row>
</template>
  
  
      
  <script>
import { CustomerManagement } from "../../apps/customer-management/app.gh.js";
import { DocAssistantApp } from "../../apps/doc-assistant/app.gh";
import { FolderApp } from "../../apps/folder/app.gh";
import { KnowledgeBaseApp } from "../../apps/knowledge-base/app.gh.js";
import { LeadAnalyzerApp } from "../../apps/lead-analyzer/app.gh.js";
import { LeadManagement } from "../../apps/leads-management/app.gh.js";
// import { CustomerManagement } from "../../apps/customer-management/app.gh.js";
import { ServiceManagement } from "../../apps/service-management/app.gh.js";
import { UsersManagementApp } from "../../apps/users-management/app.gh.js";
import { Youtube } from "../../apps/youtube/app.gh";
import { GUI } from "../../GrapUI.config.js";

export default {
  name: "TreeViewDesktop",
  props: {
    levels: {
      default: () => ({}),
    },
  },
  data() {
    return {
      user: {},
      animationStyle: {
        transition: "all 0.6s",
      },
      scene: GUI.scene,
    };
  },
  async mounted() {},
  components: {
    ...CustomerManagement.components,
    ...ServiceManagement.components,
    ...LeadAnalyzerApp.components,
    ...KnowledgeBaseApp.components,
    ...LeadManagement.components,
    ...UsersManagementApp.components,
    ...Youtube.components,
    ...FolderApp.components,
    ...DocAssistantApp.components,

    EMPTY_GRAPH_UI_COMPONENT: () => import("./EmptyComponent.vue"),
    ADD_GRAPH_UI_COMPONENT: () => import("./EmptyComponent.vue"),
  },
  methods: {
    move(breadcrumb) {
      setTimeout(() => {
        this.$refs[`appsContainer`].style.transform = `translate(${
          -1 * breadcrumb.left
        }px, ${-1 * breadcrumb.top}px)`;
      }, 10);
    },
  },
};
</script>
      
  <style lang="scss" scoped>
.apps-wrapper {
  // transition: all 1000ms;
  transition: all 0.4s ease-out;
}

.apps-col {
  // transition: all 0.4s ease-out;
}

@keyframes circle {
  0% {
    clip-path: circle(10%);
  }
  50% {
    clip-path: circle(45%);
  }
  100% {
    clip-path: circle(100%);
  }
}
</style>
      
      
      
      
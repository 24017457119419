import {
    UserAPIInstance
} from "../../../../../components/api";
import {
    GUI
} from "../../../GrapUI.config.js";
import {
    UserCreation,
    UserDetails,
    UserEditor,
} from "../app.gh.js";


// Create a new store instance.
export const UserStore = {
    namespaced: true,
    state() {
        return {
            activeUserId: undefined,
            displayedUsers: [],
            user: {
                profile:{},
                settings:{}
            },
            users: [],
            letters: [],
            activeSectionId: 'head',
            pagination: {
                page: 1,
                pageSize: 10,
            },
            filter: {
                search: "",
                typeId: "",
            },
            updateTimeout: undefined
        }
    },
    getters: {
        userById: (state) => (id) => {
            const index = state.displayedUsers.findIndex(s => s.id === id)

            if (index !== -1)
                return {
                    ...state.displayedUsers[index]
                }
            else
                return {
                    id
                }

        }
    },
    mutations: {
        setUser(state, user) {
            let index = state.displayedUsers.findIndex(s => s.id === user.id)
            let listIndex = state.users.findIndex(s => s.id === user.id)

            if (index !== -1)
                state.displayedUsers.splice(index, 1, user);
            else
                index = state.displayedUsers.push(user);


            if (listIndex !== -1 && !user.IN_PROGRESS) {
                state.users.splice(listIndex, 1, user)
            }

        },
        setActiveId(state, userId) {
            state.activeUserId = userId;
        },
        setActiveSectionId(state, activeSectionId) {
            state.activeSectionId = activeSectionId;
        },
        setUsers(state, users) {
            state.users = users;
        },
        setLetters(state, letters) {
            state.letters = letters;
        },
        setPagination(state, pagination) {
            state.pagination = pagination;
        },
        setFilter(state, filter) {
            state.filter = filter;
        }
    },
    actions: {
        openUserCreation(props, {
            from,
        }) {
            GUI.scene.add(from, UserCreation);
        },
        openUserDetails(props, {
            from,
            user
        }) {
            GUI.scene.add(from, UserDetails, {
                userId: user.id,
            });
        },
        openUserGeneralEditor(props, {
            from,
            user
        }) {
            console.log('user: ', user)
            console.log('from: ', from)

            GUI.scene.add(from, UserEditor, {
                userId: user.id,
            });
        },

        SetActiveUser({
            commit
        }, {
            from,
            user
        }) {

            GUI.scene.add(from, UserDetails, {
                userId: user.id,
            });

            commit('setActiveId', user.id)
        },
        // Users API 
        async GetUsersList({
            commit,
            state
        }, pagination, filter) {
            const resp = await UserAPIInstance
                .list(
                    pagination ? pagination : state.pagination,
                    filter ? filter : state.filter);

            commit('setUsers', resp.items);
            commit('setPagination', pagination);
            commit('setFilter', filter);
        },
        async CreateNewUser({
            dispatch
        }, {
            from,
            newUser
        }) {

            const user = await UserAPIInstance
                .create(newUser);

            dispatch("GetUsersList");

            GUI.scene.add(from, UserDetails, {
                userId: user.id
            });
        },
        async GetUser({
            commit,
            state
        }, {
            id,
            refresh
        }) {

            let index = state.displayedUsers.findIndex(s => s.id === id)



            if (!refresh && index !== -1) {
                // TODO move to some helper or so... 
                const newIndex = await new Promise((resolve) => {
                    let i = 0;

                    const handler = () => {
                        let index = state.displayedUsers.findIndex(s => s.id === id)
                        if (state.displayedUsers[index] && !state.displayedUsers[index].IN_PROGRESS)
                            return resolve(index)
                        else
                            setTimeout(handler, ++i * 100);
                    }
                    setTimeout(handler, 100);
                })

                return state.displayedUsers[newIndex];
            }

            commit('setUser', {
                id,
                //just to prevent loading 
                IN_PROGRESS: true,
                sections: []
            })

            const user = await UserAPIInstance
                .get(id);


            commit('setUser', user)

            index = state.displayedUsers.findIndex(s => s.id === id)


            console.log('state.displayedUsers[index]', state.displayedUsers[index])
            console.log('state.displayedUsers[index]', state.displayedUsers)

            return state.displayedUsers[index];
        },

        async UpdateUser({
            state,
            commit
        }, {
            user
        }) {

            commit('setUser', user);

            if (state.updateTimeout)
                clearTimeout(state.updateTimeout)

            state.updateTimeout = setTimeout(async () => {
                await UserAPIInstance.update(user, {
                    toast: {
                        message: "User Config Updated",
                    },
                });
            }, 1000);

        },

        async DeleteUser({
            dispatch
        }, {
            user
        }) {
            await UserAPIInstance.delete(user, {
                toast: {
                    message: "User Terminated",
                },
            });

            dispatch('GetUser', {
                id: user.id,
                refresh: true
            });
        },

    }
}
import {
    CommonAPI
} from "./common/Common.api";
import {
    CustomerAPI
} from "./customers/Customer.api";
import {
    DeliverablesAPI
} from "./Deliverables.api";
import {
    ServiceAPI
} from "./Service.api";
import {
    UserAPI
} from "./users/User.api";
import {
    LeadAnalyzerAPI
} from './leadAnalyzer/LeadAnalyzer.api'
import {
    GlossaryAPI
} from "./glossary/Glossary.api";
import {
    KnowledgeBaseAPI
} from "./knowledgeBase/KnowledgeBase.api";
import {
    AuthAPI
} from "./Auth.api";
import {
    GUIAPI
} from "./common/GUI.api";
import {
    RoleAPI
} from "./users/Roles.api";
import {
    LeadsAPI
} from "./leads/Leads.api";
import {
    FolderAPI
} from "./folder/Folder.api";
import {
    FileAPI
} from "./folder/Files.api";
import {
    DocAssistantAPI
} from "./doc-assistant/DocAssistant.api";



export const ServiceAPIInstance = new ServiceAPI(process.env.VUE_APP_API)
export const DeliverablesAPIInstance = new DeliverablesAPI(process.env.VUE_APP_API);
export const UserAPIInstance = new UserAPI(process.env.VUE_APP_SSO_API)
export const RoleAPIInstance = new RoleAPI(process.env.VUE_APP_SSO_API)
export const CustomerAPIInstance = new CustomerAPI(process.env.VUE_APP_API)
export const CommonAPIInstance = new CommonAPI(process.env.VUE_APP_API)
export const LeadAnalyzerAPIInstance = new LeadAnalyzerAPI(process.env.VUE_APP_API)
export const GlossaryAPIInstance = new GlossaryAPI(process.env.VUE_APP_API)
export const KnowledgeBaseAPIInstance = new KnowledgeBaseAPI(process.env.VUE_APP_API)
export const AuthAPIInstance = new AuthAPI(process.env.VUE_APP_SSO_API)
export const GUIAPIInstance = new GUIAPI(process.env.VUE_APP_SSO_API)
export const LeadsAPIInstance = new LeadsAPI(process.env.VUE_APP_API);
export const FolderAPIInstance = new FolderAPI(process.env.VUE_APP_API);
export const FileAPIInstance = new FileAPI(process.env.VUE_APP_API);
export const DocAssistantAPInstance = new DocAssistantAPI(process.env.VUE_APP_API);
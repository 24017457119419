import {
    GraphUI
} from "../../../lib/graph-ui/corev2/GraphUI.class.js";
import {
    DocAssistantApp
} from "./apps/doc-assistant/app.gh.js";
import {
    FolderApp
} from "./apps/folder/app.gh.js";
import {
    KnowledgeBaseApp
} from "./apps/knowledge-base/app.gh.js";
import {
    LeadAnalyzerApp
} from "./apps/lead-analyzer/app.gh.js";
import {
    LeadManagement
} from "./apps/leads-management/app.gh.js";
import {
    ServiceManagement
} from "./apps/service-management/app.gh.js";
import {
    UsersManagementApp
} from "./apps/users-management/app.gh.js";
import {
    Youtube
} from "./apps/youtube/app.gh.js";
import {
    GLOBAL_PERMISSIONS
} from "./desktop/constants/permissions.gh.js";




export const GUI = new GraphUI({
        drawConfig: {
            drawDelay: 10,
            removeDelay: 0,
            replaceDelay: 0,

        },
        debug: false,
        cols: 3,
        globalPermissions: [
            GLOBAL_PERMISSIONS.ALLOW_CONFIGURE_DAILY_DIGEST_NOTIFICATIONS,
            GLOBAL_PERMISSIONS.ALLOW_CONFIGURE_DAILY_LEADS_NOTIFICATIONS
        ]
    })
    .use([
        ServiceManagement,
        LeadAnalyzerApp,
        KnowledgeBaseApp,
        LeadManagement,
        // CustomerManagement,
        UsersManagementApp,
        Youtube,
        FolderApp,
        DocAssistantApp
    ]);
//|##########################################################|
//|                      GraphUI V2.0.0                      |
//|----------------------------------------------------------|
//|                © Copyright 2022 adaas.org                |
//|                All rights reserved ADAAS                 |
//|----------------------------------------------------------|
//| Created At: 11.16.2022                                   |
//| Created By: Andrew Tsariuk (andrew.t@adaas.org)          |
//|__________________________________________________________|
//|###########################################################

import _ from 'lodash';
import {
    RendererHelper
} from '../helpers/renderer.helper.js';


/* eslint-disable no-unused-vars */
import {
    GraphUI
} from '../GraphUI.class.js';
import {
    BreadcrumbGH
} from './breadcrumbs/Breadcrumb.js';
import {
    RenderingViewGH
}
from './RenderingView.gh.js';
import {
    CommonHelper
} from '../helpers/common.helper.js';
import {
    RenderingLevelGH
} from './RenderingLevel.gh.js';

/* eslint-enable no-unused-vars */


/**
 * 
 * 
 * @typedef {Object} DrawInstructionsItem
 * @property {number} oldIndex - the index in array from where to start
 * @property {number} newIndex - the index in a new array
 * @property {RenderingViewGH} oldItem - a link to item from Old Array
 * @property {RenderingViewGH} newItem - a link to item from New Array
 * @property {'INSERT'|'REPLACE_INSERT'|'REPLACE_REMOVE'} operation - a link to item
 * 
 * 
 */
export class RendererManagerGH_V2 {


    /**
     * This class should help renderer to draw all items in a proper way
     * 
     * @param {GraphUI} graphUI
     */
    constructor(graphUI) {

        /**
         * Uses to have a link to renderer
         * 
         * @private
         * @type {GraphUI}
         */
        this.__graphUI = graphUI;


        /**
         * unique identifier to log data properly
         * 
         * @private
         * @type {string}
         */
        this.__logAlias = 'RendererManager'

    }


    /**
     * Generates a set of instructions to draw a proper scene. 
     * Based on the difference between OLD and NEW it will decide wht items should be added, removed, replaced or adopted 
     *
     *  ____________________________________________________________
     * |                 When Starting Index is 0                   |
     * |____________________________________________________________|
     * |                             | remove | add |    replace    |
     * |_____________________________|________|_____|_______________|
     * | 1 -> 2                          2       2         2        |
     * | 2 -> 9            i:1; i:2      4       9         9        |
     * | 3 -> 8 => add    [ 9,   8 ] =>  5       8         8        |
     * | 4 -> 5 => remove [ 1,   3 ] =>  6   =>  4  =>     5        |
     * | 5 -> 4                                  5         4        |
     * | 6 -> 6                                  6         6        |
     * |_______________________________ ____________________________|
     * 
     * 
     * 
     *  In this case Empty Rendering View should be added
     *  ____________________________________________________________
     * |                When Starting Index is 1                    |
     * |____________________________________________________________|
     * |                            | remove | add |    replace     |
     * |_ __________________________|________|_____|________________|
     * | 1 -> X                          1      X      X     X    X |
     * | 2 -> 9            i:1; i:0      4      9      9     9    9 |
     * | 3 -> 4 => add    [ 9    X ] =>  5      1      4     4    4 |
     * | 4 -> 6 => remove [ 2,   3 ] =>  6  =>  4  =>  1  => 5 => 6 |
     * | 5 -> 1                                 5      5     1    1 |
     * | 6 -> 5                                 6      6     6    5 |
     * |____________________________________________________________|
     *
     * 
     * 
     * In this case only values that are after Starting Index should be added to toBeRemoved 
     * _____________________________________________________________
     * |     When Starting Index is 1 AND i:1 should be REMOVED     |
     * |____________________________________________________________|
     * |                            | remove | add |    replace     |
     * |_ __________________________|________|_____|________________|
     * | 1 -> X                          1      1      1     1    1 |
     * | 2 -> 9            i:1; i:4      4      9      9     9    9 |
     * | 3 -> 4 => add    [ 9    8 ] =>  5      4      4     4    4 |
     * | 4 -> 6 => remove [ 2,   3 ] =>  6  =>  5  =>  1  => 5 => 6 |
     * | 5 -> 8                                 8      5     1    1 |
     * | 6 -> 5                                 6      6     6    5 |
     * |____________________________________________________________|
     * 
     * 
     *  
     *  ____________________________________________________________
     * |     When Previous Array is less then destination           |
     * |____________________________________________________________|
     * |                            | remove | add |    replace     |
     * |_ __________________________|________|_____|________________|
     * | 1 -> X                          1      X      X            |
     * | 2 -> 9            i:1; i:4      4      9      9            |
     * | 3 -> 4 => add    [ X 9 8 6] =>         1      4            |
     * | 4 -> 6 => remove [ 2,   3 ] =>     =>  6  =>  6            |
     * |      8                                 8      8            |
     * |      1                                 4      1            |
     * |____________________________________________________________|
     * 
     * 
     * 
     *  ____________________________________________________________
     * |     When Previous Array is less then destination           |
     * |____________________________________________________________|
     * |                            | remove | add |    replace     |
     * |_ __________________________|________|_____|________________|
     * | 1 -> X                          1      X      X            |
     * | 2 -> X = 2        i:1; i:4      2      1      9            |
     * | 3 -> 4 => add    [ X 6 8  ] =>  4      2      4            |
     * | 4 -> 6 => remove [   3    ] =>     =>  6  =>  6            |
     * |      8                                 8      8            |
     * |      1                                 4      1            |
     * |____________________________________________________________|
     * 
     * 
     * 
     * TEST OPTION FOR V2.0.0
     *  ____________________________________________________________
     * |     When Previous Array is less then destination           |
     * |                    StartIndex=2                            |
     * |____________________________________________________________|
     * |old|new|      Instructions           | add |    replace     |
     * |___|___|__R____D_____________________|_____|________________|
     * | 1 | 1 |  X -> X                                            |
     * | 2 | 4 |  2 -> 2                                            |
     * | 3 | 6 |  1 -> 1                                            |
     * | 4 | 9 |  3 -> 4                                            |
     * |          4 -> 3                                            |
     * |                                                            |
     * |____________________________________________________________|
     * 
     * 
     * N       a=0  a=1
     * _
     * 1  2 ->  2    2    2
     * 2  1 ->  1 -> 1 -> 1
     * 3  4 ->  3    3    4
     * 4  3 ->  4    4
     * 
     * @param {BreadcrumbGH} breadcrumb 
     * @param {RenderingLevelGH} level 
     * @param {RenderingViewGH[]} newRViews 
     * @returns {DrawInstructionsItem[]}  
     */
    async getDrawInstructions(breadcrumb, level, newRViews) {

        this.__graphUI.logger.log(this.__logAlias, 'getDrawInstructions: OLD: ', level.views)
        this.__graphUI.logger.log(this.__logAlias, 'getDrawInstructions: NEW: ', newRViews)


        this.__graphUI.logger.log(this.__logAlias, 'newRViews: ', newRViews.map(el => el.view.key + '_' + JSON.stringify(el.relation.params) + '_' + el.height))

        if (breadcrumb.heightOffset !== 0) {
            const placeholderIndex = _.findIndex(level.views, el => el.view.key === this.__graphUI.config.emptyView.key)

            if (placeholderIndex !== -1) {
                level.views[placeholderIndex].height = breadcrumb.heightOffset
            } else
                await this.insertPlaceholder(0, breadcrumb.heightOffset, level, {
                    push: true
                })
        }

        const startIndex = RendererHelper.findIndexByHeight(level.views, breadcrumb.heightOffset);

        console.log('startIndex: ', startIndex)
        console.log('old: ', level.views.map(rv=>rv.view.key))
        console.log('new: ', newRViews.map(rv=>rv.view.key))


        for (let i = startIndex; i < level.views.length; i++) {

            const newIndex = _.findIndex(newRViews, rv => rv.isEqual(level.views[i]));

            //just remove
            if (newIndex === -1) {
                await CommonHelper.delay(this.__graphUI.config.drawConfig.removeDelay)

                level.views.splice(i, 1);
            }
        }



        for (let i = 0; i < newRViews.length; i++) {

            // const startIndex = RendererHelper.findIndexByHeight(level.views, breadcrumb.heightOffset);


            const upperSlice = level.views.slice(0, startIndex);
            const lowerSlice = level.views.slice(startIndex + i, level.views.length);

            //TODO: remove unused
            this.__graphUI.logger.log(this.__logAlias, '\n\n============================================\n\nnewRView: ', newRViews[i].view.key + '_' + JSON.stringify(newRViews[i].relation.params) + '_' + newRViews[i].height)
            this.__graphUI.logger.log(this.__logAlias, 'upperSlice: ', upperSlice.map(el => el.view.key + '_' + JSON.stringify(el.relation.params) + '_' + el.height))
            this.__graphUI.logger.log(this.__logAlias, 'lowerSlice: ', lowerSlice.map(el => el.view.key + '_' + JSON.stringify(el.relation.params) + '_' + el.height))

            const upperIndex = _.findIndex(upperSlice, v => v.isEqual(newRViews[i]));
            const lowerIndex = _.findIndex(lowerSlice, v => v.isEqual(newRViews[i]));



            //TODO: remove unused
            this.__graphUI.logger.log(this.__logAlias, 'startIndex: ', startIndex)
            this.__graphUI.logger.log(this.__logAlias, 'upperIndex: ', upperIndex)
            this.__graphUI.logger.log(this.__logAlias, 'lowerIndex: ', lowerIndex)

            switch (true) {
                case upperIndex === -1 && lowerIndex === -1:

                    await this.insert({
                        operation: 'INSERT',
                        newIndex: i + startIndex,
                        newItem: newRViews[i]
                    }, level)

                    break;

                case upperIndex !== -1 && lowerIndex === -1:
                    await this.replaceInsert({
                        operation: 'REPLACE_INSERT',
                        oldIndex: upperIndex,
                        newIndex: i + startIndex,
                        oldItem: level.views[upperIndex],
                        newItem: newRViews[i]
                    }, level)

                    break;

                case upperIndex === -1 && lowerIndex !== -1:
                    await this.replaceRemove({
                        operation: 'REPLACE_REMOVE',
                        oldIndex: i + startIndex + lowerIndex,
                        newIndex: i + startIndex,
                        oldItem: level.views[i + startIndex + lowerIndex],
                        newItem: newRViews[i]

                    }, level)

                    break;

                default:
                    break;

            }

            //TODO: remove unused
            this.__graphUI.logger.log(this.__logAlias, 'oldRViews: ', level.views.map(el => el.view.key + '_' + JSON.stringify(el.relation.params) + '_' + el.height))

        }

        const startIndex2 = RendererHelper.findIndexByHeight(level.views, breadcrumb.heightOffset);

        //TODO: QUICK FIX
        level.views.splice(startIndex2 + newRViews.length, level.views.length);


        // return instructions;
    }


    /**
     * This method should receive instructions and modify received level using instructions 
     * 
     *  
     * @param {DrawInstructionsItem[]} instructions 
     * @param {RenderingView[]} arrayLink 
     */
    async drawByInstructions(instructions, arrayLink) {


        for (let i = 0; i < instructions.length; i++) {
            switch (instructions[i].operation) {
                case 'INSERT':
                    await this.insert(instructions[i], arrayLink)
                    break;

                case 'REPLACE_INSERT':
                    await this.replaceInsert(instructions[i], arrayLink)
                    break;

                case 'REPLACE_REMOVE':
                    await this.replaceRemove(instructions[i], arrayLink)
                    break;

                default:
                    break;
            }

        }
    }

    /**
     * 
     * 
     * @param {BreadcrumbGH} breadcrumb 
     * @param {RenderingViewGH[]} rViews 
     * @returns {RenderingViewGH[]}  
     */
    getDisplaySlice(breadcrumb, rViews) {
        const startIndex = RendererHelper.findIndexByHeight(rViews, breadcrumb.heightOffset);
        const endIndex = RendererHelper.findIndexByHeight(rViews, breadcrumb.heightOffset + RendererHelper.getActualHeight());

        //TODO: remove if unused
        // const upperSlice = rViews.slice(0, startIndex);
        // const lowerSlice = rViews.slice(startIndex, rViews.length);

        const displaySlice = rViews.slice(startIndex, endIndex + 1);

        return displaySlice;
    }


    /**
     *  
     * Should calculate heights for the new views 
     * 
     * 
     * @param {RenderingViewGH[]} prevViews 
     * @param {RenderingViewGH[]} newViews 
     */
    calcHeight(prevViews, newViews, heightOffset = 0) {

        //TODO update for more complex stuff

        // return RendererHelper.directCalc(newViews)

        return RendererHelper.calcHeightForArray(prevViews, newViews, heightOffset)

    }




    /**
     * 
     * @param {DrawInstructionsItem} instruction 
     * @param {RenderingLevelGH} level 
     */
    async insert(instruction, level) {

        this.__graphUI.logger.log(this.__logAlias, 'INSERT: ', level.views, instruction)

        this.__graphUI.actions.emit('onViewBeforeEnter', {
            rView: instruction.newItem
        });

        await CommonHelper.delay(this.__graphUI.config.drawConfig.drawDelay)

        //add width
        instruction.newItem.width = level.width

        level.views.splice(instruction.newIndex, 0, instruction.newItem);


        this.__graphUI.actions.emit('onViewEnter', {
            rView: instruction.newItem
        });

        this.__graphUI.logger.log(this.__logAlias, 'AFTER INSERT: ', level.views)

    }

    /**
     * 
     * @param {DrawInstructionsItem} instruction 
     * @param {RenderingLevelGH} level 
     */
    async replaceRemove(instruction, level) {
        this.__graphUI.logger.log(this.__logAlias, 'REPLACE REMOVE: ', level.views, instruction)

        this.__graphUI.actions.emit('onViewBeforeEnter', {
            rView: instruction.newItem
        });

        await CommonHelper.delay(this.__graphUI.config.drawConfig.removeDelay)

        await this.__graphUI.actions.emit('onViewBeforeRemove', {
            rView: instruction.oldItem
        });

        level.views.splice(instruction.oldIndex, 1);
        level.views.splice(instruction.newIndex, 0, instruction.oldItem);


        await this.__graphUI.actions.emit('onViewRemoved', {
            rView: instruction.oldItem
        });


        //adjust height 
        level.views[instruction.newIndex].height = instruction.newItem.height
        //add width
        level.views[instruction.newIndex].width = level.width

        this.__graphUI.actions.emit('onViewEnter', {
            rView: instruction.newItem
        });

        this.__graphUI.logger.log(this.__logAlias, 'AFTER REPLACE REMOVE: ', level.views)


    }

    /**
     * 
     * @param {DrawInstructionsItem} instruction 
     * @param {RenderingLevelGH} level 
     */
    async replaceInsert(instruction, level) {
        this.__graphUI.logger.log(this.__logAlias, 'REPLACE INSERT: ', level.views, instruction)

        this.__graphUI.actions.emit('onViewBeforeEnter', {
            rView: instruction.oldItem
        });

        await CommonHelper.delay(this.__graphUI.config.drawConfig.removeDelay)

        //  check if placeholder already exists
        const placeholderIndex = _.findIndex(level.views, el => el.view.key === this.__graphUI.config.emptyView.key)



        if (placeholderIndex !== -1) {
            level.views[placeholderIndex].height += instruction.oldItem.height
            // level.views[placeholderIndex].width += level.width
            level.views.splice(instruction.oldIndex, 1);

        } else {
            await this.insertPlaceholder(instruction.oldIndex, instruction.oldItem.height, level)

        }

        instruction.oldItem.width = level.width

        level.views.splice(placeholderIndex !== -1 ? instruction.newIndex - 1 : instruction.newIndex, 0, instruction.oldItem);
        this.__graphUI.logger.log(this.__logAlias, 'AFTER REPLACE INSERT: ', level.views)

        //adjust height 
        level.views[placeholderIndex !== -1 ? instruction.newIndex - 1 : instruction.newIndex].height = instruction.newItem.height

        this.__graphUI.actions.emit('onViewEnter', {
            rView: instruction.oldItem
        });
    }


    /**
     * Will insert placeholder Rendering View
     * 
     * @param {number} index 
     * @param {number} height 
     * @param {RenderingLevelGH} level 
     */
    async insertPlaceholder(index, height, level, options = {
        push: false,
        splice: true
    }) {

        await CommonHelper.delay(this.__graphUI.config.drawConfig.drawDelay)
        const placeHolder = new RenderingViewGH(this.__graphUI.config.emptyView);

        placeHolder.height = height;
        placeHolder.width = level.width;

        if (options.splice)
            level.views.splice(index, 1, placeHolder);

        if (options.push)
            level.views.splice(index, 0, placeHolder);

    }
}
import {
    API
} from '../Api.class';


export class LeadAnalyzerAPI extends API {

    constructor(baseUrl) {
        super(baseUrl)

    }


    async analyze(data, meta) {
        this.loading = true
        return await this.__axiosInstance
            .post('api/v1/lead-analyzer/analyze', data, {
                meta,
            });
    }


}
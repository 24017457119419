<template>
  <v-form ref="form" v-model="pValid" lazy-validation>
    <HelpFormInputVue
      dark
      v-model="form.email"
      label="E-mail"
      disabled
      required
      autocomplete="off"
      :helpText="'This is an email that will be used for the sign in and notifications. <br> You will be able to adjust this information after login'"
    ></HelpFormInputVue>

    <HelpFormInputVue
      dark
      v-model="form.profile.name"
      :rules="nameRules"
      label="User Name"
      autocomplete="name"
      :helpText="'This is your user name. It will be used to display in the system for better communication and visibility.'"
    ></HelpFormInputVue>

    <HelpFormInputVue
      dark
      autocomplete="new-password"
      v-model="form.password"
      :rules="passwordRules"
      prepend-icon="icon-lock"
      label="Password"
      type="password"
      required
      :helpText="'Please create a new password to sign in in the system.'"
    ></HelpFormInputVue>

    <HelpFormInputVue
      dark
      autocomplete="new-password"
      v-model="form.repeatPassword"
      :rules="repeatPasswordRules"
      prepend-icon="icon-lock"
      label="Repeat Password"
      type="password"
      required
      :helpText="'Please type password one more time to make sure they are the same and prevent any issues.'"
    ></HelpFormInputVue>

    <v-checkbox
      v-model="form.termsAccepted"
      color="primary"
      :rules="[(v) => !!v || 'You must agree to continue!']"
    >
      <template v-slot:label>
        <div>
          I agree with
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <a
                target="_blank"
                href="https://adaas.org/terms-and-conditions.html"
                @click.stop
                v-on="on"
              >
                ADAAS Terms&Conditions
              </a>
            </template>
            Opens in new window
          </v-tooltip>
        </div>
      </template>
    </v-checkbox>
  </v-form>
</template>
      
      
  <script>
import HelpFormInputVue from "../../atoms/common/inputs/HelpFormInput.vue";

export default {
  props: {
    value: {},
    valid: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // Form
      pValid: this.valid,
      form: this.value,

      // ======rules
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) =>
          (v && v.length >= 8) || "Password must be more than 8 characters",
      ],
      repeatPasswordRules: [
        (v) => !!v || "Password is required",
        (v) =>
          (v && v.length >= 8) || "Password must be more than 8 characters",
        (v) => this.form.password === v || "Passwords do not match.",
      ],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      nameRules: [(v) => !!v || "Name is required"],
    };
  },
  components: {
    HelpFormInputVue,
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
  watch: {
    value(newVal) {
      this.form = newVal;
    },
    form: {
      handler(newVal) {
        this.$emit("input", newVal);
      },
      deep: true,
    },
    pValid(newVal) {
      this.$emit("onValid", newVal);
    },
  },
};
</script>
      
      
      <style lang="scss" scoped>
.new-service-col {
  display: flex;
  flex-direction: column;
  min-width: 300px;
  flex-grow: 1;
  max-width: 100%;
}
</style>
import {
    API
} from '../Api.class';


export class AnalyzeCategoriesAPI extends API {

    constructor(baseUrl) {
        super(baseUrl)

    }


    async list(pagination, filter, meta) {
        this.loading = true

        return await this.__axiosInstance.get('api/v1/lead-analyze-categories', {
            meta,
            params: {
                ...pagination,
                ...filter
            }
        });

    }

    async get(id, meta) {
        this.loading = true

        return await this.__axiosInstance.get(`api/v1/lead-analyze-categories/${id}`, {
            meta
        });

    }


    async create(body, meta) {
        return await this.__axiosInstance.post('api/v1/lead-analyze-categories', body, {
            meta
        })
    }

    async update(item, meta) {
        return await this.__axiosInstance.put(`api/v1/lead-analyze-categories/${item.id}`, item, {
            meta
        });
    }

    async delete(item, meta) {
        this.loading = true

        return await this.__axiosInstance.delete(`api/v1/lead-analyze-categories/${item.id}`, {
            meta
        });

    }

    async goodWords(categoryId, pagination, filter, meta) {
        this.loading = true

        return await this.__axiosInstance.get(`api/v1/lead-analyze-categories/${categoryId}/words/good`, {
            meta,
            params: {
                ...pagination,
                ...filter
            }
        });

    }

    async badWords(categoryId, pagination, filter, meta) {
        this.loading = true

        return await this.__axiosInstance.get(`api/v1/lead-analyze-categories/${categoryId}/words/bad`, {
            meta,
            params: {
                ...pagination,
                ...filter
            }
        });

    }

    async createWord(categoryId, type, body, meta) {
        this.loading = true
        return await this.__axiosInstance.post(`api/v1/lead-analyze-categories/${categoryId}/words/${type}`, body, {
            meta
        })
    }

    async updateWord(categoryId, type, word, meta) {
        this.loading = true
        return await this.__axiosInstance.put(`api/v1/lead-analyze-categories/${categoryId}/words/${type}/${word.id}`, word, {
            meta
        })
    }

    async deleteWord(categoryId, type, word, meta) {
        this.loading = true

        return await this.__axiosInstance.delete(`api/v1/lead-analyze-categories/${categoryId}/words/${type}/${word.id}`, {
            meta
        });
    }

}
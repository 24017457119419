import { render, staticRenderFns } from "./AppsBar.vue?vue&type=template&id=4bbb5cd6&scoped=true&"
import script from "./AppsBar.vue?vue&type=script&lang=js&"
export * from "./AppsBar.vue?vue&type=script&lang=js&"
import style0 from "./AppsBar.vue?vue&type=style&index=0&id=4bbb5cd6&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4bbb5cd6",
  null
  
)

export default component.exports
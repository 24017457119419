export const DOC_ASSISTANT_PERMISSIONS = {
    ALLOW_USE_DOC_ASSISTANT: 'ALLOW_USE_DOC_ASSISTANT',
    ALLOW_USE_PROPOSAL_ASSISTANT: 'ALLOW_USE_PROPOSAL_ASSISTANT',
}

export const SLIDES_GALLERY_PERMISSIONS = {
    ALLOW_USE_SLIDES_GALLERY: 'ALLOW_USE_SLIDES_GALLERY',
    ALLOW_UPLOAD_NEW_SLIDES: 'ALLOW_UPLOAD_NEW_SLIDES',
    ALLOW_DELETE_SLIDES: 'ALLOW_DELETE_SLIDES',
}

export const PRESENT_MASTER_PERMISSIONS = {
    ALLOW_USE_PRESENT_MASTER: 'ALLOW_USE_PRESENT_MASTER'
}
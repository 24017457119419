<template>
  <div class="d-flex- d-col justify-center">
    <v-expand-transition leave-absolute>
      <div
        v-if="hovered && hoveredApp"
        :key="hoveredApp ? hoveredApp.display.name : 'key'"
        class="app-description"
      >
        <h3 class="white--text">{{ hoveredApp.display.name }}</h3>
        <!-- <span>
              {{ hoveredApp ? hoveredApp.display.description : "-" }}</span
            > -->
      </div>
    </v-expand-transition>

    <div class="desktop-form-container" :class="{}">
      <div class="app-loading-finish" :class="{}"></div>

      <ul class="app-selection-container pa-0">
        <v-slide-x-transition>
          <li
            v-if="selectedApp"
            class="app-list app-selection-col"
            :key="'default'"
          >
            <AppIcon
              @click="onAppClose(selectedApp)"
              :value="selectedApp"
            ></AppIcon>
          </li>
        </v-slide-x-transition>
        <v-slide-x-transition>
          <li
            v-if="selectedApp"
            class="app-list app-selection-col"
            :key="'icon'"
          >
            <v-icon color="white">mdi-chevron-double-right </v-icon>
          </li>
        </v-slide-x-transition>
        <v-slide-x-reverse-transition  leave-absolute>
          <v-slide-x-reverse-transition
            class="app-selection-container-list pa-0"
            name="app-list"
            group
            tag="ul"
            v-if="windowWidth > 600 && !systemAlert"
          >
            <li
              class="app-list app-selection-col"
              v-for="app in apps"
              :key="app.key"
            >
              <AppIcon
                @mouseover="
                  (value) => {
                    hoveredApp = value;
                    hovered = true;
                  }
                "
                @mouseleave="
                  () => {
                    hoveredApp = undefined;
                    hovered = false;
                  }
                "
                @click.passive="
                  () => (selectedApp ? onViewSelected(app) : onAppSelected(app))
                "
                :value="app"
              ></AppIcon>
            </li>
          </v-slide-x-reverse-transition>
          <AppIcon
            class="master-icon"
            v-else-if="windowWidth <= 600 && !systemAlert"
            :value="{
              display: {
                icon: 'mdi-square-rounded-outline',
              },
            }"
          ></AppIcon>
          <v-row class="alert-row pa-0 ma-0" v-else-if="systemAlert">
            <v-col class="pa-0 ma-0">
              <v-alert
                dense
                text
                prominent
                :type="systemAlert.type"
                class="ma-0 py-0 system-alert"
              >
                <v-row align="center" class="ma-0 pa-0">
                  <v-col class="grow">
                    {{ systemAlert.message }}
                  </v-col>
                  <v-col v-if="false" class="shrink pa-0">
                    <v-btn>Take action</v-btn>
                  </v-col>
                </v-row>
              </v-alert>
            </v-col>
          </v-row>
        </v-slide-x-reverse-transition>

        <v-slide-x-reverse-transition>
          <li class="profile app-list app-selection-col" :key="'default-2'">
            <div class="profile-chip" @click="$emit('onProfileOpen')">
              <v-avatar>
                <img
                  :style="{
                    backgroundColor:
                      user.profile.avatar && user.profile.avatar.url
                        ? 'white'
                        : user.profile.color,
                  }"
                  :src="
                    user.profile.avatar && user.profile.avatar.url
                      ? user.profile.avatar.url
                      : user.profile.avatarURL
                  "
                  :lazy-src="
                    user.profile.avatar && user.profile.avatar.url
                      ? user.profile.avatar.url
                      : user.profile.avatarURL
                  "
                  alt="User"
                />
              </v-avatar>

              <span class="user-name"> {{ user.profile.name }}</span>
            </div>
          </li>
        </v-slide-x-reverse-transition>
      </ul>
      <!-- </transition-group> -->
    </div>
  </div>
</template>


<script>
import { mapGetters, mapState } from "vuex";
// import { CommonHelper } from "../../../../components/helpers/common.helper";
import AppIcon from "./AppIcon.vue";

export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      apps: [],
      views: [],
      selectedApp: undefined,
      hoveredApp: undefined,
      hovered: false,
      appOpened: false,
      profile: false,
    };
  },
  components: {
    AppIcon,
  },
  computed: {
    ...mapState("MainStore", ["user", "systemAlert"]),
    ...mapGetters("MainStore", ["availableViews"]),
    windowWidth() {
      return window.innerWidth;
    },
  },
  async mounted() {
    this.drawApps(this.value);
  },
  methods: {
    async drawApps(value) {
      this.apps = [];
      setTimeout(async () => {
        for (let item of value) {
          // await CommonHelper.delay(120);
          this.apps.push(item);
        }
      }, 250);
    },
    backToLogin() {
      this.$router.push("/log-in");
    },
    onAppClose() {
      this.selectedApp = undefined;
      this.drawApps(this.value);
    },
    onAppSelected(app) {
      this.hoveredApp = undefined;
      this.hovered = false;
      //If app has many options to start from
      if (app.main && app.main.length > 1) {
        const filtered = this.filterViews(app.main);

        if (filtered.length === 1) {
          this.$emit("onAppSelected", app, filtered[0]);
        } else {
          this.selectedApp = app;
          this.drawApps(filtered);
        }
      }
      // if App hasn't many options and this is not a View
      else this.$emit("onAppSelected", app);
    },
    onViewSelected(view) {
      this.selectedApp = undefined;
      this.hoveredApp = undefined;
      this.hovered = false;
      this.drawApps(this.value);

      this.$emit("onAppSelected", this.selectedApp, view);
    },
    filterViews(views = []) {
      return views.filter(
        (v) =>
          // this.availableViews.find((av) => av.key === v.key)
          v
      );
    },
  },
  watch: {
    value(newVal) {
      this.drawApps(newVal);
    },
  },
};
</script>
    
<style lang="scss" scoped>
.alert-row {
  max-width: 1200px;
  position: absolute;
  left: 50%;
  bottom: 4px;
  transform: translate(-50%, 0);

  .system-alert {
    font-size: 16px;
    font-weight: 700;
    height: 62px;
    display: flex;
  }
}

.loading-finish {
  width: 100% !important;
}

.app-loading-finish {
  display: none;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: white;
  z-index: 9;
}

.app-loading-finish.open-app {
  animation: circle 3s;
  display: block;
}

@keyframes circle {
  0% {
    clip-path: circle(10%);
  }
  50% {
    clip-path: circle(45%);
  }
  100% {
    clip-path: circle(100%);
  }
}

.master-icon {
  position: absolute;
  left: 50%;
  bottom: 10px;
  transform: translate(-50%, 0);
}

.desktop-form-container {
  display: flex;
  bottom: 0;
  left: 0;
  height: 70px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  backdrop-filter: blur(30px);

  // background: linear-gradient(to bottom, #000, rgba(0, 0, 0, 0.6));
  position: fixed;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.app-description {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  text-align: center;
  padding: 4px;
  bottom: 70px;
}

.app-list {
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
}

.app-selection-container {
  height: 70px;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;

  list-style: none;
  position: absolute;
  justify-content: flex-start;

  li {
    z-index: 9;
    display: flex;
  }

  .app-selection-container-list {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;

    justify-content: center;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;

    position: absolute;

    .app-selection-col {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      transition: all 0.3s;
      -webkit-transition: all 0.3s;
    }
  }

  .profile {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translate(0, -50%);

    .profile-chip {
      transition: all 0.3s;
      -webkit-transition: all 0.3s;
      background: rgba($color: #000000, $alpha: 0.1);
      border-radius: 50px;
      height: 48px;
      width: 48px;
      cursor: pointer;
      overflow: hidden;
      display: flex;

      .user-name {
        color: white;
        font-weight: 400;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;

        overflow: hidden;
        white-space: nowrap;
      }
      &:hover {
        width: 160px;
      }
    }
  }
}

.container {
  display: flex;
}
</style>
    
    
    
    







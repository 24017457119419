<template>
  <v-row>
    <v-col v-for="img in images" :key="img" class="d-flex child-flex" cols="4">
      <v-hover v-slot="{ hover }">
        <v-img
          :src="img"
          :lazy-src="img"
          aspect-ratio="1"
          class="grey lighten-2"
          :class="{ 'on-hover': hover }"
        >
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
          <v-fade-transition>
            <div
              v-if="hover"
              class="change-background-overlay"
              style="height: 100%; width: 100%"
              @click="() => $emit('onBackgroundSelect', img)"
            >
              Use as Background
            </div>
          </v-fade-transition>
        </v-img>
      </v-hover>
    </v-col>
  </v-row>
</template>


<script>
import { mapState } from "vuex";
export default {
  name: "Personalization",
  props: {
    value: {
      default: () => ({}),
    },
    badge: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState("MainStore", ["user"]),
  },
  components: {},
  data() {
    return {
      images: [
        "/static/images/backgrounds/001.jpg",
        "/static/images/backgrounds/002.jpg",
        "/static/images/backgrounds/003.jpg",
        "/static/images/backgrounds/004.jpg",
        "/static/images/backgrounds/005.jpg",
        "/static/images/backgrounds/006.jpg",
        "/static/images/backgrounds/49.webp",
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.change-background-overlay {
  background: rgba($color: #000000, $alpha: 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  justify-items: center;
  text-align: center;
  cursor: pointer;

  backdrop-filter: blur(5px);
}
</style>
import {
    API
} from '../Api.class';


export class CaseStudyAPI extends API {

    constructor(baseUrl) {
        super(baseUrl)

    }



    getUploadUrl(deliverable) {
        return `${this.baseURL}/api/v1/knowledge-base/case-studies/${deliverable.id}/attachments`
    }

    get uploadHeaders() {
        return {
            Authorization: `Bearer: ${this.token}`
        }
    }

    async list(pagination, filter, meta) {
        this.loading = true

        return await this.__axiosInstance.get('api/v1/knowledge-base/case-studies', {
            meta,
            params: {
                ...pagination,
                ...filter
            }
        })

    }

    async get(id, meta) {
        this.loading = true

        return await this.__axiosInstance.get(`api/v1/knowledge-base/case-studies/${id}`, {
            meta
        });

    }


    async create(body, meta) {
        this.loading = true;

        return await this.__axiosInstance.post('api/v1/knowledge-base/case-studies', body, {
            meta
        });
    }


    async update(link, meta) {
        this.loading = true

        return await this.__axiosInstance.put(`api/v1/knowledge-base/case-studies/${link.id}`, link, {
            meta
        });
    }

    async delete(link, meta) {
        this.loading = true

        return await this.__axiosInstance.delete(`api/v1/knowledge-base/case-studies/${link.id}`, {
            meta
        });

    }

    async statuses(pagination, filter, meta) {
        this.loading = true

        return await this.__axiosInstance.get('api/v1/knowledge-base/case-study-statuses', {
            meta,
            params: {
                ...pagination,
                ...filter
            }
        })
    }


    async deleteAttachment(caseStudy, type, file, meta) {
        this.loading = true

        return await this.__axiosInstance.delete(`api/v1/knowledge-base/case-studies/${caseStudy.id}/attachments/${type}/file/${file.id}`, {
            meta
        });

    }

}
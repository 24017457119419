<template>
  <div class="form-section">
    <div
      class="header"
      :class="{
        right: right,
      }"
    >
      <!-- <v-icon :class="iconClass" color="primary">{{ icon }}</v-icon> -->

      <v-speed-dial
        v-if="editable"
        v-model="fab"
        :open-on-hover="true"
        :direction="right ? 'left' : 'right'"
        :transition="
          right ? 'slide-x-reverse-transition' : 'slide-x-transition'
        "
        class="editable-section"
        :class="{
          right: right,
        }"
      >
        <template v-slot:activator>
          <v-btn
            v-model="fab"
            color="white"
            class="form-section-action-btn"
            dark
            icon
          >
            <v-icon v-if="fab"> mdi-close </v-icon>
            <v-icon v-else> {{ icon }} </v-icon>
          </v-btn>
        </template>
        <v-btn
          v-for="(action, i) in actions"
          :key="i"
          class="form-section-action-btn list"
          icon
          dark
          @click="onAction(action)"
          @mouseover="
            () => {
              activeAction = action;
              actionName = action.name;
            }
          "
          :style="{
            background: `
              radial-gradient(
                circle,
                rgba(0, 0, 0, 0.1) 0%,
                ${hexToRgba(action.color, 0.3)} 100%
              )
            `,
          }"
        >
          <v-icon small>{{ action.icon }}</v-icon>
        </v-btn>
      </v-speed-dial>
      <v-icon v-else class="mr-2"> {{ icon }}</v-icon>
      <v-slide-x-transition leave-absolute hide-on-leave v-if="!right">
        <div :key="actionName" v-if="fab" class="action-description">
          <h2>{{ actionName }}</h2>
          <span> {{ activeAction ? activeAction.description : "-" }}</span>
        </div>
      </v-slide-x-transition>

      <v-slide-x-transition leave-absolute hide-on-leave v-else>
        <div :key="actionName" v-if="fab" class="action-description right">
          <h2>{{ actionName }}</h2>
          <span> {{ activeAction ? activeAction.description : "-" }}</span>
        </div>
      </v-slide-x-transition>

      <v-slide-x-transition>
        <h3
          v-if="tag === 'h3'"
          class="subtitle-1 label"
          :style="{
            opacity: !fab ? 1 : 0,
          }"
          :class="{
            right,
            center,
            editable,
            icon,
          }"
        >
          {{ label }}
        </h3>

        <h2
          v-if="tag === 'h2'"
          class="section-header"
          :style="{
            opacity: !fab ? 1 : 0,
          }"
          :class="{
            right,
            center,
            editable,
            icon,
          }"
        >
          {{ String(label).toUpperCase() }}
        </h2>
      </v-slide-x-transition>
    </div>
    <v-divider v-if="underline"></v-divider>
  </div>
</template>


<script>
import hexToRgba from "hex-to-rgba";

export default {
  props: {
    tag: {
      type: String,
      default: "h2",
    },
    label: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
    labelClass: {
      type: String,
      default: "",
    },
    iconClass: {
      type: String,
      default: "",
    },
    right: {
      type: Boolean,
      default: false,
    },
    left: {
      type: Boolean,
      default: true,
    },
    center: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    underline: {
      type: Boolean,
      default: false,
    },
    actions: {
      type: Array,
      default: () => [
        {
          name: "Edit",
          description: "Will edit this element",
          icon: "mdi-pencil",
          color: "#CD303B",
          on: "onEdit",
        },
      ],
    },
    payload: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      fab: false,
      hexToRgba,
      activeAction: {},
      actionName: "",
    };
  },
  methods: {
    async onAction(action) {
      if (action.handler && this.payload && Object.keys(this.payload).length)
        await action.handler(this.payload);
      else {
        this.$emit(action.on, action);
        this.$emit("action", action);
      }

      this.closed = true;
    },
  },
};
</script>


<style lang="scss" scoped>
.form-section {
  margin-top: 30px;
  // background: radial-gradient(
  //   circle,
  //   rgba(0, 0, 0, 0.1) 0%,
  //   rgba(0, 0, 0, 0.3) 50%,
  //   rgba(0, 0, 0, 0.5) 63%
  // );
  width: 100%;
  position: relative;

  &::after {
    content: "";
    height: 50px;
  }
  .header {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    min-height: 40px;
    // overflow: hidden;
    // padding-top: 20px;
    // padding-bottom: 20px;

    &.right {
      flex-direction: row-reverse;
    }
    .label {
      margin-left: 10px;
      margin-top: 5px;

      &.editable {
        margin-left: 40px;
      }

      &.right {
        text-align: end;

        &.icon {
          margin-right: 10px;
        }

        &.editable {
          margin-right: 40px;
        }
      }
      &.center {
        text-align: center;
        margin-right: 0px;
        margin-left: 0px;
        width: 100%;
      }
    }
    .section-header {
      font-weight: 300;
      letter-spacing: 0.3em;
      text-transform: uppercase;
      font-size: 1.5em;
      margin-top: 8px;
      margin-left: 10px;
      color: white;
      transition: opacity 0.3s;

      &.editable {
        margin-left: 40px;
      }

      &.right {
        text-align: end;

        &.icon {
          margin-right: 10px;
        }

        &.editable {
          margin-right: 40px;
        }
      }
      &.center {
        text-align: center;
        margin-right: 0px;
        margin-left: 0px;
        width: 100%;
      }
    }
  }

  .editable-section {
    position: absolute;
  }
  .form-section-action-btn {
    border-radius: 14px;
    transition: all 0.2s;

    &:hover {
      transform: scale(1.2);
    }
    &.active {
      transform: scale(1.2);
    }

    &.list {
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }
  .action-description {
    top: 0;
    position: absolute;
    right: 0;
    text-align: end;

    &.right {
      left: 0 !important;
      text-align: start !important;
    }
  }
}
</style>
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import VueRouter from 'vue-router'
import router from './router'
import VueLocalStorage from 'vue-localstorage'
import VueFullscreen from 'vue-fullscreen'
import VueFileAgent from 'vue-file-agent';
import 'vue-file-agent/dist/vue-file-agent.css';
import VueToast from 'vue-toast-notification';
import "flag-icons/css/flag-icons.min.css";


// import './assets/css/MaterialIcons.css';
import '@mdi/font/css/materialdesignicons.css'

import './assets/sass/custom.scss'
import Vuex from 'vuex'
import {
  MainStore
} from './views/src/desktop/main.store'
import * as CustomerStore from './views/src/apps/customer-management/store'
// Import one of the available themes
import 'vue-toast-notification/dist/theme-default.css';
import * as ServiceStore from './views/src/apps/service-management/store'
// import 'vue-toast-notification/dist/theme-sugar.css';

import * as LeadAnalyzerStore from './views/src/apps/lead-analyzer/store'
import * as KnowledgeBaseStore from './views/src/apps/knowledge-base/store'
import * as UsersStore from './views/src/apps/users-management/store'
import * as LeadStore from './views/src/apps/leads-management/store'
import * as FolderStore from './views/src/apps/folder/store'
import * as DocAssistantStore from './views/src/apps/doc-assistant/store'


Vue.use(Vuex)


export const store = new Vuex.Store({
  modules: {
    MainStore: MainStore,
    ...CustomerStore,
    ...ServiceStore,
    ...LeadAnalyzerStore,
    ...KnowledgeBaseStore,
    ...UsersStore,
    ...LeadStore,
    ...FolderStore,
    ...DocAssistantStore
  },
})



Vue.config.productionTip = false

Vue.use(VueRouter)
Vue.use(VueFullscreen)
Vue.use(VueToast);

Vue.use(VueFileAgent)

Vue.use(VueLocalStorage)
// Or you can specify any other name and use it via this.$ls, this.$whatEverYouWant
Vue.use(VueLocalStorage, {
  name: 'ls',
  bind: true //created computed members from your variable declarations
})
// Vue.use(MainStore);

// =============== DIRECTIVES =======================
// Vue.directive('click-outside', {
//   bind: function (el, binding, vnode) {
//     console.log('BIND?????')
//     el.clickOutsideEvent = function (event) {

//       // here I check that click was outside the el and his children
//       if (!(el == event.target || el.contains(event.target))) {
//         // and if it did, call method provided in attribute value
//         if (vnode.context[binding.expression])
//           vnode.context[binding.expression](event);
//       }
//     };
//     document.body.addEventListener('click', el.clickOutsideEvent)
//   },
//   unbind: function (el) {
//     document.body.removeEventListener('click', el.clickOutsideEvent)
//   },
// });


new Vue({
  vuetify,
  router,
  render: h => h(App),
  store
}).$mount('#app')
/* eslint-disable no-unused-vars */

/* eslint-enable no-unused-vars */

/**
 * @callback ViewComparatorFunction
 * @param {*} relation
 * @param {*} rView
 * @param {*} view
 * @returns {Boolean}
 * 
 * @callback ViewComponentFunction
 * @param {ViewGH} view
 * @returns {any}
 * 
 * 
 * 
 * @typedef {Object} ViewDisplayOptions
 * @property {string} icon - component icon
 * @property {string} name - component display name
 * @property {string} description - component description
 * @property {string} color - color that may be used to display a component
 * @property {string} minHeight - minimum allowed height
 * @property {string} maxHeight - maximum allowed height
 * @property {string} constHeight - this height will never be changed
 * 
 * 
 * @typedef {Object} ViewConstructorProps
 * @property {ViewComponentFunction} component - the function to load component
 * @property {ViewDisplayOptions} display - Display options of the View
 * @property {ViewComparatorFunction} identifier - Custom method to compare one view with another
 * @property {Boolean} allowMany - If true allows to have multiple instances of the same View. Default is False 
 * @property {'add'|'activate'} addStrategy - If true allows to have multiple instances of the same View. Default is False 
 * @property {string[]} permissions - A set of permissions that should be checked before view rendering
 * 
 * 
 */
export class ViewGH {


    /**
     * The View is an initial configurations of the displaying objects. 
     * It should describe the components that may be displayed. 
     * It's almost the same as path on the standard routing - view.
     * 
     * 
     * @param {string} key - Unique Component Name
     * @param {ViewConstructorProps} props - View configuration properties
     * 
     */
    constructor(key, props) {

        /**
         * Unique Component Name
         * Will be used to display a proper component. 
         * @type {string} 
         */
        this.key = key;


        /**
         * An Object that Describes View Display options.
         * Could contain custom properties.  
         * @type {ViewDisplayOptions}
         */
        this.display = props.display || {};

        /**
         * @type {Boolean}
         */
        this.allowMany = props.allowMany || false;

        /**
         * The function to import component for lazy loading 
         * 
         * @type {ViewComponentFunction} 
         */
        this.component = props.component;

        /**
         * This is a strategy how Graph UI should react on add element. Move or just stay on active
         * @type {'add'|'activate'}
         */
        this.addStrategy = props.addStrategy || 'add'



        /**
         * This is an array to store all set of premissions that may be used for this view
         * GUI will ask to check permissions before view rendering
         * 
         * @type {string[]}
         */
        this.permissions = props.permissions || [];

    }

}
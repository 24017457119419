import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

//#3F51B5

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#CD303B',
                secondary: '#F8F8F8', // #FFCDD2
                accent: '#000000', // #3F51B5
            },
        },
    },
});
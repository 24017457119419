<template>
  <v-row>
    <v-col cols="12">
      <SelectorCountry
        dark
        autoload
        label="Your Location"
        helpText="You can select you current location"
        return-object
        v-model="user.settings.country"
        @input="() => $emit('onSettingsChanged')"
      ></SelectorCountry>

      <SelectorTimezone
        dark
        autoload
        label="Your Timezone"
        helpText="You can change your timezone to receive notifications properly"
        return-object
        v-model="user.settings.timezone"
        @input="() => $emit('onSettingsChanged')"
      ></SelectorTimezone>

      <FormSection :label="'Notifications'"></FormSection>
      <v-switch
        color="primary"
        v-model="user.settings.receive_daily_digest"
        @change="() => $emit('onSettingsChanged')"
        :label="`I want receive corporate digest`"
      ></v-switch>
      <v-switch
        color="primary"
        v-model="user.settings.receive_daily_leads"
        @change="() => $emit('onSettingsChanged')"
        :label="`I want receive daily leads summary`"
      ></v-switch>
    </v-col>
  </v-row>
</template>
  
  
<script>
import { mapState } from "vuex";
import SelectorCountry from "../../../../../components/wad/atoms/common/autocompletes/SelectorCountry.vue";
import SelectorTimezone from "../../../../../components/wad/atoms/common/autocompletes/SelectorTimezone.vue";
import FormSection from "../../../../../components/wad/atoms/common/FormSection.vue";

export default {
  name: "Personalization",
  props: {
    value: {
      default: () => ({}),
    },
    badge: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState("MainStore", ["user"]),
  },
  components: {
    FormSection,
    SelectorTimezone,
    SelectorCountry,
  },
  data() {
    return {};
  },
};
</script>
  
  <style lang="scss" scoped>
.change-background-overlay {
  background: rgba($color: #000000, $alpha: 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  justify-items: center;
  text-align: center;
  cursor: pointer;

  backdrop-filter: blur(5px);
}
</style>
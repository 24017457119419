import {
    LeadAnalyzerAPIInstance
} from "../../../../../components/api";

export const LeadAnalyzerStore = {
    namespaced: true,
    state() {
        return {
            analyzeResult: {
                services: [],
                terms: []
            },

            // Timeouts
            analyzeTimeout: undefined
        }
    },
    getters: {

    },
    mutations: {

        setResult(state, analyzeResult) {
            state.analyzeResult = analyzeResult;
        },
    },
    actions: {
        // Services API 
        async Analyze({
            commit,
            state
        }, {
            data
        }) {
            if (state.analyzeTimeout)
                clearTimeout(state.analyzeTimeout)

            state.analyzeTimeout = setTimeout(async () => {
                const resp = await LeadAnalyzerAPIInstance
                    .analyze(data, {
                        toast: {
                            message: "Analysis completed",
                        },
                    });

                commit('setResult', resp);
            }, 400);
        },

        async clearAnalyze({
            state
        }) {
            clearTimeout(state.analyzeTimeout)

        },
    }
}
import {
    API
} from '../Api.class';


export class UseCaseAPI extends API {

    constructor(baseUrl) {
        super(baseUrl)
    }


    async list(pagination, filter, serviceId, meta) {
        return await this.__axiosInstance.get(`api/v1/services/${serviceId}/use-cases`, {
            meta,
            params: {
                ...pagination,
                ...filter
            }
        })
    }

    async get(serviceId, id) {
        return await this.__axiosInstance.get(`api/v1/services/${serviceId}/use-cases/${id}`);
    }


    async create(serviceId, useCase = {}, meta) {
        return await this.__axiosInstance.post(`api/v1/services/${serviceId}/use-cases`, useCase, {
            meta
        })
    }

    async update(serviceId, useCase = {}, meta) {
        return await this.__axiosInstance.put(`api/v1/services/${serviceId}/use-cases/${useCase.id}`, useCase, {
            meta
        })
    }

    async delete(serviceId, useCase, meta) {
        return await this.__axiosInstance.delete(`api/v1/services/${serviceId}/use-cases/${useCase.id}`, {
            meta
        })
    }

}
import {
    API
} from '../Api.class';


export class FileAPI extends API {

    constructor(baseUrl) {
        super(baseUrl)

    }



    async get(u_type, id, meta) {
        this.loading = true

        return await this.__axiosInstance.get(`api/v1/files/${u_type}/${id}`, {
            meta
        });

    }


    async upload(body, meta) {
        this.loading = true;

        return await this.__axiosInstance.post(`api/v1/files/upload`, body, {
            meta
        });
    }


    async update(u_type, file, meta) {
        this.loading = true

        return await this.__axiosInstance.put(`api/v1/files/${u_type}/${file.id}`, file, {
            meta
        });
    }

    async delete(u_type, file, meta) {
        this.loading = true

        return await this.__axiosInstance.delete(`api/v1/files/${u_type}/${file.id}`, {
            meta
        });

    }

}
<template>
  <v-textarea
    v-if="textarea"
    v-bind="$attrs"
    :value="value"
    @input="onInputChanged($event)"
    :label="label"
    class="help-input"
    :color="$attrs.dark || $attrs.dark === '' ? 'white' : 'accent'"
    @keyup.enter="$emit('onEnter')"
  >
    <v-tooltip bottom slot="append-outer">
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          class="help-input-icon"
          color="grey"
          size="18"
          dark
          v-bind="attrs"
          v-on="on"
        >
          mdi-help
        </v-icon>
      </template>
      <span class="tooltip-text">{{ helpText }}</span>
    </v-tooltip>
  </v-textarea>
  <v-text-field
    v-else
    v-bind="$attrs"
    :value="value"
    @input="onInputChanged($event)"
    :label="label"
    :color="$attrs.dark || $attrs.dark === '' ? 'white' : 'accent'"
    @keyup.enter="$emit('onEnter')"

  >
    <v-tooltip v-bind="tooltipAttrs" slot="append-outer">
      <template v-slot:activator="{ on, attrs }">
        <v-icon color="grey" size="18" dark v-bind="attrs" v-on="on">
          mdi-help
        </v-icon>
      </template>
      <span class="tooltip-text" v-html="helpText"></span>
    </v-tooltip>
  </v-text-field>
</template>


<script>
export default {
  props: {
    textarea: {
      default: false,
      type: Boolean,
    },
    value: {},
    label: {
      type: String,
      default: "",
    },
    helpText: {
      type: String,
      default: "",
    },
    timeout: {
      type: Number,
      default: 0,
    },
    tooltipAttrs: {
      type: Object,
      default: () => ({
        bottom: true,
      }),
    },
  },
  data() {
    return {
      searchTimeout: null,
    };
  },
  methods: {
    onInputChanged(event) {
      if (this.timeout && this.timeout !== 0) {
        if (this.searchTimeout) clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(() => {
          this.$emit("input", event);
        }, this.timeout);
      } else {
        this.$emit("input", event);
      }
    },

    proxyKeydown(e){
      console.log('PROXY')

     this.$emit( 'keydown', new KeyboardEvent('keydown', {
        keyCode: 40
    }), e)
    }
  },
};
</script>


<style lang="scss" scoped>
.help-input {
  align-items: flex-end;
  .help-input-icon {
    margin-bottom: 24px;
  }
}
</style>
import {
    v4 as uuid
} from "uuid";
import {
    RenderingViewGH
} from "../RenderingView.gh.js";
import {
    RenderingLevelGH
} from "../RenderingLevel.gh.js";
import {
    RendererHelper
} from "../../helpers/renderer.helper.js";

/* eslint-disable no-unused-vars */

/* eslint-enable no-unused-vars */

export class BreadcrumbGH {

    /**
     * This class represents each entity that was activated, column or a specific View
     * 
     * 
     * @param {RenderingViewGH | RenderingLevelGH} target 
     * @param {RenderingLevelGH[]} levels 
     */
    constructor(target, levels) {

        /**
         * Unique identifier of the breadcrumb.
         * uses to identify Breadcrumb
         * 
         * @type {string}
         */
        this.id = uuid();

        /**
         * RenderingView that was activated
         * @type {RenderingViewGH} 
         */
        this.view;

        /**
         * RenderingLevel that was activated
         * @type {RenderingLevelGH} 
         */
        this.level;

        /**
         * number of px from top 
         * @type {number} 
         */
        this.top = 0;

        /**
         * number of px from left
         * @type {number} 
         */
        this.left = 0;

        /**
         * index of level
         * @type {number} 
         */
        this.col = 0;

        /**
         * index of rView in level.views 
         * @type {number} 
         */
        this.row = 0;

        /**
         * 
         * The height that was passed and skipped up to the element
         * @type {number}
         */
        this.heightOffset = 0;

        /**
         * Rendering View Initialization Date
         * 
         * @type {Date}
         */
        this.created = new Date()



        //-----------Initial Breadcrumb identification----------
        this.adjust(target, levels)

    }



    get name() {
        if (this.level)
            return this.level.views[0].view.display.name
        if (this.view)
            return this.view.view.display.name;

        return '';
    }


    //==========================PUBLIC METHODS===========================

    /**
     * This method will compare current breadcrumb (this) with received
     * Will return true if they are the same
     * 
     * @param {RenderingLevelGH | RenderingViewGH | BreadcrumbGH} breadcrumb 
     */
    isEqual(levelOrView) {

        if (levelOrView instanceof RenderingLevelGH)
            return this.level.id === levelOrView.id
        if (levelOrView instanceof RenderingViewGH)
            return this.view.id === levelOrView.id
        if (levelOrView instanceof BreadcrumbGH)
            return this.id === levelOrView.id
    }


    /**
     * 
     * @param {BreadcrumbGH|undefined} active 
     */
    increment(active) {

        // if (this.top)
        this.top += active ? active.top : 0
        // if (this.left)
        this.left += active ? active.left : 0

        return this;
    }

    /**
     * Should adjust position and offset based on the new element position
     * 
     * @param {RenderingViewGH | RenderingLevelGH} target 
     * @param {RenderingLevelGH[]} levels 
     */
    adjust(target, levels) {
        if (levels && target instanceof RenderingViewGH)
            this.__useView(target, levels);

        if (levels && target instanceof RenderingLevelGH)
            this.__useLevel(target, levels);
    }


    reset() {
        this.col = 0;
        this.row = 0;
        this.left = 0;
        this.top = 0;
    }


    //==========================PRIVATE METHODS===========================


    /**
     * 
     * @private 
     * @param {RenderingViewGH} rView 
     * @param {RenderingLevelGH[]} levels 
     */
    __useView(rView, levels) {

        this.view = rView;

        const {
            top,
            left
        } = rView.position;

        this.top = top
        this.left = left

        const {
            col,
            row
        } = RendererHelper.findViewIndexById(levels, rView);

        this.col = col === -1 ? 0 : col;
        this.row = row === -1 ? 0 : row;

        this.heightOffset = RendererHelper.calcHeightUpToIndex(levels[this.col], this.row);

    }

    /**
     * 
     * @private
     * @param {RenderingLevelGH} rLevel 
     * @param {RenderingLevelGH[]} levels 
     */
    __useLevel(rLevel, levels) {
        this.level = rLevel;

        const {
            top,
            left
        } = rLevel.position;

        this.top = top
        this.left = left

        console.log('levels: ', levels)

        const col = levels.findIndex(el => el.id === rLevel.id);


        this.col = col;
        this.row = 0;
    }

}
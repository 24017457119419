//|##########################################################|
//|                      GraphUI V2.0.0                      |
//|----------------------------------------------------------|
//|                © Copyright 2022 adaas.org                |
//|                All rights reserved ADAAS                 |
//|----------------------------------------------------------|
//| Created At: 11.16.2022                                   |
//| Created By: Andrew Tsariuk (andrew.t@adaas.org)          |
//|__________________________________________________________|
//|###########################################################

import * as  uuid from "uuid";
import {
    GraphUIConfig
} from "../GraphUI.class.js";

/* eslint-disable no-unused-vars */
import {
    RenderingViewGH
} from "./RenderingView.gh.js";

/* eslint-enable no-unused-vars */

export class RenderingLevelGH {


    /**
     * This class describes each col that display on the Desktop.
     * The name is Level because it illustrates the nested dependencies from the parent View
     * 
     * 
     */
    constructor() {

        /**
         * This is unique identifier of the level.
         * It will be used to identify level position, size, etc. 
         * 
         * @type {string}
         */
        this.id = uuid.v4();


        /**
         * This is a set of views that should be displayed in the col
         * 
         * @type {RenderingViewGH[]} 
         */
        this.views = []

        /**
         * Column width, by default it will be the same for each column.
         * It calculates in percentage (%) and then should be passed to the column width
         *
         * @type {number}
         */
        this.width;
        this.__calcDefaultWidth();



        /**
         * This is an initial size of the column.
         * Uses to reset after maximize methods
         * 
         * @private
         * @type {number}
         */
        this.__defaultWidth = 100 / GraphUIConfig.cols;



        /**
         * Column Initialization Date
         * 
         * @type {Date}
         */
        this.created = new Date()
    }

    //===============================GETTERS/SETTERS====================

    /**
     * Returns column position on the Screen with top and left parameters
     * 
     * @returns {{top:number, left:number}}
     */
    get position() {

        const desktop = document.getElementById(GraphUIConfig.container);

        const dTop = desktop.getBoundingClientRect().top;
        const dLeft = desktop.getBoundingClientRect().left;

        const element = document.getElementById(this.id);

        const top = element.getBoundingClientRect().top - dTop;
        const left = element.getBoundingClientRect().left - dLeft;

        return {
            top,
            left
        }
    }



    //==========================PUBLIC METHODS===========================

    /**
     * sets the width to 100%
     * 
     * @returns {RenderingLevelGH}
     */
    maximize() {
        this.width = 100;

        return this;
    }

    /**
     * resets the width to the default 
     * 
     * @returns {RenderingLevelGH}
     */
    minimize() {
        this.width = this.__defaultWidth;
        return this;
    }


    /**
     * Increases the size of this column and makes it the main
     * @deprecated since version 2.0
     * 
     */
    activate() {
        const desktop = document.getElementById(GraphUIConfig.container);

        const maxWidth = desktop.getBoundingClientRect().width;



        this.width = maxWidth * 0.5
    }

    /**
     * Decreases the size of column and makes it default 
     * @deprecated since version 2.0
     * 
     */
    deActivate() {
        this.__calcDefaultWidth();
    }


    //==========================PRIVATE METHODS===========================

    /**
     * @private
     */
    __calcDefaultWidth() {
        const desktop = document.getElementById(GraphUIConfig.container);

        const maxWidth = desktop.getBoundingClientRect().width;

        this.width = (maxWidth - (maxWidth * 0.5)) / (GraphUIConfig.cols - 1);

    }
}
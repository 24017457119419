import {
    API
} from '../Api.class';
import {
    AnalyzeCategoriesAPI
} from './AnalyzeCategories.api';


export class LeadsAPI extends API {

    constructor(baseUrl) {
        super(baseUrl)


        this.AnalyzeCategory = new AnalyzeCategoriesAPI(baseUrl);
    }


    getUploadUrl(lead) {
        return `${this.baseURL}/api/v1/leads/${lead.id}/attachments/upload`
    }

    get uploadHeaders() {
        return {
            Authorization: `Bearer: ${this.token}`
        }
    }

    async backlog(pagination, filter, meta) {
        this.loading = true

        return await this.__axiosInstance.get('api/v1/leads/backlog', {
            meta,
            params: {
                ...pagination,
                ...filter
            }
        });

    }

    async list(pagination, filter, meta) {
        this.loading = true

        return await this.__axiosInstance.get('api/v1/leads', {
            meta,
            params: {
                ...pagination,
                ...filter
            }
        });

    }

    async get(id, meta) {
        this.loading = true

        return await this.__axiosInstance.get(`api/v1/leads/${id}`, {
            meta
        });

    }


    async create(body, meta) {
        return await this.__axiosInstance.post('api/v1/leads', body, {
            meta
        })
    }

    async update(item, meta) {
        return await this.__axiosInstance.put(`api/v1/leads/${item.id}`, item, {
            meta
        });
    }

    async assign(item, meta) {
        return await this.__axiosInstance.put(`api/v1/leads/${item.id}/assign-to-me`, {}, {
            meta
        });
    }

    async markAsInvalid(item, badwords, meta) {
        return await this.__axiosInstance.put(`api/v1/leads/${item.id}/mark-as-invalid`, {
            badwords
        }, {
            meta
        });
    }

    async statuses(pagination, filter, meta) {
        this.loading = true

        return await this.__axiosInstance.get('api/v1/lead-statuses', {
            meta,
            params: {
                ...pagination,
                ...filter
            }
        })
    }

    async history(pagination, id, meta) {
        return await this.__axiosInstance.get(`api/v1/leads/${id}/history`, {
            meta,
            params: {
                ...pagination,
            }
        });
    }

    async recommended(id, meta) {
        return await this.__axiosInstance.get(`api/v1/leads/${id}/recommended`, {
            meta
        });
    }

    async addHistory(id, body, meta) {
        return await this.__axiosInstance.post(`api/v1/leads/${id}/history`, body, {
            meta
        })
    }

    async delete(lead, meta) {
        this.loading = true

        return await this.__axiosInstance.delete(`api/v1/leads/${lead.id}`, {
            meta
        });

    }


    async deleteAttachment(lead, file, meta) {
        this.loading = true

        return await this.__axiosInstance.delete(`api/v1/leads/${lead.id}/attachments/${file.id}`, {
            meta
        });

    }
}
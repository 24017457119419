<template>
  <v-form ref="form" v-model="pValid" lazy-validation>
    <v-row>
      <v-col cols="12" lg="6" md="6" sm="6" class="new-service-col">
        <HelpFormInputVue
          dark
          v-model="form.customer.businessContact.title"
          :rules="titleRules"
          label="Title"
          :helpText="'Please provide your title in the company above to us know how correctly communicate with you. <br> This information may be used during documents generation and other automated processes.'"
          required
        ></HelpFormInputVue>

        <HelpFormInputVue
          dark
          v-model="form.customer.businessContact.email"
          :rules="emailRules"
          label="Work Email"
          :helpText="'Please provide your work email associated with a company above.'"
          required
        ></HelpFormInputVue>

        <SelectorCountryVue
          dark
          autoload
          label="Your Location"
          helpText="Please provide a country"
          return-object
          v-model="form.settings.country"
        ></SelectorCountryVue>

        <SelectorTimezone
          dark
          autoload
          label="Your Timezone"
          helpText="Please provide a timezone to receive notifications properly"
          return-object
          v-model="form.settings.timezone"
        ></SelectorTimezone>

        <v-alert class="my-2" dense outlined type="success">
          You will be able to change this information after sign in at any time.
          <br />
          Also, you can skip this step and fill in it later.
        </v-alert>
      </v-col>
    </v-row>
  </v-form>
</template>
      
      
<script>
import HelpFormInputVue from "../../atoms/common/inputs/HelpFormInput.vue";
import SelectorCountryVue from "../../atoms/common/autocompletes/SelectorCountry.vue";
import SelectorTimezone from "../../atoms/common/autocompletes/SelectorTimezone.vue";

export default {
  props: {
    value: {},
    valid: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const form = this.value;
    if (!form.customer) form.customer = {};
    if (!form.profile) form.profile = {};
    if (!form.settings) form.settings = {};

    if (!form.customer.businessContact) form.customer.businessContact = {};

    return {
      // Form
      pValid: this.valid,
      form,

      // ======rules
      titleRules: [(v) => !!v || "Title is required"],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      descriptionRules: [(v) => !!v || "Description is required"],
      actions: [
        {
          name: "Create",
          description: "Will create new Tag",
          icon: "mdi-plus",
          color: "#23842a",
          on: "onCreate",
        },
      ],
    };
  },
  components: {
    HelpFormInputVue,
    SelectorCountryVue,
    SelectorTimezone,
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
  watch: {
    value(newVal) {
      console.log("newVal:!!!!!!!!!! ", newVal);

      this.form = newVal;
      if (!this.form.customer) this.form.customer = {};
      if (!this.form.profile) this.form.profile = {};
      if (!this.form.settings) this.form.settings = {};
      if (!this.form.customer.businessContact) this.form.customer.businessContact = {};
    },
    form: {
      handler(newVal) {
        this.$emit("input", newVal);
      },
      deep: true,
    },
    pValid(newVal) {
      this.$emit("onValid", newVal);
    },
  },
};
</script>
      
      
      <style lang="scss" scoped>
.new-service-col {
  display: flex;
  flex-direction: column;
  min-width: 300px;
  flex-grow: 1;
  max-width: 100%;
}
</style>
import {
    CustomerAPIInstance
} from "../../../../../components/api";
import {
    GraphUI
} from "../../../GrapUI.config.js";
import {
    CompanyDetails,
    CustomerDetails
} from "../app.gh.js";

export const CustomerCompanyStore = {
    namespaced: true,
    state() {
        return {
            activeCompanyId: undefined,
            displayedCompanies: new Map(),
            company: {},
            companies: [],
            pagination: {
                page: 1,
                pageSize: 10,
            },
            filter: {
                search: "",
                typeId: "",
            },
        }
    },
    getters: {
        customer: (state) => (id) => {
            const customer = state.displayedCompanies.get(id)
            const response = customer ? customer : {
                user: {
                    profile: {

                    }
                }
            };

            console.log('response: ', JSON.stringify(response))
            return response;
        }
    },
    mutations: {
        setCompany(state, company) {
            state.displayedCompanies.set(company.id, company);
        },
        setActiveId(state, customerId) {
            state.activeCompanyId = customerId;
        },
        setCompanies(state, companies) {
            state.companies = companies;
        },
        setPagination(state, pagination) {
            state.pagination = pagination;
        },
        setFilter(state, filter) {
            state.filter = filter;
        }
    },
    actions: {
        SetActiveCompany({
            commit
        }, {
            from,
            customer
        }) {

            this.scene.add(from, CustomerDetails, {
                customerId: customer.id,
            });

            commit('setActiveId', customer.id)
        },
        async GetCompaniesList({
            commit,
            state
        }, pagination, filter) {
            const resp = await CustomerAPIInstance.Company
                .list(
                    pagination ? pagination : state.pagination,
                    filter ? filter : state.filter);

            console.log('RESP: ', resp)

            commit('setCompanies', resp.items);
            commit('setPagination', pagination);
            commit('setFilter', filter);
        },
        async CreateNewCompany({
            dispatch
        }, {
            from,
            newCompany
        }) {

            const customer = await CustomerAPIInstance.Company
                .create(newCompany);

            dispatch("GetCompaniesList");

            GraphUI.scene.add(from, CompanyDetails, {
                customerId: customer.id
            });
        },
        async GetCompany({
            commit
        }, id) {
            const customer = await CustomerAPIInstance.Company
                .get(id);

            commit('setCompany', customer)
            return customer;
        },

    }
}
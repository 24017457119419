export const SERVICE_PERMISSIONS = {
    ALLOW_SEE_SERVICE_LIST: 'ALLOW_SEE_SERVICE_LIST',
    ALLOW_CREATE_SERVICE: 'ALLOW_CREATE_SERVICE',
    ALLOW_EDIT_SERVICE: 'ALLOW_EDIT_SERVICE',
    ALLOW_EDIT_SERVICE_SETTINGS: 'ALLOW_EDIT_SERVICE_SETTINGS',
    ALLOW_EDIT_SERVICE_HEADER:'ALLOW_EDIT_SERVICE_HEADER',
    ALLOW_EDIT_SERVICE_OVERVIEW:'ALLOW_EDIT_SERVICE_OVERVIEW',
    ALLOW_EDIT_SERVICE_FREE_SECTIONS:'ALLOW_EDIT_SERVICE_FREE_SECTIONS',
    ALLOW_EDIT_SERVICE_USE_CASES:'ALLOW_EDIT_SERVICE_USE_CASES',
    ALLOW_EDIT_SERVICE_DELIVERABLES:'ALLOW_EDIT_SERVICE_DELIVERABLES',
    ALLOW_EDIT_SERVICE_PLANS:'ALLOW_EDIT_SERVICE_PLANS',
}


export const DELIVERABLE_PERMISSIONS = {
    ALLOW_SEE_DELIVERABLE_LIST: 'ALLOW_SEE_DELIVERABLE_LIST',
    ALLOW_CREATE_DELIVERABLE: 'ALLOW_CREATE_DELIVERABLE',
    ALLOW_EDIT_DELIVERABLE: 'ALLOW_EDIT_DELIVERABLE',
    ALLOW_SEE_DELIVERABLE_PRIVATE_DOCUMENTS: 'ALLOW_SEE_DELIVERABLE_PRIVATE_DOCUMENTS',
    ALLOW_SEE_DELIVERABLE_CUSTOMER_DOCUMENTS: 'ALLOW_SEE_DELIVERABLE_CUSTOMER_DOCUMENTS'
}
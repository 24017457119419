export const GRAPH_UI_ERRORS = {

    DEFAULT_ERROR: {
        code: 'GRAPH_UI-DEF-001',
        description: 'Unexpected Error, please make sure that you did everything in the right way. Please check Documentation',
        message: 'Unexpected Graph UI Error'
    },
    METHOD_NOT_IMPLEMENTED: {
        code: 'GRAPH_UI-DEF-002',
        description: 'The method was not implemented, make sure that it was defined properly.',
        message: 'Empty Method'
    }

}
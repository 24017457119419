import Vue from 'vue'
import VueRouter from 'vue-router'
import SignIn from '../views/SignIn.vue'
import SignUp from '../views/SignUp.vue'
import SignInTemplate from '../views/SignInTemplate.vue'
import Desktop from '../views/src/desktop/Desktop.vue';
import {
  store
} from '../main'
import {
  AuthAPIInstance
} from '../components/api'



Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'AuthTemplate',
    redirect: '/desktop',
    component: SignInTemplate,
    children: [{
        path: '/sign-in',
        name: 'SignIn',
        component: SignIn,
      },
      {
        path: '/sign-up',
        name: 'SignUp',
        component: SignUp,
      }
    ]
  },
  {
    path: '/desktop',
    name: 'Desktop',
    component: Desktop,
    meta: {
      auth: true
    }
  },
  {
    path: '*',
    redirect: '/desktop'
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router
  .beforeEach(async (to, from, next) => {
    if (to.meta.auth) {
      const [token, user] = await Promise.all([
        store.dispatch("MainStore/getToken"),
        store.dispatch("MainStore/getUser"),
      ]);

      if (!token || !user) return next({
        path: '/sign-in',
        replace: true
      });

      try {
        await AuthAPIInstance.validateToken(token);
        console.log("TOKEN VALID");

        return next();

      } catch (error) {
        console.log("ERROR", error);
        store.dispatch("MainStore/setAuth", {});
        return next({
          path: '/sign-in',
          replace: true
        });

      }

    }

    return next();
  });

export default router
import {
    AppGH
} from '../../../../../lib/graph-ui/corev2/entities/App.gh.js';
import {
    ViewGH
} from '../../../../../lib/graph-ui/corev2/entities/View.gh.js';


export const LeadAnalyzer = new ViewGH('LEAD_ANALYZER_VIEW', {
    display: {
        icon: 'mdi-magnify-expand',
        name: 'Lead Analyzer',
        description: 'This app can help to analyze potential leads and requests from the customer'
    },
    component: () => import('./views/LeadAnalyzer.vue')

});
// export const ServiceDetails = new ViewGH('SERVICE_DETAILS', {
//     display: {
//         icon: 'mdi-briefcase-eye',
//         name: 'Services Details',
//         description: 'More details on the service'

//     },
//     addStrategy: 'activate',
//     component: () => import('./views/ServiceDetails.vue'),
//     // comparator: (relation, rView) =>
//     // relation.viewA.key === rView.view.key &&
//     // relation.params.serviceId === rView.params.serviceId,
//     // identifier: (relation) => relation.viewA.key + '_' + relation.params.serviceId,
// });




export const LeadAnalyzerApp = new AppGH('LEAD_ANALYZER', {
    display: {
        icon: 'mdi-magnify-expand',
        name: 'Leads Analyzer',
        description: 'This app can help to analyze potential leads and requests from the customer',
    },
    main: LeadAnalyzer,
}).use([
    LeadAnalyzer,
]);
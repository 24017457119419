<template>
  <v-form ref="form" v-model="pValid" lazy-validation>
    <v-row>
      <v-col cols="12" lg="6" md="6" sm="6" class="new-service-col">
        <HelpFormInputVue
          dark
          v-model="form.customer.company.name"
          :rules="nameRules"
          label="Company Name"
          :helpText="'Please provide a Company Name to let the system know how to display you properly. <br> This information may be used during documents generation and other automated processes.'"
          required
        ></HelpFormInputVue>

        <HelpFormInputVue
          dark
          v-model="form.customer.company.website"
          :rules="websiteRules"
          label="Company Website"
          :helpText="'Please provide a Company Website to let us an imagination about your business.'"
          required
        ></HelpFormInputVue>

        <SelectorBusinessTypes
          dark
          autoload
          label="Business Type"
          helpText="You can specify your company type and size in accordance to ADAAS BSM"
          return-object
          v-model="form.customer.company.businessType"
        ></SelectorBusinessTypes>

        <a
          href="https://adaas.org/concept.html#bsm"
          class="right"
          target="blank"
        >
          ADAAS BSM Model
        </a>

        <SelectorBusinessDomains
          dark
          autoload
          label="Business Domains"
          helpText="You can specify multiple business domains that are relevant for your company"
          return-object
          v-model="domains"
          multiple
        ></SelectorBusinessDomains>

        <v-alert class="my-2" dense outlined type="success">
          You will be able to change this information after sign in at any time.
          <br />
          Also, you can skip this step and fill in it later.
        </v-alert>
      </v-col>
    </v-row>
  </v-form>
</template>
        
        
  <script>
import HelpFormInputVue from "../../atoms/common/inputs/HelpFormInput.vue";
import SelectorBusinessDomains from "../../atoms/common/autocompletes/SelectorBusinessDomains.vue";
import SelectorBusinessTypes from "../../atoms/common/autocompletes/SelectorBusinessTypes.vue";
import _ from "lodash";

export default {
  props: {
    value: {},
    valid: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const { domains } = this.prepareLists(this.value);
    const form = this.value;
    if (!form.customer) form.customer = {};
    if (!form.customer.company) form.customer.company = {};

    return {
      // Form
      pValid: this.valid,
      form,
      domains,

      // ======rules
      nameRules: [(v) => !!v || "Company Name is required"],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      websiteRules:[
      (v) =>
          !v || /(https?:\/\/[^\s]+)/.test(v) || "Please provide a link starting with http(s)://... ",
      ],
      descriptionRules: [(v) => !!v || "Description is required"],
      actions: [
        {
          name: "Create",
          description: "Will create new Tag",
          icon: "mdi-plus",
          color: "#23842a",
          on: "onCreate",
        },
      ],
    };
  },
  components: {
    HelpFormInputVue,
    SelectorBusinessTypes,
    SelectorBusinessDomains,
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    prepareLists(value) {
      let domains = [];

      if (value.domains && value.domains.length)
        domains = value.domains.map((t) => t.domain);

      return { domains };
    },
  },
  watch: {
    value: {
      handler(newVal) {
        const { domains } = this.prepareLists(newVal);

        this.domains = Object.assign(this.domains, domains);

        this.form = newVal;
        if (!this.form.customer) this.form.customer = {};
        if (!this.form.customer.company) this.form.customer.company = {};
      },
      deep: true,
    },
    form: {
      handler(newVal) {
        this.$emit("input", newVal);
      },
      deep: true,
    },
    pValid(newVal) {
      this.$emit("onValid", newVal);
    },
    domains: {
      handler(newVal) {
        this.form.customer.company.companyDomains = newVal.map(
          (domain, i, arr) => {
            const existed = _.find(arr, (t) => t.domain_id === domain.id);

            return existed
              ? existed
              : {
                  domain,
                };
          }
        );
      },
      deep: true,
    },
  },
};
</script>
        
        
<style lang="scss" scoped>
.new-service-col {
  display: flex;
  flex-direction: column;
  min-width: 300px;
  flex-grow: 1;
  max-width: 100%;
}

.right {
  text-align: end;
}
</style>
import Vue from "vue";
import {
    LeadsAPIInstance
} from "../../../../../components/api";
import {
    GUI
} from "../../../GrapUI.config.js";
import {
    LeadAttachments,
    LeadCreation,
    LeadDetails,
    // LeadFilesEditor,
    LeadGeneralEditor,
    LeadHistory,
    LeadList,
} from "../app.gh.js";


// Create a new store instance.
export const LeadStore = {
    namespaced: true,
    state() {
        return {
            activeLeadId: undefined,
            displayedLeads: [],
            lead: {},
            leads: [],
            pagination: {
                page: 1,
                pageSize: 100,
            },
            filter: {
                search: "",
                type: "",
            },
            leadUpdateTimeout: undefined,
            loadingMap: new Map(),
            board: {
                NEW: [],
                TAKEN_TO_WORK: [],
                NO_REPLY: [],
                ACTIVE: [],
                VERBAL_OK: [],
                DOCUMENTS_PREPARATION: [],
                COMPLETED: [],
                ARCHIVED: []
            },
            statuses: [],
            history: {}
        }
    },
    getters: {
        leadById: (state) => (id) => {
            const index = state.displayedLeads.findIndex(s => s.id === id)

            if (index !== -1)
                return state.displayedLeads[index];
            else
                return {
                    id,
                    sections: []
                }

        },
        historyById: (state) => id => {
            return state.history[id] && state.history[id].items ? state.history[id].items : []
        }
    },
    mutations: {
        setLead(state, lead) {
            let index = state.displayedLeads.findIndex(d => d.id === lead.id)
            let listIndex = state.leads.findIndex(d => d.id === lead.id)

            if (index !== -1)
                state.displayedLeads.splice(index, 1, lead);
            else
                index = state.displayedLeads.push(lead);


            if (listIndex !== -1) {
                state.leads.splice(listIndex, 1, lead)
            }

        },
        setActiveId(state, leadId) {
            state.activeLeadId = leadId;
        },
        setLeads(state, {
            items,
            reset
        } = {}) {
            if (reset)
                state.leads = items;

            else
                items.forEach(lead => {
                    const leadIndex = state.leads.findIndex(del => del.id === lead.id);

                    if (leadIndex !== -1)
                        state.leads.splice(leadIndex, 1, lead)
                    else
                        state.leads.push(lead)
                });
        },
        setPagination(state, pagination) {
            state.pagination = pagination;
        },
        setFilter(state, filter) {
            state.filter = filter;
        },
        setBoard(state, {
            items,
            status
        }) {
            state.board[status] = items
        },
        setStatuses(state, {
            items
        }) {
            state.statuses = items
        },
        setHistory(state, {
            id,
            resp
        }) {
            Vue.set(state.history, id, resp)
        }
    },
    actions: {

        //============PAGES==========================
        openLeadList(props, {
            from,
        }) {


            GUI.scene.add(from, LeadList);
        },

        openLeadDetails(props, {
            from,
            lead
        }) {
            const fromParams = from.relation && from.relation.params ? {
                ...from.relation.params
            } : undefined

            if (fromParams && fromParams.fromParams)
                delete fromParams.fromParams

            GUI.scene.add(from, LeadDetails, {
                leadId: lead.id,
                fromParams
            });
        },

        openLeadCreation(props, {
            from,
        }) {
            const fromParams = from.relation && from.relation.params ? {
                ...from.relation.params
            } : undefined

            if (fromParams && fromParams.fromParams)
                delete fromParams.fromParams

            GUI.scene.add(from, LeadCreation, {
                fromParams
            });
        },

        openLeadGeneralEditor(props, {
            from,
            lead
        }) {
            const fromParams = from.relation && from.relation.params ? {
                ...from.relation.params
            } : undefined

            if (fromParams && fromParams.fromParams)
                delete fromParams.fromParams

            GUI.scene.add(from, LeadGeneralEditor, {
                leadId: lead.id,
                fromParams
            });
        },

        openLeadFilesEditor(props, {
            from,
            lead
        }) {
            const fromParams = from.relation && from.relation.params ? {
                ...from.relation.params
            } : undefined

            if (fromParams && fromParams.fromParams)
                delete fromParams.fromParams

            GUI.scene.add(from, LeadAttachments, {
                leadId: lead.id,
                fromParams
            });
        },
        openLeadHistory(props, {
            from,
            lead
        }) {
            const fromParams = from.relation && from.relation.params ? {
                ...from.relation.params
            } : undefined

            if (fromParams && fromParams.fromParams)
                delete fromParams.fromParams

            GUI.scene.add(from, LeadHistory, {
                leadId: lead.id,
                fromParams
            });
        },

        SetActiveLead({
            commit
        }, {
            from,
            lead
        }) {

            const fromParams = from.relation && from.relation.params ? {
                ...from.relation.params
            } : undefined

            if (fromParams && fromParams.fromParams)
                delete fromParams.fromParams

            GUI.scene.add(from, LeadDetails, {
                leadId: lead.id,
                fromParams
            });

            commit('setActiveId', lead.id)
        },

        // Leads API 
        async GetLeadsList({
            commit,
            state
        }, {
            pagination,
            filter,
            reset,
            next
        } = {}) {
            if (reset) {
                pagination = pagination ? pagination : {}
                filter = filter ? filter : {}
            } else {
                pagination = pagination ? pagination : state.pagination
                filter = filter ? filter : state.filter
            }

            if (next)
                pagination.page++;


            const resp = await LeadsAPIInstance
                .backlog(
                    pagination,
                    filter);

            commit('setLeads', {
                items: resp.items,
                reset
            });
            commit('setPagination', resp.pagination);
            commit('setFilter', filter);
        },


        async GetLeadStatuses({
            commit,
        }) {
            const resp = await LeadsAPIInstance
                .statuses({
                    page: 1,
                    pageSize: 100
                });

            commit('setStatuses', {
                items: resp.items,
            });
        },

        async GetLeadHistory({
            commit,
            state
        }, {
            id,
            next
        }) {
            if (next)
                state.history[id].pagination.page++;

            const resp = await LeadsAPIInstance
                .history({
                    page: state.history[id] && state.history[id].pagination.page || 1,
                    pageSize: state.history[id] && state.history[id].pagination.pageSize || 20
                }, id);

            commit('setHistory', {
                resp,
                id
            });
        },


        async getLeadRecommended(props, {
            id
        }) {

            const recommendations = await LeadsAPIInstance
                .recommended(id);

            // commit('setHistory', {
            //     resp,
            //     id
            // });

            return recommendations;
        },


        async AddLeadHistory({
            dispatch
        }, {
            id,
            data
        }) {
            await LeadsAPIInstance
                .addHistory(id, data, {
                    toast: {
                        message: "Comment saved",
                    },
                });


            if (data.status)
                dispatch('GetAllMyLeads');

            dispatch('GetLeadHistory', {
                id
            });

            dispatch("GetLead", {
                id,
                refresh: true
            })
        },




        async GetMyLeads({
            commit,
        }, {
            filter,
            status
        } = {}) {

            const resp = await LeadsAPIInstance
                .list({
                    page: 1,
                    pageSize: 100
                }, {
                    status,
                    ...filter
                });

            commit('setBoard', {
                status,
                items: resp.items
            });
        },
        async GetAllMyLeads({
            dispatch,
        }) {

            dispatch('GetMyLeads', {
                status: 'NEW'
            })

            dispatch('GetMyLeads', {
                status: 'TAKEN_TO_WORK'
            })

            dispatch('GetMyLeads', {
                status: 'NO_REPLY'
            })
            dispatch('GetMyLeads', {
                status: 'ACTIVE'
            })

            dispatch('GetMyLeads', {
                status: 'VERBAL_OK'
            })

            dispatch('GetMyLeads', {
                status: 'DOCUMENTS_PREPARATION'
            })

            dispatch('GetMyLeads', {
                status: 'COMPLETED'
            })

            dispatch('GetMyLeads', {
                status: 'ARCHIVED'
            })
        },

        async CreateNewLead({
            dispatch
        }, {
            from,
            newLead
        }) {

            const lead = await LeadsAPIInstance
                .create(newLead);

            dispatch("GetLeadsList");

            GUI.scene.add(from, LeadDetails, {
                leadId: lead.id
            });
        },


        async GetLead({
            commit,
            state,
            getters
        }, {
            id,
            refresh
        }) {
            switch (true) {

                // when service loading in progress
                // -> just wait 
                case state.loadingMap.get(id) === 'LOADING': {
                    await new Promise((resolve) => {
                        let i = 0;

                        const handler = () => {
                            if (state.loadingMap.get(id) === 'COMPLETED') {
                                return resolve()
                            } else
                                setTimeout(handler, ++i + 100);
                        }

                        setTimeout(handler, 100);
                    });


                    break;
                }

                // when service loaded and Cache Exists
                // -> return existed cache 
                case state.loadingMap.get(id) === 'COMPLETED' && !refresh: {
                    break;
                }

                // when service haven't been ever loaded
                //   OR
                // when service loaded but cache requires refresh
                default: {
                    state.loadingMap.set(id, 'LOADING');

                    const lead = await LeadsAPIInstance
                        .get(id);

                    commit('setLead', lead)

                    state.loadingMap.set(id, 'COMPLETED');

                    return lead;

                }

            }

            return getters.leadById(id);

        },
        async UpdateLead({
            state,
            commit,
            dispatch
        }, {
            lead
        }) {

            if (state.leadUpdateTimeout)
                clearTimeout(state.leadUpdateTimeout)

            state.leadUpdateTimeout = setTimeout(async () => {
                commit('setLead', lead);

                await LeadsAPIInstance.update(lead, {
                    toast: {
                        message: "Lead Updated",
                    },
                });
                dispatch('GetLeadHistory', {
                    id: lead.id
                })

            }, 1000);




        },

        async assign({
            dispatch
        }, {
            lead
        }) {

            await LeadsAPIInstance.assign(lead, {
                toast: {
                    message: "Lead Assigned",
                },
            });

            dispatch('GetAllMyLeads')
            dispatch('GetLeadsList', {
                reset: true
            })

        },
        async markAsInvalid({
            dispatch
        }, {
            lead,
            badwords
        }) {

            await LeadsAPIInstance.markAsInvalid(lead, badwords, {
                toast: {
                    message: "Lead Marked as Invalid",
                },
            });
            setTimeout(() => {
                dispatch('GetLeadsList', {
                    reset: true
                });
            }, 800);
        },

        async DeleteLead({
            dispatch
        }, {
            lead
        }) {
            await LeadsAPIInstance.delete(lead, {
                toast: {
                    message: "Lead Removed",
                },
            });

            dispatch('GetLead', {
                id: lead.id,
                refresh: true
            });
        },

        async DeleteLeadAttachment({
            dispatch
        }, {
            lead,
            file,
        }) {
            await LeadsAPIInstance.deleteAttachment(lead, file, {
                toast: {
                    message: "Attachment Removed",
                },
            });

            dispatch('GetLead', {
                id: lead.id,
                refresh: true
            });
        },
    }
}
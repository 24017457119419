import {
    API
} from './Api.class';


export class DeliverablesAPI extends API {

    constructor(baseUrl) {
        super(baseUrl)

    }


    getUploadUrl(deliverable) {
        return `${this.baseURL}/api/v1/deliverables/${deliverable.id}/attachments`
    }

    get uploadHeaders() {
        return {
            Authorization: `Bearer: ${this.token}`
        }
    }


    async list(pagination, filter, meta) {
        this.loading = true

        return await this.__axiosInstance.get('api/v1/deliverables', {
            meta,
            params: {
                ...pagination,
                ...filter
            }
        })

    }

    async get(id, meta) {
        this.loading = true

        return await this.__axiosInstance.get(`api/v1/deliverables/${id}`, {
            meta
        });

    }


    async create(body, meta) {
        return await this.__axiosInstance.post('api/v1/deliverables', body, {
            meta
        })
    }

    async update(item, meta) {
        return await this.__axiosInstance.put(`api/v1/deliverables/${item.id}`, item, {
            meta
        });
    }

    async statuses(pagination, filter, meta) {
        this.loading = true

        return await this.__axiosInstance.get('api/v1/deliverable-statuses', {
            meta,
            params: {
                ...pagination,
                ...filter
            }
        })
    }

    async delete(deliverable, meta) {
        this.loading = true

        return await this.__axiosInstance.delete(`api/v1/deliverables/${deliverable.id}`, {
            meta
        });

    }


    async deleteAttachment(deliverable, type, file, meta) {
        this.loading = true

        return await this.__axiosInstance.delete(`api/v1/deliverables/${deliverable.id}/attachments/${type}/file/${file.id}`, {
            meta
        });

    }
}
import {
    GlossaryAPIInstance
} from "../../../../../components/api";
import {
    GUI
} from "../../../GrapUI.config.js";
import {
    Glossary,
    TermCreation,
    TermDetails,
    TermGeneralEditor,
} from "../app.gh.js";


// Create a new store instance.
export const GlossaryStore = {
    namespaced: true,
    state() {
        return {
            activeTermId: undefined,
            displayedTerms: [],
            term: {},
            terms: [],
            letters: [],
            activeSectionId: 'head',
            pagination: {
                page: 1,
                pageSize: 10,
            },
            filter: {
                search: "",
                typeId: "",
            },
            updateTimeout: undefined
        }
    },
    getters: {
        termById: (state) => (id) => {
            const index = state.displayedTerms.findIndex(s => s.id === id)

            if (index !== -1)
                return {
                    ...state.displayedTerms[index]
                }
            else
                return {
                    id
                }

        }
    },
    mutations: {
        setTerm(state, term) {
            let index = state.displayedTerms.findIndex(s => s.id === term.id)
            let listIndex = state.terms.findIndex(s => s.id === term.id)

            if (index !== -1)
                state.displayedTerms.splice(index, 1, term);
            else
                index = state.displayedTerms.push(term);


            if (listIndex !== -1 && !term.IN_PROGRESS) {
                state.terms.splice(listIndex, 1, term)
            }

        },
        setActiveId(state, termId) {
            state.activeTermId = termId;
        },
        setActiveSectionId(state, activeSectionId) {
            state.activeSectionId = activeSectionId;
        },
        setTerms(state, {
            items,
            reset
        } = {}) {
            if (reset)
                state.terms = items;

            else
                items.forEach(term => {
                    const deliverableIndex = state.terms.findIndex(it => it.id === term.id);

                    if (deliverableIndex !== -1)
                        state.terms.splice(deliverableIndex, 1, term)
                    else
                        state.terms.push(term)
                });

        },
        setLetters(state, letters) {
            state.letters = letters;
        },
        setPagination(state, pagination) {
            state.pagination = pagination;
        },
        setFilter(state, filter) {
            state.filter = filter;
        }
    },
    actions: {
        openTermList(props, {
            from,
        }) {
            GUI.scene.add(from, Glossary);
        },
        openTermCreation(props, {
            from,
        }) {
            GUI.scene.add(from, TermCreation);
        },
        openTermDetails(props, {
            from,
            term
        }) {
            GUI.scene.add(from, TermDetails, {
                termId: term.id,
            });
        },
        openTermGeneralEditor(props, {
            from,
            term
        }) {
            console.log('term: ', term)

            GUI.scene.add(from, TermGeneralEditor, {
                termId: term.id,
            });
        },

        SetActiveTerm({
            commit
        }, {
            from,
            term
        }) {

            GUI.scene.add(from, TermDetails, {
                termId: term.id,
            });

            commit('setActiveId', term.id)
        },
        // Terms API 
        async GetTermsList({
            commit,
            state
        }, {
            pagination,
            filter,
            reset,
            next
        } = {}) {
            if (reset) {
                pagination = pagination ? pagination : {}
                filter = filter ? filter : {}
            } else {
                pagination = pagination ? pagination : state.pagination
                filter = filter ? filter : state.filter
            }

            if (next)
                pagination.page++;


            const resp = await GlossaryAPIInstance
                .Terms
                .list(
                    pagination,
                    filter);

            commit('setTerms', {
                items: resp.items,
                reset
            });
            commit('setPagination', resp.pagination);
            commit('setFilter', filter);
        },

        async GetLetters({
            commit,
        }) {
            const resp = await GlossaryAPIInstance
                .Terms
                .letters();

            commit('setLetters', resp);
        },
        async CreateNewTerm({
            dispatch
        }, {
            from,
            newTerm
        }) {

            const term = await GlossaryAPIInstance
                .Terms
                .create(newTerm, {
                    toast: {
                        message: "Term Created",
                    },
                });

            dispatch("GetTermsList");

            GUI.scene.add(from, TermDetails, {
                termId: term.id
            });
        },
        async GetTerm({
            commit,
            state
        }, {
            id,
            refresh
        }) {

            let index = state.displayedTerms.findIndex(s => s.id === id)



            if (!refresh && index !== -1) {
                // TODO move to some helper or so... 
                const newIndex = await new Promise((resolve) => {
                    let i = 0;

                    const handler = () => {
                        let index = state.displayedTerms.findIndex(s => s.id === id)
                        if (state.displayedTerms[index] && !state.displayedTerms[index].IN_PROGRESS)
                            return resolve(index)
                        else
                            setTimeout(handler, ++i * 100);
                    }
                    setTimeout(handler, 100);
                })

                return state.displayedTerms[newIndex];
            }

            if (index === -1)
                commit('setTerm', {
                    id,
                    //just to prevent loading 
                    IN_PROGRESS: true,
                    sections: []
                })

            const term = await GlossaryAPIInstance
                .Terms
                .get(id);


            commit('setTerm', term)

            index = state.displayedTerms.findIndex(s => s.id === id)


            console.log('state.displayedTerms[index]', state.displayedTerms[index])
            console.log('state.displayedTerms[index]', state.displayedTerms)

            return state.displayedTerms[index];
        },

        async UpdateTerm({
            state,
            commit
        }, {
            term
        }) {

            commit('setTerm', term);

            if (state.updateTimeout)
                clearTimeout(state.updateTimeout)

            state.updateTimeout = setTimeout(async () => {
                await GlossaryAPIInstance.Terms.update(term, {
                    toast: {
                        message: "Term Config Updated",
                    },
                });
            }, 1000);

        },

        async DeleteTerm({
            dispatch
        }, term) {
            await GlossaryAPIInstance.Terms.delete(term, {
                toast: {
                    message: "Term Config Updated",
                },
            });

            dispatch('GetTerm', {
                id: term.id,
                refresh: true
            });
        },

    }
}
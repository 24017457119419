import {
    KnowledgeBaseAPIInstance
} from "../../../../../components/api";
import {
    GUI
} from "../../../GrapUI.config.js";
import {
    TagCreation,
    TagDetails,
} from "../app.gh.js";


// Create a new store instance.
export const TagsStore = {
    namespaced: true,
    state() {
        return {
            activeTagId: undefined,
            displayedTags: [],
            tag: {},
            tags: [],
            activeSectionId: 'head',
            pagination: {
                page: 1,
                pageSize: 10,
            },
            filter: {
                search: "",
                typeId: "",
            },
        }
    },
    getters: {
        tagById: (state) => (id) => {
            const index = state.displayedTags.findIndex(s => s.id === id)

            if (index !== -1)
                return state.displayedTags[index]
            else
                return {
                    id,
                    sections: []
                }

        }
    },
    mutations: {
        setTag(state, tag) {
            let index = state.displayedTags.findIndex(s => s.id === tag.id)
            let listIndex = state.tags.findIndex(s => s.id === tag.id)

            if (index !== -1)
                state.displayedTags.splice(index, 1, tag);
            else
                index = state.displayedTags.push(tag);


            if (listIndex !== -1 && !tag.IN_PROGRESS) {
                state.tags.splice(listIndex, 1, tag)
            }

        },
        setActiveId(state, tagId) {
            state.activeTagId = tagId;
        },
        setActiveSectionId(state, activeSectionId) {
            state.activeSectionId = activeSectionId;
        },
        setTags(state, tags) {
            state.tags = tags;
        },
        setPagination(state, pagination) {
            state.pagination = pagination;
        },
        setFilter(state, filter) {
            state.filter = filter;
        }
    },
    actions: {
        // GraphUI Action 
        openTagCreation(props, {
            from,
        }) {
            GUI.scene.add(from, TagCreation);
        },
        openTagDetails(props, {
            from,
            tag
        }) {
            GUI.scene.add(from, TagDetails, {
                tagId: tag.id,
            });
        },
        openTagList(props, {
            from,
            tag
        }) {
            GUI.scene.add(from, TagDetails, {
                tagId: tag.id,
            });
        },

        SetActiveTag({
            commit
        }, {
            from,
            tag
        }) {

            GUI.scene.add(from, TagDetails, {
                tagId: tag.id,
            });

            commit('setActiveId', tag.id)
        },
        // Tags API 
        async GetTagsList({
            commit,
            state
        }, pagination, filter) {
            const resp = await KnowledgeBaseAPIInstance
                .Tags
                .list(
                    pagination ? pagination : state.pagination,
                    filter ? filter : state.filter);

            commit('setTags', resp.items);
            commit('setPagination', pagination);
            commit('setFilter', filter);
        },
        async CreateNewTag(opt, {
            newTag
        }) {

            await KnowledgeBaseAPIInstance
                .Tags
                .create(newTag, {
                    toast: {
                        message: "Tag Created",
                    },
                });

            // dispatch("GetTagsList");

        },
        async GetTag({
            commit,
            state
        }, {
            id,
            refresh
        }) {

            let index = state.displayedTags.findIndex(s => s.id === id)

            if (!refresh && index !== -1) {
                // TODO move to some helper or so... 
                const newIndex = await new Promise((resolve) => {
                    let i = 0;

                    const handler = () => {
                        let index = state.displayedTags.findIndex(s => s.id === id)
                        if (state.displayedTags[index] && !state.displayedTags[index].IN_PROGRESS)
                            return resolve(index)
                        else
                            setTimeout(handler, ++i * 100);
                    }
                    setTimeout(handler, 100);
                })

                return state.displayedTags[newIndex];
            }

            if (index === -1)
                commit('setTag', {
                    id,
                    //just to prevent loading 
                    IN_PROGRESS: true,
                    sections: []
                })

            const tag = await KnowledgeBaseAPIInstance
                .Tags
                .get(id);


            commit('setTag', tag)

            index = state.displayedTags.findIndex(s => s.id === id)

            return state.displayedTags[index];
        },

        async UpdateTag({
            dispatch
        }, tag) {
            await KnowledgeBaseAPIInstance.Tags.update(tag, {
                toast: {
                    message: "Tag Config Updated",
                },
            });

            dispatch('GetTag', {
                id: tag.id,
                refresh: true
            });
        },

        async DeleteTag({
            dispatch
        }, tag) {
            await KnowledgeBaseAPIInstance.Tags.delete(tag, {
                toast: {
                    message: "Tag Config Updated",
                },
            });

            dispatch('GetTag', {
                id: tag.id,
                refresh: true
            });
        },

    }
}
//|##########################################################|
//|                      GraphUI V2.0.0                      |
//|----------------------------------------------------------|
//|                © Copyright 2022 adaas.org                |
//|                All rights reserved ADAAS                 |
//|----------------------------------------------------------|
//| Created At: 11.16.2022                                   |
//| Created By: Andrew Tsariuk (andrew.t@adaas.org)          |
//|__________________________________________________________|
//|###########################################################

import * as uuid from "uuid";
/* eslint-disable no-unused-vars */
import {
    RelationGH
} from "../entities/Relation.gh.js";
import {
    ViewGH
} from "../entities/View.gh.js";
import {
    GraphUIConfig
} from "../GraphUI.class.js";

/* eslint-enable no-unused-vars */



export class RenderingViewGH {



    /**
     * This is an entity that is responsible for the visualization. 
     * Each components that displays in the grid is a unique Rendering View.
     * 
     * Each Rendering Vies should be based on the View + Relation.
     * - View uses to identify display properties
     * - Relations uses to identify parameters 
     * 
     * 
     * In other classes/parameters it may be displayed as rView
     * 
     * @param {RelationGH | ViewGH} viewOrRelations
     */
    constructor(viewOrRelations) {


        /**
         * Unique identifier of the View. 
         * It will be used to draw items and calculate positions
         * @type {string} 
         */
        this.id = uuid.v4();


        /**
         * The relation that is a basis for the Rendering View
         * 
         * @type {RelationGH}
         */
        this.relation = viewOrRelations instanceof ViewGH ?
            new RelationGH(viewOrRelations, viewOrRelations, {}) :
            viewOrRelations;


        /**
         * A view that should be rendered
         * 
         * @type {ViewGH}
         */
        this.view = this.relation.to;


        /**
         * this is height of the component
         * 
         * @type {number}
         */
        this.height = this.view.display.constHeight ? this.view.display.constHeight : 0;


        /**
         * this is width of the component
         * 
         * @type {number}
         */
        this.width = 0;

        /**
         * the minimum allowed height
         * 
         * @type {number}
         */
        this.minHeight = this.relation.to.display.minHeight;

        /**
         * Should be true if a focus on this rView
         * 
         * @type {boolean}
         */
        this.isFocused = false;

        /**
         * True if rView should be expanded
         * @type {boolean}
         */
        this.isExpanded = false;

        /**
         * True if this rView should be maximized
         * 
         * @type {boolean}
         */
        this.isMaximized = false;

        /**
         * This is a set of actual permissions it represents user access to the registered permissions. 
         * It can be filled out of this view using different handlers and actions
         * by default user all permissions ARE FALSE
         * 
         * @type {Object.<string, boolean>}
         */
        this.permissions = {};

        this.setPermissions();
        /**
         * Rendering View Initialization Date
         * 
         * @type {Date}
         */
        this.created = new Date()
    }

    /**
     * This method sets permissions based on te input value. 
     * If value not provided it will use default view permissions set to FALSE
     * 
     * @param {Object.<string,boolean> | undefined} permsObject 
     */
    setPermissions(permsObject = {}) {
        this.permissions = permsObject;

        // if (!permsObject) {
        //     this.view.permissions.forEach(originalPerm => {
        //         this.permissions.set(originalPerm, false);
        //     });

        // } else
        //     this.view.permissions.forEach(originalPerm => {
        //         this.permissions.set(originalPerm, permsObject[originalPerm]);
        //     });
    }









    /**
     * Returns top and left position of the Rendering View
     * 
     * @returns {{top: number, left: number}}
     */
    get position() {

        const desktop = document.getElementById(GraphUIConfig.container);

        const dTop = desktop.getBoundingClientRect().top;
        const dLeft = desktop.getBoundingClientRect().left;

        let top = 0;
        let left = 0;

        const element = document.getElementById(this.id);

        if (element) {
            top = element.getBoundingClientRect().top - dTop;
            left = element.getBoundingClientRect().left - dLeft;

        }

        return {
            top,
            left
        }
    }

    get maxWidth() {
        const desktop = document.getElementById(GraphUIConfig.container);

        return desktop.getBoundingClientRect().width;
    }

    get maxHeight() {
        const desktop = document.getElementById(GraphUIConfig.container);

        return desktop.getBoundingClientRect().height;
    }


    /**
     * Will compare a rendering view based on the view comparator
     * 
     * @param {RenderingViewGH} rView 
     */
    isEqual(rView) {

        //TODO add custom comparator

        return this.__defaultIdentifier() === this.__defaultIdentifier(rView)
    }


    /**
     * Will return default RenderingView identifier.
     * If rView parameter was not provided will be used this as default
     * 
     * 
     * @private
     * @param {RenderingViewGH} rView 
     * @returns {string}
     */
    __defaultIdentifier(rView) {
        const source = rView ? rView : this;

        return source.view.key + '_' +
            JSON.stringify(source.relation.params);

    }
}
import {
    DeliverablesAPIInstance
} from "../../../../../components/api";
import {
    GUI
} from "../../../GrapUI.config.js";
import {
    DeliverableCreation,
    DeliverableDetails,
    DeliverableFilesEditor,
    DeliverableGeneralEditor,
    DeliverableList,
} from "../app.gh.js";


// Create a new store instance.
export const DeliverableStore = {
    namespaced: true,
    state() {
        return {
            activeDeliverableId: undefined,
            displayedDeliverables: [],
            deliverable: {},
            deliverables: [],
            activeSectionId: 'head',
            pagination: {
                page: 1,
                pageSize: 10,
            },
            filter: {
                search: "",
                type: "",
            },
            deliverableUpdateTimeout: undefined,
            loadingMap: new Map()
        }
    },
    getters: {
        deliverableById: (state) => (id) => {
            const index = state.displayedDeliverables.findIndex(s => s.id === id)

            if (index !== -1)
                return state.displayedDeliverables[index];
            else
                return {
                    id,
                    sections: []
                }

        }
    },
    mutations: {
        setDeliverable(state, deliverable) {
            let index = state.displayedDeliverables.findIndex(d => d.id === deliverable.id)
            let listIndex = state.deliverables.findIndex(d => d.id === deliverable.id)

            if (index !== -1)
                state.displayedDeliverables.splice(index, 1, deliverable);
            else
                index = state.displayedDeliverables.push(deliverable);


            if (listIndex !== -1) {
                state.deliverables.splice(listIndex, 1, deliverable)
            }

        },
        setActiveId(state, deliverableId) {
            state.activeDeliverableId = deliverableId;
        },
        setDeliverables(state, {
            items,
            reset
        } = {}) {
            if (reset)
                state.deliverables = items;

            else
                items.forEach(deliverable => {
                    const deliverableIndex = state.deliverables.findIndex(del => del.id === deliverable.id);

                    if (deliverableIndex !== -1)
                        state.deliverables.splice(deliverableIndex, 1, deliverable)
                    else
                        state.deliverables.push(deliverable)
                });
        },
        setPagination(state, pagination) {
            state.pagination = pagination;
        },
        setFilter(state, filter) {
            state.filter = filter;
        }
    },
    actions: {

        //============PAGES==========================
        openDeliverableList(props, {
            from,
        }) {
            GUI.scene.add(from, DeliverableList);
        },

        openDeliverableDetails(props, {
            from,
            deliverable
        }) {
            GUI.scene.add(from, DeliverableDetails, {
                deliverableId: deliverable.id
            });
        },

        openDeliverableCreation(props, {
            from,
        }) {
            GUI.scene.add(from, DeliverableCreation);
        },

        openDeliverableGeneralEditor(props, {
            from,
            deliverable
        }) {
            GUI.scene.add(from, DeliverableGeneralEditor, {
                deliverableId: deliverable.id,
            });
        },

        openDeliverableFilesEditor(props, {
            from,
            deliverable
        }) {
            GUI.scene.add(from, DeliverableFilesEditor, {
                deliverableId: deliverable.id,
            });
        },

        SetActiveDeliverable({
            commit
        }, {
            from,
            deliverable
        }) {

            GUI.scene.add(from, DeliverableDetails, {
                deliverableId: deliverable.id,
            });

            commit('setActiveId', deliverable.id)
        },

        // Deliverables API 
        async GetDeliverablesList({
            commit,
            state
        }, {
            pagination,
            filter,
            reset,
            next
        } = {}) {
            if (reset) {
                pagination = pagination ? pagination : {}
                filter = filter ? filter : {}
            } else {
                pagination = pagination ? pagination : state.pagination
                filter = filter ? filter : state.filter
            }

            if (next)
                pagination.page++;


            const resp = await DeliverablesAPIInstance
                .list(
                    pagination,
                    filter);

            commit('setDeliverables', {
                items: resp.items,
                reset
            });
            commit('setPagination', resp.pagination);
            commit('setFilter', filter);
        },

        async CreateNewDeliverable({
            dispatch
        }, {
            from,
            newDeliverable
        }) {

            const deliverable = await DeliverablesAPIInstance
                .create(newDeliverable);

            dispatch("GetDeliverablesList",{
                reset:true
            });

            GUI.scene.add(from, DeliverableDetails, {
                deliverableId: deliverable.id
            });
        },


        async GetDeliverable({
            commit,
            state,
            getters
        }, {
            id,
            refresh
        }) {
            switch (true) {

                // when service loading in progress
                // -> just wait 
                case state.loadingMap.get(id) === 'LOADING': {
                    await new Promise((resolve) => {
                        let i = 0;

                        const handler = () => {
                            if (state.loadingMap.get(id) === 'COMPLETED') {
                                return resolve()
                            } else
                                setTimeout(handler, ++i + 100);
                        }

                        setTimeout(handler, 100);
                    });


                    break;
                }

                // when service loaded and Cache Exists
                // -> return existed cache 
                case state.loadingMap.get(id) === 'COMPLETED' && !refresh: {
                    break;
                }

                // when service haven't been ever loaded
                //   OR
                // when service loaded but cache requires refresh
                default: {
                    state.loadingMap.set(id, 'LOADING');

                    const service = await DeliverablesAPIInstance
                        .get(id);

                    commit('setDeliverable', service)

                    state.loadingMap.set(id, 'COMPLETED');

                    break;
                }

            }

            return getters.deliverableById(id);

        },
        async UpdateDeliverable({
            state,
            commit
        }, {
            deliverable
        }) {

            if (state.deliverableUpdateTimeout)
                clearTimeout(state.deliverableUpdateTimeout)

            state.deliverableUpdateTimeout = setTimeout(async () => {
                commit('setDeliverable', deliverable);

                await DeliverablesAPIInstance.update(deliverable, {
                    toast: {
                        message: "Deliverable Updated",
                    },
                });
            }, 1000);

        },


        async DeleteDeliverable({
            dispatch
        }, {
            deliverable
        }) {
            await DeliverablesAPIInstance.delete(deliverable, {
                toast: {
                    message: "Deliverable Removed",
                },
            });

            dispatch('GetDeliverable', {
                id: deliverable.id,
                refresh: true
            });
        },

        async DeleteDeliverableAttachment({
            dispatch
        }, {
            deliverable,
            file,
            type
        }) {
            await DeliverablesAPIInstance.deleteAttachment(deliverable, type, file, {
                toast: {
                    message: "Attachment Removed",
                },
            });

            dispatch('GetDeliverable', {
                id: deliverable.id,
                refresh: true
            });
        },
    }
}
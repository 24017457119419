/* eslint-disable no-unused-vars */
import {
    ViewGH
} from "./View.gh.js";
/* eslint-enable no-unused-vars */



/**
 * 
 * 
 * @typedef {Object} AppGHDisplayOptions - Object that provides display options of the  Graph UI APP. 
 * @property {string} icon -  unique icon that might be use for rendering 
 * @property {string} name -  display name of the App 
 * @property {string} color - App display color 
 * @property {string} description -  display description of the App 
 * 
 * @typedef {Object} AppGHOptions - Graph UI Application constructor options
 * @property {ViewGH | ViewGH[]} main -  main view to start from. 
 * @property {AppGHDisplayOptions} display -  object that provides display options of the APP. 
 * 
 *
 * 
 */
export class AppGH {


    /**
     * This class provides an abstract over GraphUI App.
     * Each APP is a logical module that has a main View to start with and basic relations. 
     * Each APP should have at least one view.
     * 
     * 
     * @param {string} key 
     * @param {AppGHOptions} options 
     */
    constructor(key, options) {

        /**
         * This is a unique identifier of the APP.
         * It might be used to display a lists. 
         * 
         * @type {string}
         */
        this.key = key;

        /**
         * This is a set of views associated with APP
         * 
         * @type {ViewGH[]}
         */
        this.views = [];

        /**
         * This is the main starting point of the application
         * 
         * @type {ViewGH[]}
         */
        this.main = Array.isArray(options.main) ? options.main : [options.main]


        /**
         * This is an options that might be used for the rendering. 
         * Such as icons, name description.
         * Also, may have customer elements
         * 
         * @type {AppGHDisplayOptions}
         */
        this.display = options.display || {};
    }



    /**
     * Returns an object of custom components for easy injection and lazy loading 
     * 
     * 
     * @returns {Object<string, ViewGH>}
     */
    get components() {
        return this.views.reduce((agg, view) => {
            agg[view.key] = view.component
            return agg;
        }, {})
    }


    /**
     * will attach views to this App
     * 
     * @param {ViewGH[]}views
     * @returns {AppGH}
     */
    use(views) {
        this.views = views

        return this;

    }
}
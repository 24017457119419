import {
    KnowledgeBaseAPIInstance
} from "../../../../../components/api";



// Create a new store instance.
export const KnowledgeBaseStore = {
    namespaced: true,
    state() {
        return {

        }
    },
    getters: {},
    mutations: {

    },
    actions: {
        async CreateLink(opt, {
            link
        }) {

            return await KnowledgeBaseAPIInstance
                .Links
                .create(link);

        },

        async DeleteLink(props, {
            link
        }) {
            await KnowledgeBaseAPIInstance.Links.delete(link, {
                toast: {
                    message: "Link Config Updated",
                },
            });

        },

    }
}
/**
 * @typedef {Object} GraphUIErrorProps
 * @property {string} code - Graph UI unique Error Code 
 * @property {string} message - Graph UI unique Error message 
 * @property {string} description -  Extended Description of Graph UI Error  
 * 
 */
export class GraphUIError extends Error {


    /**
     * This is a wrapper over Error class that provides more details on occurred Error 
     * 
     * 
     * @param {GraphUIErrorProps} props 
     */
    constructor(props) {
        super(props.message)

        this.description = props.description;
        this.code = props.code;
    }


    /**
     * Should return a generated link to Graph UI documentation
     * 
     * 
     * @returns {string}
     */
    get link() {
        return '';
    }
}
//|##########################################################|
//|                      GraphUI V2.0.0                      |
//|----------------------------------------------------------|
//|                © Copyright 2022 adaas.org                |
//|                All rights reserved ADAAS                 |
//|----------------------------------------------------------|
//| Created At: 11.16.2022                                   |
//| Created By: Andrew Tsariuk (andrew.t@adaas.org)          |
//|__________________________________________________________|
//|###########################################################

/* eslint-disable no-unused-vars */
import {
    AppGH
} from "../entities/App.gh.js";
import {
    RelationGH
} from "../entities/Relation.gh.js";
import {
    ViewGH
} from "../entities/View.gh.js";
import {
    GraphUI
} from "../GraphUI.class.js";
import {
    GraphUIActions
} from "../GraphUIActions.gh.js";
import {
    BreadcrumbGH
} from "./breadcrumbs/Breadcrumb.js";
import {
    BreadcrumbsGH
} from "./breadcrumbs/Breadcrumbs.js";
import {
    RendererGH
} from "./Renderer.gh.js";
import {
    RenderingLevelGH
} from "./RenderingLevel.gh.js";
import {
    RenderingViewGH
} from "./RenderingView.gh.js";
import {
    SceneManagerGH
} from "./SceneManager.gh.js";

/* eslint-enable no-unused-vars */

//TODO move to Scene Manager
import _ from 'lodash';
import {
    RendererHelper
} from "../helpers/renderer.helper.js";
import {
    CommonHelper
} from "../helpers/common.helper.js";


export class SceneGH {



    /**
     * The scene is a storage for entities took part in operations
     * It consist of RenderingLevels and RenderingViews
     * This class is responsible for the content visualization and storage
     * @param {GraphUI} graphUI
     */
    constructor(graphUI) {

        /**
         * All existing Levels included undisplayed
         * 
         * @type {RenderingLevelGH[]}
         */
        this.levels = []

        /**
         * The history of user actions 
         * 
         * @type {BreadcrumbsGH}
         */
        this.breadcrumbs = new BreadcrumbsGH();

        /**
         * The Renderer for this scene 
         * 
         * @type {RendererGH}
         */
        this.renderer = new RendererGH(graphUI)


        /**
         * Scene Manager that helps to operate on Scene components
         * 
         * @type {SceneManagerGH}
         */
        this.manager = new SceneManagerGH();

        /**
         * The set of actions across the graphUI
         * 
         * @private
         * @type {GraphUI} 
         */
        this.__graphUI = graphUI;

        /**
         * unique identifier to log data properly
         * 
         * @private
         * @type {string}
         */
        this.__logAlias = 'Scene'

        /**
         * uses to store purge timeout
         * 
         * @private
         * @type {NodeJS.Timeout|undefined}
         */
        this.__autoPurgeTimeout = undefined;

        /**
         * Rendering flag. No operation should perform before flag is true;
         * 
         * @type {Boolean}
         */
        this.__rendering = false


    }



    /**
     * Should initialize a new set of Levels
     * By default levels are empty and then the function should create new set of them
     * 
     */
    async init() {
        this.__graphUI.logger.log(this.__logAlias, 'START - INIT', this.__graphUI.config.cols)

        for (let i = 0; i < this.__graphUI.config.cols; i++) {
            this.__graphUI.logger.log(this.__logAlias, '- Init Level: ', i)
            const level = this.manager.initLevel(this.levels, i)

            if (i === 0)
                level.activate()
            else
                level.deActivate();
        }
    }


    /**
     * Should move focus to the new element and load additional relations to fill the page grid
     * 
     * 
     * @param {RenderingViewGH | BreadcrumbGH} rView
     */
    async activate(rView) {
        if (this.__rendering)
            return
        this.__rendering = true


        if (rView instanceof BreadcrumbGH)
            rView = rView.view

        this.__graphUI.logger.log(this.__logAlias, 'Activate: ', rView);

        // init new breadcrumb with col,row, top, left and height 
        const newBreadcrumb = this.breadcrumbs.activate(rView, this.levels);


        // set auto purge to clear unused elements
        this.__setAutoPurge();

        // Expand Height of the selected element

        this.levels[newBreadcrumb.col].activate();


        this.renderer.activate(this.levels[newBreadcrumb.col].views[newBreadcrumb.row], this.levels[newBreadcrumb.col])

        //Emit onMOve action to move focus to new Top and Left

        setTimeout(() => {
            this.__graphUI.actions.emit('onMove', {
                breadcrumb: newBreadcrumb
            });
        }, 260);

        await CommonHelper.delay(220)


        for (let i = 1; i < this.__graphUI.config.cols; i++) {
            //Previous level
            const prevLevel = this.manager.initLevel(
                this.levels,
                newBreadcrumb.col + i - 1
            );
            //Current level
            const level = this.manager.initLevel(
                this.levels,
                newBreadcrumb.col + i
            );

            // level.deActivate();

            //Get new Views
            const newViews = await this.__graphUI.store.get(
                newBreadcrumb,
                prevLevel, {
                    exclude: prevLevel.views.map(v => v.relation)
                }
            );




            // add ability to add external views
            if (prevLevel.views.length) {
                const placeHolder = new RenderingViewGH(this.__graphUI.config.addView);
                newViews.push(placeHolder);
            }
            // add ability to add external views


            this.__graphUI.logger.log(this.__logAlias, 'FINISHED - Views RECEIVED: ', newViews);


            const newUpdatedViews = this.renderer.calcHeight(newBreadcrumb, prevLevel, newViews)

            this.__graphUI.logger.log(this.__logAlias, 'FINISHED - Views with Height: ', newUpdatedViews);

            //Will draw a new array
            await this.renderer.draw(newBreadcrumb, level, newUpdatedViews);

            this.__graphUI.logger.log(this.__logAlias, 'Draw finished for level: ', i)

        }
        this.__graphUI.logger.log(this.__logAlias, 'FINISHED - activate', this.levels)

        this.__graphUI.actions.emit('onActivationFinished', {
            breadcrumb: newBreadcrumb
        });


        for (let i = this.breadcrumbs.active.col + this.__graphUI.config.cols - 1; i >= this.breadcrumbs.active.col; i--) {

            await CommonHelper.delay(160)

            if (i === this.breadcrumbs.active.col)
                this.levels[i].activate()
            else
                this.levels[i].deActivate();


            this.levels[i].views.forEach((v, j) =>
                this.levels[i].views[j].width = this.levels[i].width)
        }


        this.__rendering = false
    }


    /**
     * Should re-render the scene based on App main component
     * 
     * @param {AppGH} app 
     * @param {ViewGH | undefined} view 
     * @returns {SceneGH}
     */
    async openApp(app, view) {
        if (this.__rendering)
            return this;
        this.__rendering = true

        this.__graphUI.logger.log(this.__logAlias, 'START - APP OPENING: ', app)

        this.breadcrumbs.reset();
        this.levels = [];

        this.init();
        this.__graphUI.logger.log(this.__logAlias, 'FINISHED - RESET: ', this.levels)

        const defaultRView = new RenderingViewGH(view ? view : app.main[0]);
        defaultRView.width = this.levels[0].width

        await this.renderer.manager.insert({
            newItem: defaultRView,
            newIndex: 0
        }, this.levels[0]);


        // setTimeout(() => {
        this.__rendering = false
        this.activate(defaultRView);
        // }, 0);

        return this
    }


    /**
     * Should add a new relation to DB and re-render scene
     * 
     * 
     * @param {RenderingViewGH[] | RenderingViewGH } from 
     * @param {ViewGH} view 
     * @param {Object} params 
     */
    async add(from, view, params = {}) {

        this.__graphUI.logger.log(this.__logAlias, 'START - ADD');


        // check for empty view
        if (!Array.isArray(from) && from.view.key === this.__graphUI.config.addView.key) {
            // in this case we need to find the closest set of items to current one 

            // this is col index of empty view from
            const {
                col
            } = RendererHelper.findViewIndexById(this.levels, from);

            //TODO: FIND THE CLOSES ONE
            from = this.levels[col - 1].views
                .filter(view =>
                    view.view.key !== this.__graphUI.config.emptyView.key &&
                    view.view.key !== this.__graphUI.config.addView.key
                );

        }


        // covert to Array for easy parting
        from = Array.isArray(from) ? from : [from];

        /**
         * @type {RelationGH}
         */
        let relation;


        for (let rView of from) {
            const {
                col
            } = RendererHelper.findViewIndexById(this.levels, rView)

            //TODO add implementation to allow many views with the same params

            relation = await this.__graphUI.store.find(rView.view, view, params)

            const maxRView = _.maxBy(this.levels[col + 1].views, rV => rV.relation.weight);

            this.__graphUI.logger.log(this.__logAlias, '- maxWeight: ', maxRView)

            if (relation && !view.allowMany) {
                relation.weight = maxRView ? maxRView.relation.weight * this.__graphUI.config.weightConfig.add : 1;


                await this.__graphUI.store.update(relation)

            } else {
                relation = new RelationGH(rView.view, view, {
                    weight: maxRView ? maxRView.relation.weight * 1.1 : 1,
                    params

                });

                this.__graphUI.logger.log(this.__logAlias, '- Add to store: ', relation);


                await this.__graphUI.store.add(relation);
            }
        }

        await this.activate(this.breadcrumbs.active);

        await CommonHelper.delay(400);

        if (relation.to.addStrategy === 'activate') {
            const newPosition = RendererHelper.findLastViewIndex(this.levels, relation.toRView());

            const rView = this.levels[newPosition.col].views[newPosition.row];

            this.__graphUI.logger.log(this.__logAlias, 'FINISHED - ADD')

            this.activate(rView);
        }

    }

    /**
     * Removes relation and re-renders the whole scene 
     * @param {RenderingViewGH} aView 
     */
    async remove(aView) {

        await this.__graphUI.store.remove(aView.relation);

        await this.activate(this.breadcrumbs.active);
    }


    /**
     * Should expand a selected Rendering View
     * @param {RenderingViewGH} rView 
     */
    async expand(rView) {
        const {
            col,
            row
        } = RendererHelper.findViewIndexById(this.levels, rView);

        const maxHeight = RendererHelper.getActualHeight();
        const minHeight = 50;


        for (let i = 0; i < this.levels[col].views.length; i++) {

            if (i === row) {
                this.levels[col].views[i].isExpanded = true;
                this.levels[col].views[i].height = maxHeight - (this.levels[col].views.length - 1) * minHeight;
            } else {
                this.levels[col].views[i].height = minHeight;
                this.levels[col].views[i].isExpanded = false;
            }

        }
    }

    /**
     * 
     */
    async collapse() {
        for (let i = 0; i < this.levels.length; i++) {
            for (let j = 0; j < this.levels[i].views.length; j++) {
                this.levels[i].views[j].isExpanded = false;
            }
        }

        await this.activate(this.breadcrumbs.active);

    }

    async maximize() {

    }

    async move() {

    }

    /**
     * TODO Improve performance
     * 
     */
    async clear() {
        // this.animationStyle = {
        //     transition: "none",
        // };

        // const targetHeight = RendererHelper.calcHeightUpToIndex(
        //     this.levels[this.focusedCol],
        //     this.focusedRow
        // );

        // console.log("targetHeight", targetHeight);

        // // this.levels.shift();
        // this.levels.splice(0, this.focusedCol);

        // for (let i = 0; i < this.levels.length; i++) {
        //     const targetIndex = this.findIndexByHeight(
        //         this.levels[i].items,
        //         targetHeight
        //     );

        //     this.levels[i].items.splice(0, targetIndex + 1);
        // }

        // this.left = 0;
        // this.top = 0;

        // this.$refs[`appsContainer`].style.transform = `translate(${
        //     this.left
        //   }px, ${-1 * this.top + 6}px)`;
    }



    __setAutoPurge() {
        //TODO add a proper cleaning 
        // if (this.props.purge.auto) {
        //     if (this.__autoPurgeTimeout) clearTimeout(this.__autoPurgeTimeout);
        //     this.__autoPurgeTimeout = setTimeout(() => {
        //         this.clear();
        //     }, this.props.purge.delay);
        // }
    }





}
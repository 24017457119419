import {
    KnowledgeBaseAPIInstance
} from "../../../../../components/api";
import {
    GUI
} from "../../../GrapUI.config.js";
import {
    CaseStudyCreation,
    CaseStudyDetails,
    CaseStudyEditor,
    CaseStudyFilesEditor,
    CaseStudyList,
    CaseStudySettingsEditor
} from "../app.gh.js";


// Create a new store instance.
export const CaseStudyStore = {
    namespaced: true,
    state() {
        return {
            activeCaseStudyId: undefined,
            displayedCaseStudies: [],
            caseStudy: {},
            caseStudies: [],
            pagination: {
                page: 1,
                pageSize: 10,
            },
            filter: {
                search: "",
                typeId: "",
            },
            // Timeouts
            updateTimeout: undefined,
            loadingMap: new Map()
        }
    },
    getters: {
        caseStudyById: (state) => (id) => {
            const index = state.displayedCaseStudies.findIndex(s => s.id === id)

            if (index !== -1)
                return state.displayedCaseStudies[index];
            else
                return {
                    id
                }

        }
    },
    mutations: {
        setCaseStudy(state, caseStudy) {
            let index = state.displayedCaseStudies.findIndex(s => s.id === caseStudy.id)
            let listIndex = state.caseStudies.findIndex(s => s.id === caseStudy.id)

            if (index !== -1)
                state.displayedCaseStudies.splice(index, 1, caseStudy);
            else
                index = state.displayedCaseStudies.push(caseStudy);


            if (listIndex !== -1) {
                state.caseStudies.splice(listIndex, 1, caseStudy)
            }

        },
        setActiveId(state, caseStudyId) {
            state.activeCaseStudyId = caseStudyId;
        },
        setCaseStudies(state, {
            items,
            reset
        } = {}) {
            if (reset)
                state.caseStudies = items;

            else
                items.forEach(caseStudy => {
                    const caseStudyIndex = state.caseStudies.findIndex(cs => cs.id === caseStudy.id);

                    if (caseStudyIndex !== -1)
                        state.caseStudies.splice(caseStudyIndex, 1, caseStudy)
                    else
                        state.caseStudies.push(caseStudy)
                });
        },
        setPagination(state, pagination) {
            state.pagination = pagination;
        },
        setFilter(state, filter) {
            state.filter = filter;
        }
    },
    actions: {
        openCaseStudyList(props, {
            from,
        }) {
            GUI.scene.add(from, CaseStudyList);
        },
        openCaseStudyCreation(props, {
            from,
        }) {
            GUI.scene.add(from, CaseStudyCreation);
        },
        openCaseStudyDetails(props, {
            from,
            caseStudy
        }) {
            GUI.scene.add(from, CaseStudyDetails, {
                caseStudyId: caseStudy.id,
            });
        },
        openCaseStudyGeneralEditor(props, {
            from,
            caseStudy
        }) {
            GUI.scene.add(from, CaseStudyEditor, {
                caseStudyId: caseStudy.id,
            });
        },
        openCaseStudySettingsEditor(props, {
            from,
            caseStudy
        }) {
            GUI.scene.add(from, CaseStudySettingsEditor, {
                caseStudyId: caseStudy.id,
            });
        },
        openCaseStudyFilesEditor(props, {
            from,
            caseStudy
        }) {

            GUI.scene.add(from, CaseStudyFilesEditor, {
                caseStudyId: caseStudy.id,
            });
        },

        SetActiveCaseStudy({
            commit
        }, {
            from,
            caseStudy
        }) {

            GUI.scene.add(from, CaseStudyDetails, {
                caseStudyId: caseStudy.id,
            });

            commit('setActiveId', caseStudy.id)
        },
        // CaseStudies API 
        async GetCaseStudiesList({
            commit,
            state
        }, {
            pagination,
            filter,
            reset,
            next
        } = {}) {
            if (reset) {
                pagination = pagination ? pagination : {}
                filter = filter ? filter : {}
            } else {
                pagination = pagination ? pagination : state.pagination
                filter = filter ? filter : state.filter
            }

            if (next)
                pagination.page++;


            const resp = await KnowledgeBaseAPIInstance
                .CaseStudies
                .list(
                    pagination,
                    filter);

            commit('setCaseStudies', {
                items: resp.items,
                reset
            });

            commit('setPagination', resp.pagination);
            commit('setFilter', filter);
        },
        async CreateNewCaseStudy({
            dispatch
        }, {
            from,
            newCaseStudy
        }) {

            const caseStudy = await KnowledgeBaseAPIInstance
                .CaseStudies
                .create(newCaseStudy, {
                    toast: {
                        message: "Case Study Created",
                    },
                });

            dispatch("GetCaseStudiesList", {
                reset: true
            });

            GUI.scene.add(from, CaseStudyDetails, {
                caseStudyId: caseStudy.id
            });
        },
        async GetCaseStudy({
            commit,
            state,
            getters
        }, {
            id,
            refresh
        }) {
            switch (true) {

                // when  loading in progress
                // -> just wait 
                case state.loadingMap.get(id) === 'LOADING': {
                    await new Promise((resolve) => {
                        let i = 0;

                        const handler = () => {
                            if (state.loadingMap.get(id) === 'COMPLETED') {
                                return resolve()
                            } else
                                setTimeout(handler, ++i + 100);
                        }

                        setTimeout(handler, 100);
                    });


                    break;
                }

                // when loaded and Cache Exists
                // -> return existed cache 
                case state.loadingMap.get(id) === 'COMPLETED' && !refresh: {
                    break;
                }

                // when  haven't been ever loaded
                //   OR
                // when  loaded but cache requires refresh
                default: {
                    state.loadingMap.set(id, 'LOADING');

                    const caseStudy = await KnowledgeBaseAPIInstance.CaseStudies
                        .get(id);

                    commit('setCaseStudy', caseStudy)

                    state.loadingMap.set(id, 'COMPLETED');

                    break;
                }
            }

            return getters.caseStudyById(id);

        },

        async UpdateCaseStudy({
            state,
            commit
        }, {
            caseStudy
        }) {
            if (state.updateTimeout)
                clearTimeout(state.updateTimeout)

            state.updateTimeout = setTimeout(async () => {
                commit('setCaseStudy', caseStudy);
                await KnowledgeBaseAPIInstance.CaseStudies.update(caseStudy, {
                    toast: {
                        message: "Case Study Updated",
                    },
                });
            }, 1000);

        },

        async DeleteCaseStudy({
            dispatch
        }, caseStudy) {
            await KnowledgeBaseAPIInstance.CaseStudies.delete(caseStudy, {
                toast: {
                    message: "Case Study Updated",
                },
            });

            dispatch('GetCaseStudy', {
                id: caseStudy.id,
                refresh: true
            });
        },

        async DeleteCaseStudyAttachment({
            dispatch
        }, {
            caseStudy,
            file,
            type
        }) {
            await KnowledgeBaseAPIInstance.CaseStudies.deleteAttachment(caseStudy, type, file, {
                toast: {
                    message: "Attachment Removed",
                },
            });

            dispatch('GetCaseStudy', {
                id: caseStudy.id,
                refresh: true
            });
        },

    }
}
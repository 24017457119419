export const TAGS_PERMISSIONS = {
    ALLOW_SEE_TAG_LIST: 'ALLOW_SEE_TAG_LIST',
    ALLOW_CREATE_TAG: 'ALLOW_CREATE_TAG',
    ALLOW_EDIT_TAG: 'ALLOW_EDIT_TAG'
}

export const CASE_STUDY_PERMISSIONS = {
    ALLOW_SEE_CASE_STUDY_LIST: 'ALLOW_SEE_CASE_STUDY_LIST',
    ALLOW_CREATE_CASE_STUDY: 'ALLOW_CREATE_CASE_STUDY',
    ALLOW_EDIT_CASE_STUDY: 'ALLOW_EDIT_CASE_STUDY',
    ALLOW_EDIT_CASE_STUDY_FILES: 'ALLOW_EDIT_CASE_STUDY_FILES',
    ALLOW_EDIT_CASE_STUDY_SETTINGS: 'ALLOW_EDIT_CASE_STUDY_SETTINGS',
    ALLOW_SEE_CASE_STUDY_PUBLIC_MATERIALS: 'ALLOW_SEE_CASE_STUDY_PUBLIC_MATERIALS',
    ALLOW_SEE_CASE_STUDY_PRIVATE_MATERIALS: 'ALLOW_SEE_CASE_STUDY_PRIVATE_MATERIALS',
    ALLOW_SEE_CASE_STUDY_CUSTOMER_MATERIALS: 'ALLOW_SEE_CASE_STUDY_CUSTOMER_MATERIALS',
}

export const USE_CASE_PERMISSIONS = {
    ALLOW_SEE_USE_CASE_LIST: 'ALLOW_SEE_USE_CASE_LIST',
    ALLOW_CREATE_USE_CASE: 'ALLOW_CREATE_USE_CASE',
    ALLOW_EDIT_USE_CASE: 'ALLOW_EDIT_USE_CASE'
}

export const EXPERIENCE_NOTE_PERMISSIONS = {
    ALLOW_SEE_EXPERIENCE_NOTE_LIST: 'ALLOW_SEE_EXPERIENCE_NOTE_LIST',
    ALLOW_CREATE_EXPERIENCE_NOTE: 'ALLOW_CREATE_EXPERIENCE_NOTE',
    ALLOW_EDIT_EXPERIENCE_NOTE: 'ALLOW_EDIT_EXPERIENCE_NOTE',
    ALLOW_EDIT_EXPERIENCE_NOTE_SETTINGS: 'ALLOW_EDIT_EXPERIENCE_NOTE_SETTINGS',

}

export const QUESTION_PERMISSIONS = {
    ALLOW_SEE_QUESTION_LIST: 'ALLOW_SEE_QUESTION_LIST',
    ALLOW_CREATE_QUESTION: 'ALLOW_CREATE_QUESTION',
    ALLOW_EDIT_QUESTION: 'ALLOW_EDIT_QUESTION',
    ALLOW_EDIT_QUESTION_SETTINGS: 'ALLOW_EDIT_QUESTION_SETTINGS',
}


export const GLOSSARY_PERMISSIONS = {
    ALLOW_SEE_GLOSSARY_LIST: 'ALLOW_SEE_GLOSSARY_LIST',
    ALLOW_CREATE_GLOSSARY: 'ALLOW_CREATE_GLOSSARY',
    ALLOW_EDIT_GLOSSARY: 'ALLOW_EDIT_GLOSSARY'
}


export const BUSINESS_DOMAIN_PERMISSIONS = {
    ALLOW_SEE_BUSINESS_DOMAIN_LIST: 'ALLOW_SEE_BUSINESS_DOMAIN_LIST',
    ALLOW_CREATE_BUSINESS_DOMAIN: 'ALLOW_CREATE_BUSINESS_DOMAIN',
    ALLOW_EDIT_BUSINESS_DOMAIN: 'ALLOW_EDIT_BUSINESS_DOMAIN',
    ALLOW_EDIT_BUSINESS_DOMAIN_SETTINGS: 'ALLOW_EDIT_BUSINESS_DOMAIN_SETTINGS',
}
//|##########################################################|
//|                      GraphUI V2.0.0                      |
//|----------------------------------------------------------|
//|                © Copyright 2022 adaas.org                |
//|                All rights reserved ADAAS                 |
//|----------------------------------------------------------|
//| Created At: 11.16.2022                                   |
//| Created By: Andrew Tsariuk (andrew.t@adaas.org)          |
//|__________________________________________________________|
//|###########################################################

import _ from 'lodash';

/* eslint-disable no-unused-vars */
import {
    RenderingViewGH
} from '../render/RenderingView.gh.js';
import {
    RenderingLevelGH
} from '../render/RenderingLevel.gh.js';
/* eslint-enable no-unused-vars */


export class RendererHelper {



    /**
     *  x + y + z = 85
     *  0.1 + 0.8 + 0.6 = 1.5
     *  10 + 80 + 60 = 150 / 150
     *  10/150 + 80/150 + 60/150 = 1 * 85
     *  10* 85 / 150 + 80*85/150 + 60 *85/150 = 85
     *  6 + 46 + 33
     * 
     * 
     * @param {RenderingViewGH[]} prev 
     * @param {RenderingViewGH[]} curr 
     * @returns 
     */
    static calcHeightForArray(prev, curr) {

        //TODO move to debug mode

        // console.log('calcHeightForArray: prev: ', prev.map(el => el.view.key + '_' + JSON.stringify(el.relation.params) + '_' + el.height))
        // console.log('calcHeightForArray: curr: ', curr.map(el => el.view.key + '_' + JSON.stringify(el.relation.params) + '_' + el.height))


        if (!(curr && curr.length)) return [];


        const absoluteMax = this.getActualHeight();

        const maxHeights = prev && prev.length ?
            _.map(prev, "height") : [absoluteMax];


        this.directCalc(curr);

        let prevIndex = 0;
        let maxTotalHeight = 0;


        for (const maxHeight of maxHeights) {
            let heightAcc = 0;

            const maxProhibition = curr
                .slice(prevIndex, curr.length)
                .reduce(
                    (p, c, i, arr) =>
                    i === arr.length - 1 ? (c.height + p) / arr.length : c.height + p,
                    0
                );

            let targetIndex = 0;

            for (let i = prevIndex; i < curr.length; i++) {
                heightAcc += curr[i].height;
                targetIndex = i;

                if (
                    heightAcc <= maxHeight + maxProhibition &&
                    heightAcc > maxHeight - maxProhibition &&
                    maxTotalHeight + maxHeight < absoluteMax
                ) {
                    break;
                }
            }

            maxTotalHeight += maxHeight;

            const currSlice = curr.slice(prevIndex, targetIndex + 1);

            const totalWeight = currSlice
                .filter(el => !el.view.display.constHeight)
                .reduce(
                    (prev, curr) => prev + curr.relation.weight,
                    0
                );
            let totalUsed = 0;

            const totalConstHeight = currSlice.reduce((prev, curr) => prev + curr.view.display.constHeight ? curr.view.display.constHeight : 0, 0);

            //TODO move to debug mode
            // console.log('START SLICE CALC')
            // console.log('totalConstHeight',totalConstHeight)
            // console.log('maxHeight',maxHeight)
            // console.log('calcHeightForArray: curr: ', currSlice.map(el => el.view.key + '_' + JSON.stringify(el.relation.params) + '_' + el.height))


            currSlice.forEach((el, i) => {
                if (el.view.display.constHeight) {


                    el.height = el.view.display.constHeight
                } else
                if (i + 1 === currSlice.length) {
                    //TODO move to debug mode

                    // console.log('maxHeight: ',maxHeight)
                    // console.log('totalConstHeight: ',totalConstHeight)
                    // console.log('totalUsed: ',totalUsed)

                    el.height = (maxHeight - totalConstHeight) - totalUsed;
                } else {
                    const height = Math.floor(
                        (el.relation.weight * (maxHeight - totalConstHeight)) / totalWeight
                    );

                    totalUsed += height;

                    el.height = height;
                }
                //TODO move to debug mode

                // console.log('===========================2')
                // console.log('view ',el.view.key)
                // console.log('view ', el.height)
                // console.log('===========================2')

            });

            prevIndex = targetIndex + 1;
        }

        return curr;
    }


    /**
     * 
     * @param {RenderingViewGH[]} arr 
     * @returns {RenderingViewGH[]}
     */
    static directCalc(arr) {
        const totalConstHeight = arr.reduce((prev, curr) => prev + curr.view.display.constHeight ? curr.view.display.constHeight : 0, 0);

        const maxHeight = this.getActualHeight() - totalConstHeight;

        let totalWeight = arr.reduce((prev, curr) => prev + curr.relation.weight, 0);
        let totalUsed = 0;

        arr.forEach((el, i) => {
            if (el.view.display.constHeight) {

                el.height = el.view.display.constHeight
            } else

            if (i + 1 === arr.length) {

                const height = maxHeight - totalUsed;

                arr[i].height = height;
            } else {
                const height = Math.floor(
                    (el.relation.weight * maxHeight) / totalWeight
                );

                totalUsed += height;

                arr[i].height = height;
            }
        });


        return arr;
    }


    /**
     * Will return closest Index in array by height.
     * Returns an index of rView that previous to display segment
     *  ________________________________________________
     * |  When Height NOT Equal                         |
     * |________________________________________________|
     * |    height 50                                   |
     * |    10   0 + 10>=50  i=0                        |
     * |    20  10 + 20>=50  i=1                        |
     * |    30  30 + 30>=50  i=2 => break => i=2        |
     * |    40                                          |
     * |________________________________________________|
     * 
     * 
     *  This is case when height=0 AND array length=1 
     *  ________________________________________________
     * |  When Height Equal 0. Should be i=0            |
     * |________________________________________________|
     * |    height 0                                    |
     * |------------------------------------------------|
     * |    10   0=0  => break => i=0                   |
     * |________________________________________________|
     * 
     *  
     *  This options will be used as default for version 2.0.0
     *  ________________________________________________
     * |  When Height Equal. Should be i=3              |
     * |________________________________________________|
     * |    height 50                                   |
     * |    10        0!=50  i=0                        |
     * |    10   0 + 10!=50  i=1                        |
     * |    30  10 + 10!=50  i=2                        |
     * |------------------------------------------------|
     * |    40  20 + 30 =50  i=3 => break => 3          |
     * |________________________________________________|
     * 
     * @param {RenderingViewGH[]} rViews -initial array of views that displays NOW 
     * @param {number} height - the height to the selected element
     * @returns 
     */
    static findIndexByHeight(rViews, height = 0) {
        //to store total offset height
        let acc = 0;
        let found = false
        let index = 0;


        for (let i = 0; i < rViews.length; i++) {
            index = i;
            acc += rViews[i].height;

            //if acc > height it means that current element in Display Segment
            if (acc > height) {
                found = true;
                break;
            }

        }

        return !found ? rViews.length : index;
    }


    static getArraySliceByHeight(array, fromHeight, toHeight) {
        if (!toHeight) {
            toHeight = fromHeight + this.getActualHeight();
        }

        const [from, to] = this.getSegmentIndexesByHeight(
            array,
            fromHeight,
            toHeight
        );

        return array.slice(from, to);
    }

    static getSegmentIndexesByHeight(array, fromHeight, toHeight) {
        const fromIndex = this.findIndexByHeight(array, fromHeight);
        const toIndex = this.findIndexByHeight(array, toHeight);
        return [fromIndex, toIndex];
    }



    static getActualHeight() {
        return document.getElementById('desktop').clientHeight

        // return document.documentElement.clientHeight;
    }


    static calcHeightUpToIndex(level, index) {
        return level.views.reduce(
            (prev, curr, i) => (i < index ? prev + curr.height : prev),
            0
        );
    }


    /**
     * Should return a coordinates of Rendering View in the Levels
     * It will search by rView.id
     * 
     * @param {RenderingLevelGH[]} levels 
     * @param {RenderingViewGH}rView 
     * @returns 
     */
    static findViewIndexById(levels, rView) {
        let col = -1;
        let row = -1;
        let found = false;

        for (let i = 0; i < levels.length; i++) {

            for (let j = 0; j < levels[i].views.length; j++) {


                if (levels[i].views[j].id === rView.id) {
                    col = i;
                    row = j

                    found = true;
                    break;
                }

            }
            if (found)
                break;
        }

        return {
            col,
            row
        };
    }


    /**
     * Should return a coordinates of Rendering View in the Levels
     * It will search by rView.isEqual
     * 
     * @param {RenderingLevelGH[]} levels 
     * @param {RenderingViewGH}rView 
     * @returns 
     */
    static findViewIndex(levels, rView) {
        let col = -1;
        let row = -1;
        let found = false;

        for (let i = 0; i < levels.length; i++) {

            for (let j = 0; j < levels[i].views.length; j++) {


                //TODO add custom identifier
                if (levels[i].views[j].isEqual(rView)) {
                    col = i;
                    row = j

                    found = true;
                    break;
                }

            }
            if (found)
                break;
        }

        return {
            col,
            row
        };
    }


    /**
     * Should return a coordinates of Rendering View in the Levels
     * It will search by rView.isEqual
     * 
     * @param {RenderingLevelGH[]} levels 
     * @param {RenderingViewGH}rView 
     * @returns 
     */
    static findLastViewIndex(levels, rView) {
        let col = -1;
        let row = -1;
        let found = false;

        for (let i = levels.length - 1; i >= 0; i--) {

            for (let j = levels[i].views.length - 1; j >= 0; j--) {


                //TODO add custom identifier
                if (levels[i].views[j].isEqual(rView)) {
                    col = i;
                    row = j

                    found = true;
                    break;
                }

            }
            if (found)
                break;
        }

        return {
            col,
            row
        };
    }

}
import {
    API
} from '../Api.class';
import {
    PresentMasterAPI
} from './PresentMaster.api';
import {
    ProposalAPI
} from './Proposals.api';
import {
    SlideAPI
} from './Slides.api';


export class DocAssistantAPI extends API {

    constructor(baseUrl) {
        super(baseUrl)

        this.Proposals = new ProposalAPI(baseUrl);
        this.Slides = new SlideAPI(baseUrl);
        this.PresentMaster = new PresentMasterAPI(baseUrl);
    }



    async list(pagination, filter, meta) {
        this.loading = true

        return await this.__axiosInstance.get('api/v1/doc-assistant/history', {
            meta,
            params: {
                ...pagination,
                ...filter
            }
        })

    }


    async get(id, meta) {
        this.loading = true

        return await this.__axiosInstance.get(`api/v1/doc-assistant/history/${id}`, {
            meta
        })

    }
}
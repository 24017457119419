import {
    API
} from '../Api.class';


export class ServiceSectionsAPI extends API {

    constructor(baseUrl) {
        super(baseUrl)
    }


    async list(pagination, filter, serviceId, meta) {
        return await this.__axiosInstance.get(`api/v1/services/${serviceId}/sections`, {
            meta,
            params: {
                ...pagination,
                ...filter
            }
        })
    }

    async get(serviceId, id) {
        return await this.__axiosInstance.get(`api/v1/services/${serviceId}/sections/${id}`);
    }


    async create(serviceId, section = {}, meta) {
        return await this.__axiosInstance.post(`api/v1/services/${serviceId}/sections`, section, {
            meta
        })
    }

    async update(serviceId, section = {}, meta) {
        return await this.__axiosInstance.put(`api/v1/services/${serviceId}/sections/${section.id}`, section, {
            meta
        })
    }

    async delete(serviceId, section, meta) {
        return await this.__axiosInstance.delete(`api/v1/services/${serviceId}/sections/${section.id}`, {
            meta
        })
    }


    // ====== FREE SECTION STEPS============
    async createFreeSectionStep(serviceId, freeSection = {}, meta) {
        return await this.__axiosInstance.post(`api/v1/services/${serviceId}/free-sections/${freeSection.id}/steps`, freeSection, {
            meta
        })
    }

    async FreeSectionStep(serviceId, freeSection = {}, step, meta) {
        return await this.__axiosInstance.put(`api/v1/services/${serviceId}/free-sections/${freeSection.id}/steps/${step.id}`, freeSection, {
            meta
        })
    }

    async deleteFreeSectionStep(serviceId, freeSection, step, meta) {
        return await this.__axiosInstance.delete(`api/v1/services/${serviceId}/free-sections/${freeSection.id}/steps/${step.id}`, {
            meta
        })
    }




}
<template>
  <v-app light>
    <iframe id="adaas_sso" :src="SSOUrl" frameborder="0"></iframe>

    <router-view></router-view>

    <SplashScreen v-if="isSplash"></SplashScreen>

    <!-- <notifications /> -->
  </v-app>
</template>

<script>
import { AuthHelper } from "./components/helpers/auth.helper";
import { mapState } from "vuex";
import SplashScreen from "./views/src/desktop/components/SplashScreen.vue";

export default {
  data() {
    return {
      clipped: true,
      drawer: true,
      fixed: true,
      items: [
        {
          icon: "bubble_chart",
          title: "Inspire",
        },
      ],
      miniVariant: false,
      right: true,
      rightDrawer: false,
      title: "Admin Panel",
    };
  },
  components: {
    SplashScreen,
  },
  computed: {
    SSOUrl() {
      return `${process.env.VUE_APP_SSO_API}/api/v1/auth/shared`;
    },
    ...mapState("MainStore", ["isSplash"]),
  },
  async mounted() {
    await AuthHelper.init();
  },
};
</script>



<style lang="scss" scoped>
#adaas_sso {
  display: none;
}
.sign-in {
  height: 100%;
  max-height: 100%;
  .sign-in-wrapper {
    height: 100%;
    width: 100%;
  }

  .sign-in-image-col {
    // -webkit-animation: spinX 3s infinite;
    overflow: hidden;
    position: relative;
    .sign-in-img {
      animation: spinX 60s infinite linear;

      position: absolute;

      left: -100vh;
      bottom: -100vh;
      // top: -50vh;
      // left: -50vh;
      width: 300vh;
      height: 300vh;
      background-position: 50% 50%;
      background-image: url("/static/images/backgrounds/45-progressive.webp");
      .sign-in-cover {
        height: 100%;
        max-height: 100%;
        width: 100%;
      }
    }

    @-webkit-keyframes spinX {
      0% {
        -webkit-transform: rotate(0deg);
      }
      50% {
        -webkit-transform: rotate(180deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
      }
    }
    @keyframes spinX {
      0% {
        transform: rotate(0deg);
      }
      50% {
        transform: rotate(180deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}
</style>

<template>
  <v-card
    max-width="375"
    class="mx-auto transparent elevation-0 profile-fast-access"
  >
    <v-img
      height="100%"
      src="https://cdn.vuetifyjs.com/images/cards/server-room.jpg"
    >
      <v-row align="end" class="fill-height">
        <v-col align-self="start" class="pa-0" cols="12">
          <v-hover v-slot="{ hover }">
            <v-avatar class="profile ma-2" color="grey" size="164">
              <v-img
                :style="{
                  backgroundColor:
                    user.profile.avatar && user.profile.avatar.url
                      ? 'white'
                      : user.profile.color,
                }"
                :src="
                  user.profile.avatar && user.profile.avatar.url
                    ? user.profile.avatar.url
                    : user.profile.avatarURL
                "
                :lazy-src="
                  user.profile.avatar && user.profile.avatar.url
                    ? user.profile.avatar.url
                    : user.profile.avatarURL
                "
              >
                <v-fade-transition>
                  <div
                    v-if="hover"
                    class="change-avatar-overlay"
                    style="height: 100%; width: 100%"
                  >
                    <v-icon @click="openFile" color="primary">mdi-plus</v-icon>
                  </div>
                </v-fade-transition>
              </v-img>
            </v-avatar>
          </v-hover>
        </v-col>
        <v-col class="py-0">
          <v-list-item color="rgba(0, 0, 0, .4)" dark>
            <v-list-item-content>
              <v-list-item-title class="text-h6">
                {{ user.profile.name }}
              </v-list-item-title>
              <v-list-item-subtitle>{{
                user.employee ? user.employee.title : ""
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-btn text @click="signOut">
          <span class="pa-2">Sign Out</span>
          <v-icon right class="pa-2" color="primary">mdi-logout</v-icon>
        </v-btn>
      </v-row>
    </v-img>

    <v-tabs
      color="white"
      class="transparent"
      right
      background-color="transparent"
      v-model="tab"
    >
      <v-tab :key="0">My Information</v-tab>
      <v-tab :key="1">System</v-tab>
      <v-tab :key="2">Settings</v-tab>
    </v-tabs>

    <v-tabs-items
      class="transparent"
      background-color="transparent"
      v-model="tab"
      dark
    >
      <v-tab-item :key="0" background-color="transparent">
        <v-container fluid>
          <v-list two-line>
            <v-list-item>
              <v-list-item-icon>
                <v-icon color="primary"> mdi-phone </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>(650) 555-1234</v-list-item-title>
                <v-list-item-subtitle>Mobile</v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-icon>
                <v-icon>mdi-message-text</v-icon>
              </v-list-item-icon>
            </v-list-item>

            <v-list-item>
              <v-list-item-action></v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>(323) 555-6789</v-list-item-title>
                <v-list-item-subtitle>Work</v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-icon>
                <v-icon>mdi-message-text</v-icon>
              </v-list-item-icon>
            </v-list-item>

            <v-divider inset></v-divider>

            <v-list-item>
              <v-list-item-icon>
                <v-icon color="primary"> mdi-email </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>aliconnors@example.com</v-list-item-title>
                <v-list-item-subtitle>Personal</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-action></v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>{{ user.email }}</v-list-item-title>
                <v-list-item-subtitle>Work</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-divider inset></v-divider>

            <v-list-item v-if="user.settings.timezone">
              <v-list-item-icon>
                <v-icon color="primary"> mdi-map-clock </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{
                  user.settings.timezone.name
                }}</v-list-item-title>
                <v-list-item-subtitle>{{
                  user.settings.timezone.code
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item v-if="user.settings.country">
              <v-list-item-icon>
                <v-icon size="24" :style="{ width: '24px' }" color="primary">
                  {{
                    `fi fi-${String(user.settings.country.iso2).toLowerCase()}`
                  }}
                </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{
                  user.settings.country.name
                }}</v-list-item-title>
                <v-list-item-subtitle
                  >ISO: {{ user.settings.country.iso2 }}/{{
                    user.settings.country.iso3
                  }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-container>
      </v-tab-item>

      <v-tab-item :key="1">
        <v-container fluid>
          <PersonalizationVue
            @onBackgroundSelect="onBackgroundSelect"
          ></PersonalizationVue>
        </v-container>
      </v-tab-item>
      <v-tab-item :key="2">
        <v-container fluid>
          <UserSettings @onSettingsChanged="onSettingsChanged"></UserSettings>
        </v-container>
      </v-tab-item>
    </v-tabs-items>

    <!-- <v-file-input
    accept="image/*"
    label="File input"
    ref="fileInput"
  ></v-file-input> -->

    <input
      type="file"
      ref="fileInput"
      accept="image/*"
      style="display: none"
      @change="uploadFile"
    />
    <!-- <VueFileAgent ref="fileInput"></VueFileAgent> -->

    <!-- <iframe src="https://dateful.com/time-zone-converter" frameborder="0" width="100%" height="300px" scrolling="no"></iframe> -->
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import PersonalizationVue from "./Personalization.vue";
import UserSettings from "./UserSettings.vue";

export default {
  name: "UserProfile",
  props: {
    value: {
      default: () => ({}),
    },
    badge: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState("MainStore", ["user"]),
  },
  components: {
    PersonalizationVue,
    UserSettings,
  },
  data() {
    return {
      tooltip: false,
      tab: 0,
    };
  },
  created() {
    setTimeout(() => {
      console.log("this.user", this.user);
    }, 3000);
  },

  methods: {
    onBackgroundSelect(src) {
      if (!this.user.settings)
        this.user.settings = {
          system_background: src,
        };
      else this.user.settings.system_background = src;

      this.$store.dispatch("MainStore/updateUser", this.user);
    },
    onSettingsChanged() {
      this.$store.dispatch("MainStore/updateUser", this.user);
    },
    openFile() {
      this.$refs.fileInput.click();
    },
    async uploadFile() {
      const formData = new FormData();
      formData.append("file", this.$refs.fileInput.files[0]);

      this.$store.dispatch("MainStore/uploadUserAvatar", { formData });
    },
    async signOut() {
      await this.$store.dispatch("MainStore/logOut");

      this.$router.push("/sign-in");
    },
  },
};
</script>


<style lang="scss" scoped>
.profile-fast-access {
  .change-avatar-overlay {
    border-radius: 50%;

    backdrop-filter: blur(5px);
  }
}
</style>

import {
    AppGH
} from '../../../../../lib/graph-ui/corev2/entities/App.gh.js';
import {
    ViewGH
} from '../../../../../lib/graph-ui/corev2/entities/View.gh.js';
import {
    DELIVERABLE_PERMISSIONS,
    SERVICE_PERMISSIONS
} from '../service-management/constants/permissions.gh.js';
import {
    CASE_STUDY_PERMISSIONS,
    EXPERIENCE_NOTE_PERMISSIONS,
    QUESTION_PERMISSIONS,
    TAGS_PERMISSIONS,
    GLOSSARY_PERMISSIONS,
    BUSINESS_DOMAIN_PERMISSIONS
} from './constants/permissions.gh.js';



export const TagCreation = new ViewGH('KNOWLEDGE_BASE_TAG_CREATION', {
    display: {
        icon: 'mdi-tag-plus',
        name: 'New KnowledgeBase Tag',
        description: 'A new form to create a tag'

    },
    // addStrategy: 'activate',
    component: () => import('./views/tags/TagCreation.vue'),
});

//  Case Studies 
export const CaseStudyList = new ViewGH('CASE_STUDY_LIST', {
    display: {
        icon: 'mdi-store-outline',
        name: 'Case Studies',
        description: 'This is a list of all existed case studies'
    },
    component: () => import('./views/caseStudies/CaseStudiesList.vue'),
    permissions: [
        CASE_STUDY_PERMISSIONS.ALLOW_SEE_CASE_STUDY_LIST,
        CASE_STUDY_PERMISSIONS.ALLOW_CREATE_CASE_STUDY
    ]
});
export const CaseStudyDetails = new ViewGH('CASE_STUDY_DETAILS', {
    display: {
        icon: 'mdi-store',
        name: 'Case Study Details',
        description: 'Case Study Detailed Information'
    },
    // addStrategy: 'activate',
    component: () => import('./views/caseStudies/CaseStudiesDetails.vue'),
    permissions: [
        CASE_STUDY_PERMISSIONS.ALLOW_SEE_CASE_STUDY_LIST,
        CASE_STUDY_PERMISSIONS.ALLOW_CREATE_CASE_STUDY,
        CASE_STUDY_PERMISSIONS.ALLOW_EDIT_CASE_STUDY,
        CASE_STUDY_PERMISSIONS.ALLOW_EDIT_CASE_STUDY_SETTINGS,
        CASE_STUDY_PERMISSIONS.ALLOW_EDIT_CASE_STUDY_FILES,
        CASE_STUDY_PERMISSIONS.ALLOW_SEE_CASE_STUDY_PUBLIC_MATERIALS,
        CASE_STUDY_PERMISSIONS.ALLOW_SEE_CASE_STUDY_PRIVATE_MATERIALS,
        CASE_STUDY_PERMISSIONS.ALLOW_SEE_CASE_STUDY_CUSTOMER_MATERIALS,
        SERVICE_PERMISSIONS.ALLOW_SEE_SERVICE_LIST,
        DELIVERABLE_PERMISSIONS.ALLOW_SEE_DELIVERABLE_LIST
    ]
});
export const CaseStudyEditor = new ViewGH('CASE_STUDY_GENERAL_EDITOR', {
    display: {
        icon: 'mdi-store-edit-outline',
        name: 'Case Study Settings',
        description: 'Case Study Editor'
    },
    component: () => import('./views/caseStudies/CaseStudiesEditor.vue'),
    permissions: [
        CASE_STUDY_PERMISSIONS.ALLOW_SEE_CASE_STUDY_LIST,
        CASE_STUDY_PERMISSIONS.ALLOW_EDIT_CASE_STUDY,
        TAGS_PERMISSIONS.ALLOW_CREATE_TAG,
        SERVICE_PERMISSIONS.ALLOW_CREATE_SERVICE,
        SERVICE_PERMISSIONS.ALLOW_SEE_SERVICE_LIST,
        DELIVERABLE_PERMISSIONS.ALLOW_CREATE_DELIVERABLE,
        DELIVERABLE_PERMISSIONS.ALLOW_SEE_DELIVERABLE_LIST
    ]
});

export const CaseStudySettingsEditor = new ViewGH('CASE_STUDY_SETTINGS_EDITOR', {
    display: {
        icon: 'mdi-store-edit-outline',
        name: 'Case Study Settings',
        description: 'Case Study Settings'
    },
    component: () => import('./views/caseStudies/CaseStudySettingsEditor.vue'),
    permissions: [
        CASE_STUDY_PERMISSIONS.ALLOW_EDIT_CASE_STUDY_SETTINGS,
        CASE_STUDY_PERMISSIONS.ALLOW_SEE_CASE_STUDY_LIST,
        CASE_STUDY_PERMISSIONS.ALLOW_CREATE_CASE_STUDY,
        CASE_STUDY_PERMISSIONS.ALLOW_EDIT_CASE_STUDY
    ]
});

export const CaseStudyFilesEditor = new ViewGH('CASE_STUDY_FILES_EDITOR', {
    display: {
        icon: 'mdi-store-edit-outline',
        name: 'Case Study Files',
        description: 'Case Study Files'
    },
    component: () => import('./views/caseStudies/CaseStudyFilesEditor.vue'),
});

export const CaseStudyCreation = new ViewGH('CASE_STUDY_CREATION', {
    display: {
        icon: 'mdi-store-plus-outline',
        name: 'New Case Study',
        description: 'A new form to create a Case Study'

    },
    component: () => import('./views/caseStudies/CaseStudiesCreation.vue'),
    permissions: [
        CASE_STUDY_PERMISSIONS.ALLOW_CREATE_CASE_STUDY,
        TAGS_PERMISSIONS.ALLOW_CREATE_TAG,
        SERVICE_PERMISSIONS.ALLOW_CREATE_SERVICE,
        SERVICE_PERMISSIONS.ALLOW_SEE_SERVICE_LIST,
        DELIVERABLE_PERMISSIONS.ALLOW_CREATE_DELIVERABLE,
        DELIVERABLE_PERMISSIONS.ALLOW_SEE_DELIVERABLE_LIST
    ]
});




//  Questions 
export const QuestionList = new ViewGH('QUESTION_LIST', {
    display: {
        icon: 'mdi-help-box',
        name: 'Questions',
        description: 'This is a list of all existed questions'
    },
    component: () => import('./views/questions/QuestionsList.vue'),
    permissions: [
        QUESTION_PERMISSIONS.ALLOW_SEE_QUESTION_LIST,
        QUESTION_PERMISSIONS.ALLOW_CREATE_QUESTION
    ]
});
export const QuestionDetails = new ViewGH('QUESTION_DETAILS', {
    display: {
        icon: 'mdi-help-box',
        name: 'Question Details',
        description: 'Questions Detailed Information'
    },
    // addStrategy: 'activate',
    component: () => import('./views/questions/QuestionsDetails.vue'),
    permissions: [
        QUESTION_PERMISSIONS.ALLOW_EDIT_QUESTION,
        QUESTION_PERMISSIONS.ALLOW_EDIT_QUESTION_SETTINGS
    ]
});
export const QuestionEditor = new ViewGH('QUESTION_GENERAL_EDITOR', {
    display: {
        icon: 'mdi-help-box',
        name: 'Question Settings',
        description: 'Question Editor'
    },
    component: () => import('./views/questions/QuestionsEditor.vue'),
    permissions: [
        TAGS_PERMISSIONS.ALLOW_CREATE_TAG,
        QUESTION_PERMISSIONS.ALLOW_EDIT_QUESTION,
        QUESTION_PERMISSIONS.ALLOW_EDIT_QUESTION_SETTINGS
    ]
});

export const QuestionCreation = new ViewGH('QUESTION_CREATION', {
    display: {
        icon: 'mdi-help-box',
        name: 'New Question',
        description: 'A new form to create a Question'

    },
    component: () => import('./views/questions/QuestionsCreation.vue'),
    permissions: [
        TAGS_PERMISSIONS.ALLOW_CREATE_TAG,
        QUESTION_PERMISSIONS.ALLOW_CREATE_QUESTION
    ]
});


//  Experience Records 
export const ExperienceNoteList = new ViewGH('EXPERIENCE_NOTE_LIST', {
    display: {
        icon: 'mdi-lightbulb-outline',
        name: 'Experience Records',
        description: 'This is a list of all existed Experience Records'
    },
    component: () => import('./views/experienceNotes/ExperienceNotesList.vue'),
    permissions: [
        EXPERIENCE_NOTE_PERMISSIONS.ALLOW_CREATE_EXPERIENCE_NOTE,
        EXPERIENCE_NOTE_PERMISSIONS.ALLOW_SEE_EXPERIENCE_NOTE_LIST
    ]
});
export const ExperienceNoteDetails = new ViewGH('EXPERIENCE_NOTE_DETAILS', {
    display: {
        icon: 'mdi-lightbulb-outline',
        name: 'Experience Note Details',
        description: 'Experience Note Detailed Information'
    },
    // addStrategy: 'activate',
    component: () => import('./views/experienceNotes/ExperienceNotesDetails.vue'),
    permissions: [
        EXPERIENCE_NOTE_PERMISSIONS.ALLOW_EDIT_EXPERIENCE_NOTE,
        EXPERIENCE_NOTE_PERMISSIONS.ALLOW_EDIT_EXPERIENCE_NOTE_SETTINGS
    ]
});
export const ExperienceNoteEditor = new ViewGH('EXPERIENCE_NOTE_GENERAL_EDITOR', {
    display: {
        icon: 'mdi-lightbulb-outline',
        name: 'Experience Note Settings',
        description: 'Experience Note Editor'
    },
    component: () => import('./views/experienceNotes/ExperienceNotesEditor.vue'),
    permissions: [
        EXPERIENCE_NOTE_PERMISSIONS.ALLOW_EDIT_EXPERIENCE_NOTE,
        TAGS_PERMISSIONS.ALLOW_CREATE_TAG
    ]
});

export const ExperienceNoteCreation = new ViewGH('EXPERIENCE_NOTE_CREATION', {
    display: {
        icon: 'mdi-lightbulb-outline',
        name: 'New Experience Note',
        description: 'A new form to create a Experience Note',


    },
    component: () => import('./views/experienceNotes/ExperienceNotesCreation.vue'),
    permissions: [
        EXPERIENCE_NOTE_PERMISSIONS.ALLOW_CREATE_EXPERIENCE_NOTE,
        TAGS_PERMISSIONS.ALLOW_CREATE_TAG
    ]
});


// ==============GLOSSARY===========

export const Glossary = new ViewGH('GLOSSARY_VIEW', {
    display: {
        icon: 'mdi-order-alphabetical-ascending',
        name: 'Glossary',
        description: 'This is a list of all existed terms in the system'
    },
    component: () => import('./views/glossary/Glossary.vue'),
    permissions: [
        GLOSSARY_PERMISSIONS.ALLOW_CREATE_GLOSSARY,
        GLOSSARY_PERMISSIONS.ALLOW_SEE_GLOSSARY_LIST
    ]
});
export const TermDetails = new ViewGH('TERM_DETAILS', {
    display: {
        icon: 'mdi-alpha-a-box',
        name: 'Glossary Term Details',
        description: 'Glossary Term Detailed Information'
    },
    // addStrategy: 'activate',
    component: () => import('./views/glossary/GlossaryTermDetails.vue'),
    permissions: [
        GLOSSARY_PERMISSIONS.ALLOW_EDIT_GLOSSARY,
        GLOSSARY_PERMISSIONS.ALLOW_SEE_GLOSSARY_LIST
    ]
});
export const TermGeneralEditor = new ViewGH('TERM_GENERAL_EDITOR', {
    display: {
        icon: 'mdi-alpha-s-box',
        name: 'Term Settings',
        description: 'Glossary Term Editor'
    },
    component: () => import('./views/glossary/GlossaryTermGeneralEditor.vue'),
    permissions: [
        GLOSSARY_PERMISSIONS.ALLOW_SEE_GLOSSARY_LIST,
        GLOSSARY_PERMISSIONS.ALLOW_EDIT_GLOSSARY,
        GLOSSARY_PERMISSIONS.ALLOW_CREATE_GLOSSARY,
        TAGS_PERMISSIONS.ALLOW_CREATE_TAG
    ]
});

export const TermCreation = new ViewGH('TERM_CREATION', {
    display: {
        icon: 'mdi-alpha-a-box',
        name: 'New Glossary Term',
        description: 'A new form to create a glossary term'

    },
    component: () => import('./views/glossary/GlossaryTermCreation.vue'),
    permissions: [
        GLOSSARY_PERMISSIONS.ALLOW_CREATE_GLOSSARY,
        TAGS_PERMISSIONS.ALLOW_CREATE_TAG
    ]
});





//  Business Domains 
export const BusinessDomainList = new ViewGH('BUSINESS_DOMAIN_LIST', {
    display: {
        icon: 'mdi-domain',
        name: 'Business Domains',
        description: 'This is a list of all existed questions'
    },
    component: () => import('./views/businessDomains/BusinessDomainList.vue'),
    permissions: [
        BUSINESS_DOMAIN_PERMISSIONS.ALLOW_SEE_BUSINESS_DOMAIN_LIST,
        BUSINESS_DOMAIN_PERMISSIONS.ALLOW_CREATE_BUSINESS_DOMAIN
    ]
});
export const BusinessDomainDetails = new ViewGH('BUSINESS_DOMAIN_DETAILS', {
    display: {
        icon: 'mdi-domain',
        name: 'Business Domain Details',
        description: 'Business Domain Detailed Information'
    },
    // addStrategy: 'activate',
    component: () => import('./views/businessDomains/BusinessDomainDetails.vue'),
    permissions: [
        BUSINESS_DOMAIN_PERMISSIONS.ALLOW_EDIT_BUSINESS_DOMAIN,
        BUSINESS_DOMAIN_PERMISSIONS.ALLOW_EDIT_BUSINESS_DOMAIN_SETTINGS
    ]
});
export const BusinessDomainEditor = new ViewGH('BUSINESS_DOMAIN_GENERAL_EDITOR', {
    display: {
        icon: 'mdi-domain',
        name: 'BusinessDomain Settings',
        description: 'Business Domain Editor'
    },
    component: () => import('./views/businessDomains/BusinessDomainEditor.vue'),
    permissions: [
        BUSINESS_DOMAIN_PERMISSIONS.ALLOW_EDIT_BUSINESS_DOMAIN,
        BUSINESS_DOMAIN_PERMISSIONS.ALLOW_EDIT_BUSINESS_DOMAIN_SETTINGS
    ]
});

export const BusinessDomainCreation = new ViewGH('BUSINESS_DOMAIN_CREATION', {
    display: {
        icon: 'mdi-domain',
        name: 'New Business Domain',
        description: 'A new form to create a Business Domain'

    },
    component: () => import('./views/businessDomains/BusinessDomainCreation.vue'),
    permissions: [
        BUSINESS_DOMAIN_PERMISSIONS.ALLOW_CREATE_BUSINESS_DOMAIN
    ]
});









export const KnowledgeBaseApp = new AppGH('KNOWLEDGE_BASE_APP', {
        display: {
            icon: 'mdi-graph-outline',
            name: 'KnowledgeBase',
            description: 'This is a corporate knowledge base',
        },
        main: [Glossary, CaseStudyList, QuestionList, ExperienceNoteList, BusinessDomainList],
    })
    .use([
        // Tags
        TagCreation,

        // Case Studies 
        CaseStudyList,
        CaseStudyCreation,
        CaseStudyDetails,
        CaseStudyEditor,
        CaseStudyFilesEditor,
        CaseStudySettingsEditor,

        // Questions 
        QuestionList,
        QuestionCreation,
        QuestionDetails,
        QuestionEditor,

        // Experience Records
        ExperienceNoteList,
        ExperienceNoteCreation,
        ExperienceNoteDetails,
        ExperienceNoteEditor,

        // Glossary
        Glossary,
        TermCreation,
        TermDetails,
        TermGeneralEditor,


        // Business Domains
        BusinessDomainList,
        BusinessDomainCreation,
        BusinessDomainDetails,
        BusinessDomainEditor,
        

    ]);
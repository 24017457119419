import {
    KnowledgeBaseAPIInstance
} from "../../../../../components/api";
import {
    GUI
} from "../../../GrapUI.config.js";
import {
    ExperienceNoteCreation,
    ExperienceNoteDetails,
    ExperienceNoteEditor,
    ExperienceNoteList,
} from "../app.gh.js";


// Create a new store instance.
export const ExperienceNotesStore = {
    namespaced: true,
    state() {
        return {
            activeExperienceNoteId: undefined,
            displayedExperienceNotes: [],
            experienceNote: {},
            experienceNotes: [],
            pagination: {
                page: 1,
                pageSize: 10,
            },
            filter: {
                search: "",
            },
            // Timeouts
            updateTimeout: undefined,
            loadingMap: new Map()
        }
    },
    getters: {
        experienceNoteById: (state) => (id) => {
            const index = state.displayedExperienceNotes.findIndex(s => s.id === id)

            if (index !== -1)
                return state.displayedExperienceNotes[index]
            else
                return {
                    id
                }

        }
    },
    mutations: {
        setExperienceNote(state, experienceNote) {
            let index = state.displayedExperienceNotes.findIndex(s => s.id === experienceNote.id)
            let listIndex = state.experienceNotes.findIndex(s => s.id === experienceNote.id)

            if (index !== -1)
                state.displayedExperienceNotes.splice(index, 1, experienceNote);
            else
                index = state.displayedExperienceNotes.push(experienceNote);


            if (listIndex !== -1) {
                state.experienceNotes.splice(listIndex, 1, experienceNote)
            }

        },
        setActiveId(state, experienceNoteId) {
            state.activeExperienceNoteId = experienceNoteId;
        },
        setExperienceNotes(state, {
            items,
            reset
        } = {}) {
            if (reset)
                state.experienceNotes = items;

            else
                items.forEach(experienceNote => {
                    const experienceNoteIndex = state.experienceNotes.findIndex(cs => cs.id === experienceNote.id);

                    if (experienceNoteIndex !== -1)
                        state.experienceNotes.splice(experienceNoteIndex, 1, experienceNote)
                    else
                        state.experienceNotes.push(experienceNote)
                });
        },
        setPagination(state, pagination) {
            state.pagination = pagination;
        },
        setFilter(state, filter) {
            state.filter = filter;
        }
    },
    actions: {
        openExperienceNoteList(props, {
            from,
        }) {
            GUI.scene.add(from, ExperienceNoteList);
        },
        openExperienceNoteCreation(props, {
            from,
        }) {
            GUI.scene.add(from, ExperienceNoteCreation);
        },
        openExperienceNoteDetails(props, {
            from,
            experienceNote
        }) {
            GUI.scene.add(from, ExperienceNoteDetails, {
                experienceNoteId: experienceNote.id,
            });
        },
        openExperienceNoteGeneralEditor(props, {
            from,
            experienceNote
        }) {
            GUI.scene.add(from, ExperienceNoteEditor, {
                experienceNoteId: experienceNote.id,
            });
        },
        openExperienceNoteSettingsEditor(props, {
            from,
            experienceNote
        }) {
            GUI.scene.add(from, ExperienceNoteEditor, {
                experienceNoteId: experienceNote.id,
            });
        },

        SetActiveExperienceNote({
            commit
        }, {
            from,
            experienceNote
        }) {

            GUI.scene.add(from, ExperienceNoteDetails, {
                experienceNoteId: experienceNote.id,
            });

            commit('setActiveId', experienceNote.id)
        },
        // ExperienceNotes API 
        async GetExperienceNotesList({
            commit,
            state
        }, {
            pagination,
            filter,
            reset,
            next
        } = {}) {
            if (reset) {
                pagination = pagination ? pagination : {}
                filter = filter ? filter : {}
            } else {
                pagination = pagination ? pagination : state.pagination
                filter = filter ? filter : state.filter
            }

            if (next)
                pagination.page++;


            const resp = await KnowledgeBaseAPIInstance
                .ExperienceNotes
                .list(
                    pagination,
                    filter);

            commit('setExperienceNotes', {
                items: resp.items,
                reset
            });

            commit('setPagination', resp.pagination);
            commit('setFilter', filter);
        },

        async CreateNewExperienceNote({
            dispatch
        }, {
            from,
            newExperienceNote
        }) {

            const experienceNote = await KnowledgeBaseAPIInstance
                .ExperienceNotes
                .create(newExperienceNote, {
                    toast: {
                        message: "Note Created",
                    },
                });

            dispatch("GetExperienceNotesList");

            GUI.scene.add(from, ExperienceNoteDetails, {
                experienceNoteId: experienceNote.id
            });
        },
        async GetExperienceNote({
            commit,
            state,
            getters
        }, {
            id,
            refresh
        }) {
            switch (true) {

                // when  loading in progress
                // -> just wait 
                case state.loadingMap.get(id) === 'LOADING': {
                    await new Promise((resolve) => {
                        let i = 0;

                        const handler = () => {
                            if (state.loadingMap.get(id) === 'COMPLETED') {
                                return resolve()
                            } else
                                setTimeout(handler, ++i + 100);
                        }

                        setTimeout(handler, 100);
                    });


                    break;
                }

                // when loaded and Cache Exists
                // -> return existed cache 
                case state.loadingMap.get(id) === 'COMPLETED' && !refresh: {
                    break;
                }

                // when  haven't been ever loaded
                //   OR
                // when  loaded but cache requires refresh
                default: {
                    state.loadingMap.set(id, 'LOADING');

                    const experienceNote = await KnowledgeBaseAPIInstance.ExperienceNotes
                        .get(id);

                    commit('setExperienceNote', experienceNote)

                    state.loadingMap.set(id, 'COMPLETED');

                    break;
                }
            }

            return getters.experienceNoteById(id);
        },

        async UpdateExperienceNote({
            state,
            commit
        }, {
            experienceNote
        }) {

            commit('setExperienceNote', experienceNote);

            if (state.updateTimeout)
                clearTimeout(state.updateTimeout)

            state.updateTimeout = setTimeout(async () => {
                await KnowledgeBaseAPIInstance.ExperienceNotes.update(experienceNote, {
                    toast: {
                        message: "Experience Note Updated",
                    },
                });
            }, 1000);

        },

        async DeleteExperienceNote({
            dispatch
        }, experienceNote) {
            await KnowledgeBaseAPIInstance.ExperienceNotes.delete(experienceNote, {
                toast: {
                    message: "Experience Note Removed",
                },
            });

            dispatch('GetExperienceNote', {
                id: experienceNote.id,
                refresh: true
            });
        },

    }
}
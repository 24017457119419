import Vue from "vue";
import {
    DocAssistantAPInstance
} from "../../../../../components/api";
import {
    GUI
} from "../../../GrapUI.config.js";
import {
    PresentMaster
} from "../app.gh.js";


// Create a new store instance.
export const PresentMasterStore = {
    namespaced: true,
    state() {
        return {
            activePresentMasterId: undefined,
            presentMasters: [],
            pagination: {
                page: 1,
                pageSize: 10,
            },
            filter: {
                search: "",
                typeId: "",
            },
            // Timeouts
            updateTimeout: undefined,
            loadingMap: new Map(),
            loading: false
        }
    },

    mutations: {
        setPresentMasters(state, {
            items,
            reset
        } = {}) {
            if (reset)
                state.presentMasters = items;

            else
                items.forEach(presentMaster => {
                    const presentMasterIndex = state.presentMasters.findIndex(cs => cs.id === presentMaster.id);

                    if (presentMasterIndex !== -1)
                        state.presentMasters.splice(presentMasterIndex, 1, presentMaster)
                    else
                        state.presentMasters.push(presentMaster)
                });
        },
        setPagination(state, pagination) {
            state.pagination = pagination;
        },
        setFilter(state, filter) {
            state.filter = filter;
        }
    },
    actions: {
        openPresentMaster(props, {
            from,
            leadId,
            folderId,
            proposalId,
            serviceIds,
            caseStudyIds,
            slideIds
        }) {
            const fromParams = from.relation && from.relation.params ? {
                ...from.relation.params
            } : undefined

            if (fromParams && fromParams.fromParams)
                delete fromParams.fromParams

            GUI.scene.add(from, PresentMaster, {
                leadId: leadId ? leadId : undefined,
                folderId: folderId ? folderId : undefined,
                proposalId: proposalId ? proposalId : undefined,
                serviceIds,
                caseStudyIds,
                slideIds,
                fromParams
            });
        },

        // PresentMasters API 
        async initGeneration({
            dispatch,
            state
        }, {
            from,
            generationRequest
        }) {

            state.loading = true;

            // const presentMaster =
            const generationItem = await DocAssistantAPInstance
                .PresentMaster
                .create(generationRequest, {
                    toast: {
                        message: "Generation Initiated",
                    },
                });



            const readyItem = await new Promise((resolve, reject) => {
                let nOfRetries = 0;

                const interval = setInterval(async () => {
                    nOfRetries++;
                    const updatedItem = await DocAssistantAPInstance
                        .get(generationItem.id);


                    if (updatedItem.status.code === 'COMPLETED') {
                        state.loading = false;
                        clearInterval(interval);
                        Vue.$toast.open({
                            type: "success",
                            message: 'Generation Completed',
                            position: "top-right",
                        });
                        resolve(updatedItem);
                    } else if (updatedItem.status.code === 'FAILED' || nOfRetries > 30) {
                        state.loading = false;

                        clearInterval(interval);
                        Vue.$toast.open({
                            type: "error",
                            message: 'Generation Failed. Please contact system admin',
                            position: "top-right",
                        });
                        reject();
                    }

                }, 2000);
            })

            if (readyItem && readyItem.document) {
                // Would be nice to handle it somehow else but... 
                //TODO: improve 
                readyItem.document.u_type = 'documents';

                dispatch("FileStore/openFileDetails", {
                    from,
                    file: readyItem.document,
                }, {
                    root: true
                });
            }

        },

    }
}
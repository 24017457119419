//|##########################################################|
//|                      GraphUI V2.0.0                      |
//|----------------------------------------------------------|
//|                © Copyright 2022 adaas.org                |
//|                All rights reserved ADAAS                 |
//|----------------------------------------------------------|
//| Created At: 11.16.2022                                   |
//| Created By: Andrew Tsariuk (andrew.t@adaas.org)          |
//|__________________________________________________________|
//|###########################################################


import {
    GraphUIConfigManager
} from './GraphUIConfig.gh.js';

/* eslint-disable no-unused-vars */
import {
    RelationStoreGH
} from './entities/RelationStore.gh.js';
import {
    ViewGH
} from './entities/View.gh.js';
import {
    AppGH
} from './entities/App.gh.js';
import {
    GraphUIActions
} from './GraphUIActions.gh.js';
import {
    GraphUILogger
} from './helpers/Logger.gh.js';
import {
    SceneGH
} from './render/Scene.gh.js';
/* eslint-enable no-unused-vars */


export const GraphUIConfig = new GraphUIConfigManager();

/**
 * 
 * 
 */
export class GraphUI {

    /**
     * This is a main class of GraphUI
     * It uses to organize the handshake between entities and provides all controls over the entities
     * 
     * 
     * @param {import('./GraphUIConfig.gh.js').GraphUIConfigProps} config
     */
    constructor(config) {


        /**
         * Will be used as shared config across entities
         * 
         * @type {GraphUIConfig}
         */
        this.config = GraphUIConfig.init(config);



        /**
         * uses to search and do other operations over relations store
         * 
         * @type {RelationStoreGH}
         */
        this.store;


        /**
         * The set of application attached to GraphUI instance
         * 
         * @type {AppGH[]}
         */
        this.apps = [];


        /**
         * The helper to work with actions in reactive style
         * 
         * @type {GraphUIActions}
         */
        this.actions = new GraphUIActions(this);


        /**
         * @type {GraphUILogger}
         */
        this.logger = new GraphUILogger(this);


        /**
         * The main scene of GUI
         * 
         * @type {SceneGH}
         */
        this.scene = new SceneGH(this);
    }


    /**
     * Will return an array of all registered GraphUI Views to display
     * 
     * @returns {ViewGH[]}
     */
    get views() {
        return this.apps.reduce((acc, app) => [...acc, ...app.views], []);
    }


    /**
     * Will return all component for lazy-loading 
     * 
     */
    get components() {
        return []
    }



    /**
     * Will attach Apps to the GraphUI instance
     * 
     * @param {AppGH[]}
     * @returns {GraphUI}
     */
    use(apps) {
        this.apps = apps;

        // just init everything that depends on APPs
        this.__init();

        return this;
    }



    /**
     * after all properties defined just start GUI
     * @private
     */
    __init() {

        this.store = new RelationStoreGH(this);
    }

}
//|##########################################################|
//|                      GraphUI V2.0.0                      |
//|----------------------------------------------------------|
//|                © Copyright 2022 adaas.org                |
//|                All rights reserved ADAAS                 |
//|----------------------------------------------------------|
//| Created At: 11.16.2022                                   |
//| Created By: Andrew Tsariuk (andrew.t@adaas.org)          |
//|__________________________________________________________|
//|###########################################################

import {
    StoreDriverAbstract
} from "./StoreDriver.abstract.js";
import _ from 'lodash';

/* eslint-disable no-unused-vars */
import {
    ViewGH
} from "../View.gh.js";
import {
    RelationGH
} from "../Relation.gh.js";
import {
    RenderingViewGH
} from "../../render/RenderingView.gh.js";
/* eslint-enable no-unused-vars */


export class WebDriver extends StoreDriverAbstract {

    /**
     * This class allows GraphUI to use local storage as a main storage for graph structure. 
     * @param {GraphUI} graphUI
     */
    constructor(graphUI) {
        super(graphUI);

        /**
         * The name of key to store data in local storage
         * @private
         */
        this.__key = 'GRAPH_UI_RELATION_STORE_V2';

        /**
         * Changed relations to prevent extra calls on get operations
         * 
         * @type {RelationGH[]}
         */
        this.__cached = [];


        //init cached items 
        this.__deserialize();

        /**
         * unique identifier to log data properly
         * 
         * @private
         * @type {string}
         */
        this.__logAlias = 'WebDriver'

    }


    /**
     * Should return a set of Relations associated with provided View by Key
     * Will apply sort and filters based on the received options object
     * 
     * 
     * @param {RenderingViewGH[]} rViews 
     * @param {import('./StoreDriver.abstract.js').StoreDriverOptions} options 
     * 
     * @returns {RelationGH[]}
     */
    async get(rViews, options) {

        //just make sure that options are proper and has default values
        options = this.__prepareOptions(options);

        this.__graphUI.logger.log(this.__logAlias, 'START- GET', rViews.map(rv => rv.view), options);


        const filtered = this.__cached.filter((relation, index, arr) => {

            //Will be valid if relation from presented in the views array
            const isValid = !!rViews.find(rv => {

                let valid = relation.from.key == rv.view.key





                // TODO MOVE fromParams to types
                if (relation.params && relation.params.fromParams) {

                    // TODO MAKE IT BETTER: Move fromParams to a proper variable to RelationGH

                    // const possibleRelationParamsFrom = relation.params && relation.params.fromParams ? {
                    //     ...relation.params.fromParams
                    // } : undefined



                    const previousViewParams = rv.relation.params ? {
                        ...rv.relation.params
                    } : undefined


                    if (previousViewParams&& previousViewParams.fromParams)
                        delete previousViewParams.fromParams

                    valid &= JSON.stringify(relation.params.fromParams) === JSON.stringify(previousViewParams)
                }

                if ((rv.relation.params && rv.relation.params.fromParams) && !(relation.params && relation.params.fromParams))
                    valid &= false;


                return valid
            });

            let customValid = 1;
            //custom validation and filtering
            if (options.filter)
                for (const key in options.filter) {
                    customValid &= _.get(relation.params, key) === options.filter[key]
                }

            if (options.exclude)
                customValid &= options.exclude.findIndex(v => v.isEqual(relation)) === -1

            let defaultValidation = 1;

            defaultValidation &= arr.findIndex(v => v.isEqual(relation)) === index;



            return !!(isValid & +customValid & +defaultValidation);
        });

        this.__graphUI.logger.log(this.__logAlias, 'RECEIVED - FILTERED', filtered);



        const result = _.orderBy(
                filtered,
                Object.keys(options.orderBy),
                Object.values(options.orderBy)
            )
            .slice(0, options.limit);

        this.__graphUI.logger.log(this.__logAlias, 'FINISHED - GET. Sorted:', result);


        return result || [];
    }

    /**
     * Should merge old and new views into one array
     * 
     * @param {RenderingViewGH[]} oldViews 
     * @param {RenderingViewGH[]} newViews 
     * @param {number} startIndex - the index from where to start merge operation
     * @param {import('./StoreDriver.abstract.js').StoreDriverOptions} options 
     */
    async merge(oldViews, newViews, startIndex, options) {

        //TODO remove if unused

        const merged = [
            //slice up to display index
            ...oldViews.slice(0, startIndex),
            //all new data
            ...newViews,
        ];

        // replace links
        // for (let i = 0; i < merged.length; i++) {
        //     const new = 

        //     output.push()
        // }



        return _.orderBy(
                _.uniqWith(
                    merged,
                    /**
                     * 
                     * @param {RenderingViewGH} el1 
                     * @param {RenderingViewGH} el2 
                     * @returns 
                     */
                    (el1, el2) => el1.isEqual(el2)
                ),
                Object.keys(options.orderBy),
                Object.values(options.orderBy)
            )
            .slice(0, options.limit);
    }

    /**
     * Will add a relation to cached Array and then to local storage
     * 
     * @param {RelationGH} relation
     */
    async add(relation) {
        this.__cached.push(relation);

        this.__serialize();
    }

    /**
     * 
     * @param {ViewGH} from 
     * @param {ViewGH} to 
     * @param {Object} params 
     */
    async find(from, to, params) {

        return this.__cached.find(relation => {
            //TODO make custom identifier
            return relation.from.key === from.key &&
                relation.to.key === to.key &&
                JSON.stringify(relation.params) === JSON.stringify(params)
        });
    }

    /**
     * Will remove relation from localstorage
     * @param {RelationGH} relation 
     */
    remove(relation) {
        const relationIndex = this.__cached.findIndex(rel => {
            //TODO make custom identifier
            return rel.from.key === relation.from.key &&
                rel.to.key === relation.to.key &&
                JSON.stringify(rel.params) === JSON.stringify(relation.params)
        });

        if (relationIndex !== -1)
            this.__cached.splice(relationIndex, 1);

        this.__serialize();
    }


    /**
     * should be used to update  GraphUI Relation parameters and configurations
     * 
     * @param {RelationGH} relation 
     */
    async update(relation) {

        //TODO update if needed
        relation;
        this.__serialize();


        // const existedRelation = this.__cached.find(rel => {
        //     //TODO make custom identifier
        //     return relation.from.key === rel.from.key &&
        //         relation.to.key === rel.to.key &&
        //         JSON.stringify(relation.params) === JSON.stringify(rel.params)
        // })

    }


    /**
     * Should get an item from localstorage and will prepare a proper set of Relations with a proper object links
     * 
     * @private
     * @returns {WebDriver}
     */
    __deserialize() {
        try {
            const storedData = window.localStorage.getItem(this.__key);

            /**
             * @type {import('../Relation.gh.js').SerializedRelationGH[]}
             */
            const parsed = JSON.parse(storedData);

            //Parse Data from local storage
            this.__cached = parsed.map(relation => {
                //Find a proper View to keep Object memory Link
                const from = this.__graphUI.views.find(v => v.key === relation.from)
                //Find a proper View to keep Object memory Link
                const to = this.__graphUI.views.find(v => v.key === relation.to)

                //Create a new Relation  with a proper links
                return new RelationGH(from, to, {
                    weight: relation.weight,
                    params: relation.params
                });
            });

        } catch (error) {
            this.__cached = [];
        }

        return this;

    }


    /**
     * Will store items to the storage. Will update all relations and will keep them the same as cached items
     * 
     * @private
     * @returns {WebDriver}
     */
    __serialize() {
        //just store items to storage
        window.localStorage.setItem(this.__key, JSON.stringify(this.__cached));
        return this;
    }
}
import {
    AppGH
} from '../../../../../lib/graph-ui/corev2/entities/App.gh.js';
import {
    ViewGH
} from '../../../../../lib/graph-ui/corev2/entities/View.gh.js';
import {
    DELIVERABLE_PERMISSIONS,
    SERVICE_PERMISSIONS
} from './constants/permissions.gh.js'

export const ServicesList = new ViewGH('SERVICES_LIST', {
    display: {
        icon: 'mdi-briefcase',
        name: 'Services',
        description: 'This is a list of all services'
    },
    component: () => import('./views/services/ServicesList.vue'),
    permissions: [
        SERVICE_PERMISSIONS.ALLOW_CREATE_SERVICE,
        SERVICE_PERMISSIONS.ALLOW_SEE_SERVICE_LIST
    ]

});
export const ServiceDetails = new ViewGH('SERVICE_DETAILS', {
    display: {
        icon: 'mdi-briefcase-eye',
        name: 'Services Details',
        description: 'More details on the service'

    },
    // addStrategy: 'activate',
    component: () => import('./views/services/ServiceDetails.vue'),
    permissions: [
        SERVICE_PERMISSIONS.ALLOW_EDIT_SERVICE,
        SERVICE_PERMISSIONS.ALLOW_EDIT_SERVICE_SETTINGS,
        SERVICE_PERMISSIONS.ALLOW_EDIT_SERVICE_HEADER,
        SERVICE_PERMISSIONS.ALLOW_EDIT_SERVICE_OVERVIEW,
        SERVICE_PERMISSIONS.ALLOW_EDIT_SERVICE_FREE_SECTIONS,
        SERVICE_PERMISSIONS.ALLOW_EDIT_SERVICE_USE_CASES,
        SERVICE_PERMISSIONS.ALLOW_EDIT_SERVICE_DELIVERABLES,
        SERVICE_PERMISSIONS.ALLOW_EDIT_SERVICE_PLANS
    ]
    // comparator: (relation, rView) =>
    // relation.viewA.key === rView.view.key &&
    // relation.params.serviceId === rView.params.serviceId,
    // identifier: (relation) => relation.viewA.key + '_' + relation.params.serviceId,
});


export const ServiceCreation = new ViewGH('SERVICE_CREATION', {
    display: {
        name: 'New Service',
        icon: 'mdi-briefcase-plus',
    },
    component: () => import('./views/services/ServiceCreation.vue'),
    permissions: [
        SERVICE_PERMISSIONS.ALLOW_CREATE_SERVICE
    ]

});


// Editor 
export const ServiceSettingsEditor = new ViewGH('SERVICE_SETTINGS_EDITOR', {
    display: {
        name: 'Service Settings',
        icon: 'mdi-briefcase-arrow-left-right',
    },
    component: () => import('./views/services/editors/ServiceGeneralEditor.vue'),
    permissions: [
        SERVICE_PERMISSIONS.ALLOW_EDIT_SERVICE_SETTINGS
    ]
});

export const ServiceHeaderEditor = new ViewGH('SERVICE_HEADER_EDITOR', {
    display: {
        name: 'Service Header',
        icon: 'mdi-briefcase-edit',
    },
    component: () => import('./views/services/editors/ServiceHeaderEditor.vue'),
    permissions: [
        SERVICE_PERMISSIONS.ALLOW_EDIT_SERVICE_HEADER
    ]
});

export const ServiceOverviewEditor = new ViewGH('SERVICE_OVERVIEW_EDITOR', {
    display: {
        name: 'Service Overview',
        icon: 'mdi-briefcase-edit',
    },
    component: () => import('./views/services/editors/ServiceOverviewEditor.vue'),
    permissions: [
        SERVICE_PERMISSIONS.ALLOW_EDIT_SERVICE_OVERVIEW
    ]
});

export const ServiceFreeSectionEditor = new ViewGH('SERVICE_FREE_SECTION_EDITOR', {
    display: {
        name: 'Service Free Section Editor',
        icon: 'mdi-briefcase-edit',
    },
    component: () => import('./views/services/editors/ServiceFreeSectionEditor.vue'),
    permissions: [
        SERVICE_PERMISSIONS.ALLOW_EDIT_SERVICE_FREE_SECTIONS
    ]
});

export const ServiceUseCasesEditor = new ViewGH('SERVICE_USE_CASES_EDITOR', {
    display: {
        name: 'Service Use Cases Editor',
        icon: 'mdi-briefcase-edit',
    },
    component: () => import('./views/services/editors/ServiceUseCasesEditor.vue'),
    permissions: [
        SERVICE_PERMISSIONS.ALLOW_EDIT_SERVICE_USE_CASES
    ]
});

export const ServiceDeliverablesEditor = new ViewGH('SERVICE_DELIVERABLES_EDITOR', {
    display: {
        name: 'Service Deliverables Editor',
        icon: 'mdi-briefcase-edit',
    },
    component: () => import('./views/services/editors/ServiceDeliverablesEditor.vue'),
    permissions: [
        SERVICE_PERMISSIONS.ALLOW_EDIT_SERVICE_DELIVERABLES,
        DELIVERABLE_PERMISSIONS.ALLOW_CREATE_DELIVERABLE,
        DELIVERABLE_PERMISSIONS.ALLOW_SEE_DELIVERABLE_LIST,
    ]
});

export const ServicePlansEditor = new ViewGH('SERVICE_PLANS_EDITOR', {
    display: {
        name: 'Service Plans Editor',
        icon: 'mdi-briefcase-edit',
    },
    component: () => import('./views/services/editors/ServicePlansEditor.vue'),
    permissions: [
        SERVICE_PERMISSIONS.ALLOW_EDIT_SERVICE_DELIVERABLES,
        DELIVERABLE_PERMISSIONS.ALLOW_CREATE_DELIVERABLE,
        DELIVERABLE_PERMISSIONS.ALLOW_SEE_DELIVERABLE_LIST,
    ]
});



// =========================== DELIVERABLES ===========================

export const DeliverableList = new ViewGH('DELIVERABLE_LIST', {
    display: {
        icon: 'mdi-file-document-multiple-outline',
        name: 'Deliverables',
        description: 'The list of Deliverables'
    },
    component: () => import('./views/deliverables/DeliverableList.vue'),
    permissions: [
        DELIVERABLE_PERMISSIONS.ALLOW_CREATE_DELIVERABLE,
        DELIVERABLE_PERMISSIONS.ALLOW_SEE_DELIVERABLE_LIST
    ]
});

export const DeliverableCreation = new ViewGH('DELIVERABLE_CREATION', {
    display: {
        name: 'New Deliverable',
        icon: 'mdi-file-plus-outline',
    },
    component: () => import('./views/deliverables/DeliverableCreation.vue'),
    permissions: [
        DELIVERABLE_PERMISSIONS.ALLOW_CREATE_DELIVERABLE
    ]

});

export const DeliverableDetails = new ViewGH('DELIVERABLE_DETAILS', {
    display: {
        icon: 'mdi-file-document-outline',
        name: 'Deliverable Details',
        description: 'More details on the deliverable'
    },
    permissions: [
        DELIVERABLE_PERMISSIONS.ALLOW_EDIT_DELIVERABLE,
        DELIVERABLE_PERMISSIONS.ALLOW_SEE_DELIVERABLE_CUSTOMER_DOCUMENTS,
        DELIVERABLE_PERMISSIONS.ALLOW_SEE_DELIVERABLE_PRIVATE_DOCUMENTS,
    ],
    // addStrategy: 'activate',
    component: () => import('./views/deliverables/DeliverableDetails.vue'),
});


export const DeliverableGeneralEditor = new ViewGH('DELIVERABLE_GENERAL_EDITOR', {
    display: {
        icon: 'mdi-file-cog-outline',
        name: 'Deliverable Editor',
        description: 'Editor for the deliverable'
    },
    component: () => import('./views/deliverables/editors/DeliverableGeneralEditor.vue'),
    permissions: [
        DELIVERABLE_PERMISSIONS.ALLOW_EDIT_DELIVERABLE
    ]
});

export const DeliverableFilesEditor = new ViewGH('DELIVERABLE_FILES_EDITOR', {
    display: {
        icon: 'mdi-file-cog-outline',
        name: 'Deliverable Files',
        description: 'Files Uploader for the deliverable'
    },
    permissions: [],
    // addStrategy: 'activate',
    component: () => import('./views/deliverables/editors/DeliverableFilesEditor.vue'),
});



export const ServiceManagement = new AppGH('SERVICE_MANAGEMENT', {
    display: {
        icon: 'mdi-briefcase',
        name: 'Services',
        description: 'This app provide an ability to manage services',
    },
    main: [ServicesList, DeliverableList],
}).use([
    ServicesList,
    ServiceDetails,
    ServiceCreation,
    //Service Editors
    ServiceSettingsEditor,
    ServiceHeaderEditor,
    ServiceOverviewEditor,
    ServiceUseCasesEditor,
    ServiceFreeSectionEditor,
    ServiceDeliverablesEditor,
    ServicePlansEditor,
    // Deliverables
    DeliverableList,
    DeliverableCreation,
    DeliverableDetails,
    DeliverableGeneralEditor,
    DeliverableFilesEditor
]);
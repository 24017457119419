import {
    AppGH
} from '../../../../../lib/graph-ui/corev2/entities/App.gh.js';
import {
    ViewGH
} from '../../../../../lib/graph-ui/corev2/entities/View.gh.js';



//  Users 
export const UserList = new ViewGH('USER_LIST', {
    display: {
        icon: 'mdi-account-multiple',
        name: 'Users list',
        description: 'This is a list of all existed users'
    },
    component: () => import('./views/users/UsersList.vue'),
    permissions:[
        'ALLOW_SEE_USERS_LIST'
    ]
});
export const UserDetails = new ViewGH('USER_DETAILS', {
    display: {
        icon: 'mdi-account-multiple',
        name: 'User Details',
        description: 'Users Detailed Information'
    },
    // addStrategy: 'activate',
    component: () => import('./views/users/UsersDetails.vue'),
});
export const UserEditor = new ViewGH('USER_GENERAL_EDITOR', {
    display: {
        icon: 'mdi-account-multiple',
        name: 'User Settings',
        description: 'User Editor'
    },
    component: () => import('./views/users/UsersEditor.vue'),
});

export const UserCreation = new ViewGH('USER_CREATION', {
    display: {
        icon: 'mdi-account-multiple-plus',
        name: 'New User',
        description: 'A new form to create a User'

    },
    component: () => import('./views/users/UsersCreation.vue'),
});



//  Roles 
export const RoleList = new ViewGH('ROLE_LIST', {
    display: {
        icon: 'mdi-shield-key-outline',
        name: 'Roles list',
        description: 'This is a list of all existed users'
    },
    component: () => import('./views/roles/RolesList.vue')
});
export const RoleDetails = new ViewGH('ROLE_DETAILS', {
    display: {
        icon: 'mdi-shield-key-outline',
        name: 'Role Details',
        description: 'Roles Detailed Information'
    },
    // addStrategy: 'activate',
    component: () => import('./views/roles/RolesDetails.vue'),
});
export const RoleEditor = new ViewGH('ROLE_GENERAL_EDITOR', {
    display: {
        icon: 'mdi-shield-key-outline',
        name: 'Role Settings',
        description: 'Role Editor'
    },
    component: () => import('./views/roles/RolesEditor.vue'),
});

export const RoleCreation = new ViewGH('ROLE_CREATION', {
    display: {
        icon: 'mdi-shield-plus-outline',
        name: 'New Role',
        description: 'A new form to create a Role'

    },
    component: () => import('./views/roles/RolesCreation.vue'),
});



export const UsersManagementApp = new AppGH('USERS_MANAGEMENT_APP', {
        display: {
            icon: 'mdi-account-multiple',
            name: 'Users Management',
            description: 'This is a and admin tool to manage users',
        },
        main: [UserList, RoleList],
    })
    .use([
        // Users 
        UserList,
        UserCreation,
        UserDetails,
        UserEditor,

        // Roles
        RoleList,
        RoleCreation,
        RoleDetails,
        RoleEditor,

    ]);
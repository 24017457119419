

<template>
  <div class="desktop-wrapper">
    <fullscreen
      class="fullscreen"
      v-model="fullscreen"
      :style="{
        '--bgImage': `url(${
          user && user.settings && user.settings.system_background
            ? user.settings.system_background
            : '/static/images/backgrounds/49.webp'
        })`,
      }"
    >
      <BreadcrumbsVue
        :value="breadcrumbs"
        @onBreadcrumb="onBreadcrumb"
      ></BreadcrumbsVue>
      <v-icon
        @click="fullscreen = !fullscreen"
        color="white"
        class="maximize-window"
        >mdi-window-maximize</v-icon
      >

      <div
        id="desktop"
        class="desktop-container"
        @mousedown="startDrag"
        @mousemove="doDrag"
      >
        <TreeViewDesktop
          ref="treeView"
          :levels="levels"
          @onActivate="onActivate"
          @onAdd="onAdd"
          @onMaximize="onMaximize"
          @onClose="onClose"
          @onExpand="onExpand"
          @onCollapse="onCollapse"
        ></TreeViewDesktop>
      </div>

      <!-- APP Bar -->
      <AppsBarVue
        @onAppSelected="onAppSelected"
        :value="apps"
        @onProfileOpen="drawer = true"
      ></AppsBarVue>

      <v-navigation-drawer
        class="transparent profile"
        dark
        right
        v-model="drawer"
        temporary
        width="375"
        app
      >
        <UserProfile :value="user"></UserProfile>
      </v-navigation-drawer>
    </fullscreen>
  </div>
</template>


    
<script>
import { CommonHelper } from "../../../components/helpers/common.helper";
import AppsBarVue from "./components/AppsBar.vue";
import TreeViewDesktop from "./components/TreeViewDesktop.vue";
import { GUI } from "../GrapUI.config.js";
import BreadcrumbsVue from "./components/Breadcrumbs.vue";
import UserProfile from "./components/profile/UserProfile.vue";
import { mapGetters, mapState } from "vuex";
import { AuthAPIInstance } from "../../../components/api";

export default {
  data() {
    return {
      dark: true,
      signInCompleted: false,
      startRedirect: false,
      list: [],
      portals: [],
      fullscreen: false,

      selectedPortal: null,
      dragging: false,
      x: 0,
      y: 0,
      height: 50,
      width: 50,
      preX: 0,
      preY: 0,
      appOpened: false,
      scene: GUI.scene,

      apps: [],
      breadcrumbs: GUI.scene.breadcrumbs.findIndex(),

      drawer: null,
      items: [
        { title: "Home", icon: "mdi-view-dashboard" },
        { title: "About", icon: "mdi-forum" },
      ],

      levels: [],
    };
  },
  computed: {
    ...mapState("MainStore", ["user"]),
    ...mapGetters("MainStore", ["availableApps", "availableViews"]),
  },
  async created() {
    // window.addEventListener("mouseup", this.stopDrag);

    this.apps = this.filterApps(this.availableApps);

    // ===================GRAPH UI HANDLERS===================
    GUI.actions.subscribe("onMove", (props) => this.onMove(props));
    GUI.actions.subscribe("onViewBeforeEnter", (props) =>
      this.onViewEnter(props)
    );
  },
  components: {
    // PortalSelectionComponentVue,
    // AAppVue,
    TreeViewDesktop,
    AppsBarVue,
    BreadcrumbsVue,
    // TreeViewV2Vue
    // TreeViewV3
    UserProfile,
  },
  methods: {
    onMaximize(e, level) {
      level.maximize();
    },
    onClose(view) {
      this.scene.remove(view);
    },
    onExpand(view) {
      this.scene.expand(view);
    },
    onCollapse() {
      this.scene.collapse();
    },
    async onAppSelected(app, view) {
      const { levels, breadcrumbs } = await this.scene.openApp(app, view);
      this.levels = levels;
      this.breadcrumbs = breadcrumbs;
    },
    async onActivate($event, item) {
      this.scene.activate(item);
    },
    async onAdd(from, to) {
      this.scene.add(from, to);
    },
    async onBreadcrumb(breadcrumb) {
      this.scene.activate(breadcrumb);
    },
    openFullScreen() {
      this.fullscreen = !this.fullscreen;
    },
    onMove({ breadcrumb }) {
      try {
        this.$refs.treeView.move(breadcrumb);
      } catch (error) {
        console.log("move error");
      }
    },
    async onViewEnter({ rView }) {
      if (rView.view.permissions && rView.view.permissions.length) {
        const perms = await AuthAPIInstance.checkPermissions(
          rView.view.permissions,
          this.user
        );
        rView.setPermissions(perms);
      }
    },
    startDrag() {
      this.dragging = true;
      // this.x = this.y = 0;
    },
    stopDrag() {
      this.dragging = false;
      // this.x = this.y = 'no';
    },
    doDrag(event) {
      if (this.dragging) {
        this.x = event.clientX;
        this.y = event.clientY;
      }
    },
    async addPortalsWithDelay(array) {
      for (let item of array) {
        await CommonHelper.delay(100);
        this.portals.push(item);
      }
    },
    backToLogin() {
      this.$router.push("/desktop");
    },
    portalSelected(portal) {
      console.log("PORTAAAAAl", portal);
      this.selectedPortal = portal;

      // setTimeout(() => {
      //   this.startRedirect = true;

      //   setTimeout(() => {
      //     window.location.href = this.selectedPortal.location;
      //   }, 2000);
      // }, 1500);
    },

    filterApps(apps = []) {
      return GUI.apps.filter((app) => apps.find((el) => el.key === app.key));
    },
  },
  watch: {
    availableApps(newVal) {
      this.apps = this.filterApps(newVal);
    },
  },
};
</script>
    
<style lang="scss" scoped>
.maximize-window {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 999;
}
.fullscreen {
  height: 100%;
  width: 100%;
  overflow: hidden;

  // overflow: hidden;
  width: 100%;
  height: 100vh;
  padding: 20px;
  padding-top: 40px;
  padding-bottom: 80px;
  background: var(--bgImage);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top left;
  background-size: cover;
  // background: rgba(255, 255, 255, 0.9);
}

.desktop-container {
  overflow-x: visible;
  overflow-y: visible;
  // overflow: scroll;
  width: 100%;
  height: calc(100vh - 120px);
}

.desktop-completed {
  width: 100%;

  .logo {
    width: 700px;
  }
}

.profile {
  backdrop-filter: blur(20px);
}
</style>
    
    
    
    
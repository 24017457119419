import {
    KnowledgeBaseAPIInstance
} from "../../../../../components/api";
import {
    GUI
} from "../../../GrapUI.config.js";
import {
    QuestionCreation,
    QuestionDetails,
    QuestionEditor,
    QuestionList,
} from "../app.gh.js";


// Create a new store instance.
export const QuestionStore = {
    namespaced: true,
    state() {
        return {
            activeQuestionId: undefined,
            displayedQuestions: [],
            question: {},
            questions: [],
            letters: [],
            pagination: {
                page: 1,
                pageSize: 10,
            },
            filter: {
                search: "",
                typeId: "",
            },
            // Timeouts
            updateTimeout: undefined,
            loadingMap: new Map()
        }
    },
    getters: {
        questionById: (state) => (id) => {

            const index = state.displayedQuestions.findIndex(s => s.id === id)

            if (index !== -1)
                return state.displayedQuestions[index]
            else
                return {
                    id,
                    searchableKeyword: {},
                }

        }
    },
    mutations: {
        setQuestion(state, question) {
            let index = state.displayedQuestions.findIndex(s => s.id === question.id)
            let listIndex = state.questions.findIndex(s => s.id === question.id)

            if (index !== -1) {
                if (JSON.stringify(question) === JSON.stringify(state.displayedQuestions[index]))
                    return;
                else
                    state.displayedQuestions.splice(index, 1, question);
            } else
                state.displayedQuestions.push(question);


            if (listIndex !== -1) {
                state.questions.splice(listIndex, 1, question)
            }

        },
        setActiveId(state, questionId) {
            state.activeQuestionId = questionId;
        },
        setQuestions(state, {
            items,
            reset
        } = {}) {
            if (reset)
                state.questions = items;

            else
                items.forEach(question => {
                    const questionIndex = state.questions.findIndex(cs => cs.id === question.id);

                    if (questionIndex !== -1)
                        state.questions.splice(questionIndex, 1, question)
                    else
                        state.questions.push(question)
                });
        },
        setLetters(state, letters) {
            state.letters = letters;
        },
        setPagination(state, pagination) {
            state.pagination = pagination;
        },
        setFilter(state, filter) {
            state.filter = filter;
        }
    },
    actions: {
        openQuestionList(props, {
            from,
        }) {
            GUI.scene.add(from, QuestionList);
        },
        openQuestionCreation(props, {
            from,
        }) {
            GUI.scene.add(from, QuestionCreation);
        },
        openQuestionDetails(props, {
            from,
            question
        }) {
            GUI.scene.add(from, QuestionDetails, {
                questionId: question.id,
            });
        },
        openQuestionGeneralEditor(props, {
            from,
            question
        }) {

            GUI.scene.add(from, QuestionEditor, {
                questionId: question.id,
            });
        },
        openQuestionSettingsEditor(props, {
            from,
            question
        }) {

            GUI.scene.add(from, QuestionEditor, {
                questionId: question.id,
            });
        },

        SetActiveQuestion({
            commit
        }, {
            from,
            question
        }) {

            GUI.scene.add(from, QuestionDetails, {
                questionId: question.id,
            });

            commit('setActiveId', question.id)
        },
        // Questions API 
        async GetQuestionsList({
            commit,
            state
        }, {
            pagination,
            filter,
            reset,
            next
        } = {}) {
            if (reset) {
                pagination = pagination ? pagination : {}
                filter = filter ? filter : {}
            } else {
                pagination = pagination ? pagination : state.pagination
                filter = filter ? filter : state.filter
            }

            if (next)
                pagination.page++;


            const resp = await KnowledgeBaseAPIInstance
                .Questions
                .list(
                    pagination,
                    filter);

            commit('setQuestions', {
                items: resp.items,
                reset
            });

            commit('setPagination', resp.pagination);
            commit('setFilter', filter);
        },
        async CreateNewQuestion({
            dispatch
        }, {
            from,
            newQuestion
        }) {

            const question = await KnowledgeBaseAPIInstance
                .Questions
                .create(newQuestion, {
                    toast: {
                        message: "Question Created",
                    },
                });

            dispatch("GetQuestionsList", {
                reset: true
            });

            GUI.scene.add(from, QuestionDetails, {
                questionId: question.id
            });
        },
        async GetQuestion({
            commit,
            state,
            getters
        }, {
            id,
            refresh
        }) {

            switch (true) {

                // when  loading in progress
                // -> just wait 
                case state.loadingMap.get(id) === 'LOADING': {
                    await new Promise((resolve) => {
                        let i = 0;

                        const handler = () => {
                            if (state.loadingMap.get(id) === 'COMPLETED') {
                                return resolve()
                            } else
                                setTimeout(handler, ++i + 100);
                        }

                        setTimeout(handler, 100);
                    });


                    break;
                }

                // when loaded and Cache Exists
                // -> return existed cache 
                case state.loadingMap.get(id) === 'COMPLETED' && !refresh: {
                    break;
                }

                // when  haven't been ever loaded
                //   OR
                // when  loaded but cache requires refresh
                default: {
                    state.loadingMap.set(id, 'LOADING');

                    const question = await KnowledgeBaseAPIInstance.Questions
                        .get(id);

                    commit('setQuestion', question)

                    state.loadingMap.set(id, 'COMPLETED');

                    break;
                }
            }

            return getters.questionById(id);
        },

        async UpdateQuestion({
            state,
            dispatch,
        }, {
            question
        }) {


            if (state.updateTimeout)
                clearTimeout(state.updateTimeout)

            state.updateTimeout = setTimeout(async () => {
                // commit('setQuestion', question);

                await KnowledgeBaseAPIInstance.Questions.update(question, {
                    toast: {
                        message: "Question Updated",
                    },
                });
                dispatch('GetQuestion', {
                    id: question.id,
                    refresh: true
                });

                // commit('setQuestion', updatedQuestion)
            }, 1000);

        },

        async DeleteQuestion({
            dispatch
        }, question) {
            await KnowledgeBaseAPIInstance.Questions.delete(question, {
                toast: {
                    message: "Question Updated",
                },
            });

            dispatch('GetQuestion', {
                id: question.id,
                refresh: true
            });
        },

    }
}
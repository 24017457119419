import Vue from "vue";
import {
    AnalyzeCategoriesAPIInstance,
    LeadsAPIInstance
} from "../../../../../components/api";
import {
    GUI
} from "../../../GrapUI.config.js";
import {
    LeadsAnalyzeCategories,
    LeadsAnalyzeCategoryCreation,
    LeadsAnalyzeCategoryDetails,
    // AnalyzeCategoryFilesEditor,
    AnalyzeCategoryGeneralEditor,
    AnalyzeCategoryHistory,
} from "../app.gh.js";


// Create a new store instance.
export const AnalyzeCategoryStore = {
    namespaced: true,
    state() {
        return {
            activeAnalyzeCategoryId: undefined,
            displayedAnalyzeCategories: [],
            analyzeCategory: {
                config: {}
            },
            analyzeCategories: [],
            pagination: {
                page: 1,
                pageSize: 100,
            },
            filter: {
                search: "",
                type: "",
            },
            analyzeCategoryUpdateTimeout: undefined,
            loadingMap: new Map(),
            statuses: [],
            history: {}
        }
    },
    getters: {
        analyzeCategoryById: (state) => (id) => {
            const index = state.displayedAnalyzeCategories.findIndex(s => s.id === id)

            if (index !== -1)
                return state.displayedAnalyzeCategories[index];
            else
                return {
                    id,
                    config: {}
                }

        },
        historyById: (state) => id => {
            return state.history[id] && state.history[id].items ? state.history[id].items : []
        }
    },
    mutations: {
        setAnalyzeCategory(state, analyzeCategory) {
            let index = state.displayedAnalyzeCategories.findIndex(d => d.id === analyzeCategory.id)
            let listIndex = state.analyzeCategories.findIndex(d => d.id === analyzeCategory.id)

            if (index !== -1)
                state.displayedAnalyzeCategories.splice(index, 1, analyzeCategory);
            else
                index = state.displayedAnalyzeCategories.push(analyzeCategory);


            if (listIndex !== -1) {
                state.analyzeCategories.splice(listIndex, 1, analyzeCategory)
            }

        },
        setActiveId(state, analyzeCategoryId) {
            state.activeAnalyzeCategoryId = analyzeCategoryId;
        },
        setAnalyzeCategories(state, {
            items,
            reset
        } = {}) {
            if (reset)
                state.analyzeCategories = items;

            else
                items.forEach(analyzeCategory => {
                    const analyzeCategoryIndex = state.analyzeCategories.findIndex(del => del.id === analyzeCategory.id);

                    if (analyzeCategoryIndex !== -1)
                        state.analyzeCategories.splice(analyzeCategoryIndex, 1, analyzeCategory)
                    else
                        state.analyzeCategories.push(analyzeCategory)
                });
        },
        setPagination(state, pagination) {
            state.pagination = pagination;
        },
        setFilter(state, filter) {
            state.filter = filter;
        },
        setBoard(state, {
            items,
            status
        }) {
            state.board[status] = items
        },
        setStatuses(state, {
            items
        }) {
            state.statuses = items
        },
        setHistory(state, {
            id,
            resp
        }) {
            Vue.set(state.history, id, resp)
        }
    },
    actions: {

        //============PAGES==========================
        openAnalyzeCategoryList(props, {
            from,
        }) {
            GUI.scene.add(from, LeadsAnalyzeCategories);
        },

        openAnalyzeCategoryDetails(props, {
            from,
            analyzeCategory
        }) {
            GUI.scene.add(from, LeadsAnalyzeCategoryDetails, {
                analyzeCategoryId: analyzeCategory.id
            });
        },

        openAnalyzeCategoryCreation(props, {
            from,
        }) {

            console.log('FROM: ', from)
            GUI.scene.add(from, LeadsAnalyzeCategoryCreation);
        },

        openAnalyzeCategoryGeneralEditor(props, {
            from,
            analyzeCategory
        }) {
            GUI.scene.add(from, AnalyzeCategoryGeneralEditor, {
                analyzeCategoryId: analyzeCategory.id,
            });
        },
        openAnalyzeCategoryHistory(props, {
            from,
            analyzeCategory
        }) {
            GUI.scene.add(from, AnalyzeCategoryHistory, {
                analyzeCategoryId: analyzeCategory.id,
            });
        },

        SetActiveAnalyzeCategory({
            commit
        }, {
            from,
            analyzeCategory
        }) {

            GUI.scene.add(from, LeadsAnalyzeCategoryDetails, {
                analyzeCategoryId: analyzeCategory.id,
            });

            commit('setActiveId', analyzeCategory.id)
        },

        // AnalyzeCategories API 
        async GetAnalyzeCategoriesList({
            commit,
            state
        }, {
            pagination,
            filter,
            reset,
            next
        } = {}) {
            if (reset) {
                pagination = pagination ? pagination : {}
                filter = filter ? filter : {}
            } else {
                pagination = pagination ? pagination : state.pagination
                filter = filter ? filter : state.filter
            }

            if (next)
                pagination.page++;


            const resp = await LeadsAPIInstance.AnalyzeCategory
                .list(
                    pagination,
                    filter);

            commit('setAnalyzeCategories', {
                items: resp.items,
                reset
            });
            commit('setPagination', resp.pagination);
            commit('setFilter', filter);
        },


        async GetAnalyzeCategoryGoodWords(pr, {
            pagination,
            analyzeCategoryId
        } = {}) {


            const resp = await LeadsAPIInstance.AnalyzeCategory
                .goodWords(
                    analyzeCategoryId,
                    pagination,
                );
            return resp;
        },

        async GetAnalyzeCategoryBadWords(pr, {
            pagination,
            analyzeCategoryId
        } = {}) {

            const resp = await LeadsAPIInstance.AnalyzeCategory
                .badWords(
                    analyzeCategoryId,
                    pagination,
                );
            return resp;
        },


        async CreateNewAnalyzeCategory({
            dispatch
        }, {
            from,
            newAnalyzeCategory
        }) {

            const analyzeCategory = await LeadsAPIInstance
                .AnalyzeCategory
                .create(newAnalyzeCategory);

            dispatch("GetAnalyzeCategoriesList");

            GUI.scene.add(from, LeadsAnalyzeCategoryDetails, {
                analyzeCategoryId: analyzeCategory.id
            });
        },


        async GetAnalyzeCategory({
            commit,
            state,
            getters
        }, {
            id,
            refresh
        }) {
            switch (true) {

                // when service loading in progress
                // -> just wait 
                case state.loadingMap.get(id) === 'LOADING': {
                    await new Promise((resolve) => {
                        let i = 0;

                        const handler = () => {
                            if (state.loadingMap.get(id) === 'COMPLETED') {
                                return resolve()
                            } else
                                setTimeout(handler, ++i + 100);
                        }

                        setTimeout(handler, 100);
                    });


                    break;
                }

                // when service loaded and Cache Exists
                // -> return existed cache 
                case state.loadingMap.get(id) === 'COMPLETED' && !refresh: {
                    break;
                }

                // when service haven't been ever loaded
                //   OR
                // when service loaded but cache requires refresh
                default: {
                    state.loadingMap.set(id, 'LOADING');

                    const service = await LeadsAPIInstance
                        .AnalyzeCategory
                        .get(id);

                    commit('setAnalyzeCategory', service)

                    state.loadingMap.set(id, 'COMPLETED');

                    break;
                }

            }

            return getters.analyzeCategoryById(id);

        },
        async UpdateAnalyzeCategory({
            state,
            commit,
            dispatch
        }, {
            analyzeCategory
        }) {

            if (state.analyzeCategoryUpdateTimeout)
                clearTimeout(state.analyzeCategoryUpdateTimeout)

            state.analyzeCategoryUpdateTimeout = setTimeout(async () => {
                commit('setAnalyzeCategory', analyzeCategory);

                await LeadsAPIInstance
                    .AnalyzeCategory
                    .update(analyzeCategory, {
                        toast: {
                            message: "AnalyzeCategory Updated",
                        },
                    });
                dispatch('GetAnalyzeCategoryHistory', {
                    id: analyzeCategory.id
                })

            }, 1000);
        },


        async CreateWord(prop, {
            analyzeCategoryId,
            word,
            type
        }) {
            await LeadsAPIInstance
                .AnalyzeCategory
                .createWord(analyzeCategoryId, type, word)
        },

        async RemoveWord(prop, {
            analyzeCategoryId,
            word,
            type
        }) {
            await LeadsAPIInstance
                .AnalyzeCategory
                .deleteWord(analyzeCategoryId, type, word)
        },
        async UpdateWord(prop, {
            analyzeCategoryId,
            word,
            type
        }) {
            await LeadsAPIInstance
                .AnalyzeCategory
                .updateWord(analyzeCategoryId, type, word)
        },


        async markAsInvalid({
            dispatch
        }, {
            analyzeCategory,
            badwords
        }) {

            await AnalyzeCategoriesAPIInstance.markAsInvalid(analyzeCategory, badwords, {
                toast: {
                    message: "AnalyzeCategory Marked as Invalid",
                },
            });
            setTimeout(() => {
                dispatch('GetAnalyzeCategoriesList', {
                    reset: true
                });
            }, 800);
        },

        async DeleteAnalyzeCategory({
            dispatch
        }, {
            analyzeCategory
        }) {
            await AnalyzeCategoriesAPIInstance.delete(analyzeCategory, {
                toast: {
                    message: "AnalyzeCategory Removed",
                },
            });

            dispatch('GetAnalyzeCategory', {
                id: analyzeCategory.id,
                refresh: true
            });
        },

        async DeleteAnalyzeCategoryAttachment({
            dispatch
        }, {
            analyzeCategory,
            file,
            type
        }) {
            await AnalyzeCategoriesAPIInstance.deleteAttachment(analyzeCategory, type, file, {
                toast: {
                    message: "Attachment Removed",
                },
            });

            dispatch('GetAnalyzeCategory', {
                id: analyzeCategory.id,
                refresh: true
            });
        },
    }
}
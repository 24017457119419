import {
    API
} from './Api.class';


export class ServiceTypesAPI extends API {


    async list(pagination, filter) {
        this.loading = true

        return await this.__axiosInstance.get('api/v1/service-types', {
                params: {
                    page: pagination.page,
                    pageSize: pagination.pageSize,
                    search: filter.search
                }
            })
    }
}
import {
    API
} from '../Api.class';


export class ProposalAPI extends API {

    constructor(baseUrl) {
        super(baseUrl)

    }


    async list(pagination, filter, meta) {
        this.loading = true

        return await this.__axiosInstance.get('api/v1/doc-assistant/proposals', {
            meta,
            params: {
                ...pagination,
                ...filter
            }
        })

    }

    async get(id, meta) {
        this.loading = true

        return await this.__axiosInstance.get(`api/v1/doc-assistant/proposals/${id}`, {
            meta
        });

    }


    async create(body, meta) {
        this.loading = true;

        return await this.__axiosInstance.post('api/v1/doc-assistant/proposals', body, {
            meta
        });
    }


    async update(proposal, meta) {
        this.loading = true

        return await this.__axiosInstance.put(`api/v1/doc-assistant/proposals/${proposal.id}`, proposal, {
            meta
        });
    }

    async delete(proposal, meta) {
        this.loading = true

        return await this.__axiosInstance.delete(`api/v1/doc-assistant/proposals/${proposal.id}`, {
            meta
        });

    }

}
<template>
  <v-row class="breadcrumbs">
    <v-col cols="12" class="breadcrumbs-col d-flex justify-center pa-0 ma-0">
      <v-hover v-slot="{ hover }">
        <div class="navigation">
          <v-btn icon large dark>
            <v-icon large> mdi-dots-horizontal </v-icon></v-btn
          >
          <div
            class="breadcrumbs-container"
            :class="{
              hover,
            }"
          >
            <v-row class="justify-center active-row">
              <v-btn
                icon
                color="white"
                :disabled="value.used ? activeIndex === 0 : true"
                @click="
                  () => $emit('onBreadcrumb', value.used[activeIndex - 1])
                "
              >
                <v-icon :size="40">mdi-chevron-left</v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <span class="active white--text">
                {{
                  value.active && value.active.name
                    ? value.active.name.toUpperCase()
                    : ""
                }}
              </span>
              <v-spacer></v-spacer>
              <v-btn
                icon
                :disabled="
                  value.used ? activeIndex === value.used.length - 1 : true
                "
                @click="
                  () => $emit('onBreadcrumb', value.used[activeIndex + 1])
                "
              >
                <v-icon color="white" :size="40">mdi-chevron-right</v-icon>
              </v-btn>
            </v-row>

            <!-- <li
                  class="breadcrumb"
                  v-for="breadcrumb in value.used.slice(0, 5)"
                  :key="breadcrumb.id"
                >
                  <v-btn
                    class="breadcrumb-btn"
                    @click="() => $emit('onBreadcrumb', breadcrumb)"
                    :color="
                      value.active.id === breadcrumb.id ? 'primary' : 'primary'
                    "
                  >
                    <span class="mr-5 ml-5">
                      {{ breadcrumb.name.toUpperCase() }}
                    </span>
                  </v-btn> -->
            <!-- </li> -->
          </div>
        </div>
      </v-hover>
    </v-col>
  </v-row>
</template>


<script>
import { mapState } from "vuex";
export default {
  name: "Breadcrumbs",
  props: {
    value: {
      default: () => ({}),
    },
  },
  data() {
    return {
      menu: false,
    };
  },
  computed: {
    ...mapState("MainStore", ["user"]),
    activeIndex: function () {
      console.log(
        "activeIndex: ",
        this.value.findIndex && this.value.active
          ? this.value.findIndex(this.value.active)
          : -1
      );
      return this.value.findIndex && this.value.active
        ? this.value.findIndex(this.value.active)
        : -1;
    },
  },
  watch: {
    // value: {
    //   handler(newVal) {
    //     console.log("=======================================newVal: ", newVal);
    //   },
    //   deep: true,
    // },
  },
};
</script>


<style lang="scss" scoped>
.breadcrumbs {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 40px;
  z-index: 9;

  .active {
    color: white;
    font-size: 1.2rem;
    margin: 0 auto;
    text-align: center;
    vertical-align: bottom;
    margin-top: 4px;
  }

  .breadcrumbs-col {
    z-index: 9;
  }

  .navigation {
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    align-items: center;
  }
  .breadcrumbs-container {
    transform-origin: center top 0px;
    /* z-index: 7; */
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -200px);
    // transform: translate(-50%, 0);
    height: 80px;
    width: 350px;
    border-radius: 0px 0px 50px 50px;
    backdrop-filter: blur(10px);
    background: radial-gradient(
      circle,
      rgba(255, 255, 255, 0.1) 0%,
      rgba(255, 255, 255, 0.1) 50%,
      rgba(255, 255, 255, 0.1) 63%
    );
    border-radius: 20px;
    margin-top: -20px;
    z-index: 2;
    transition: all 0.3s ease-in-out;
    display: flex;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;

    .active-row {
      margin-top: 18px !important;
    }

    &.hover {
      transform: translate(-50%, 0);
    }

    & ul {
      height: 100%;
      align-items: center;
      display: flex;
      justify-content: center;
      justify-items: center;
      margin: 0 auto;
    }

    .breadcrumb {
      display: inline-block;
      height: 100%;
      clip-path: polygon(90% 0%, 100% 50%, 90% 100%, 0% 100%, 10% 50%, 0% 0%);
      display: flex;
      flex-direction: column;
      justify-items: center;
      justify-content: center;
      .breadcrumb-btn {
        font-size: 10px;
        height: 26px;
      }
    }
    .breadcrumb:first-child {
      clip-path: polygon(90% 0%, 100% 50%, 90% 100%, 0% 100%, 0% 50%, 0% 0%);
    }
    .breadcrumb:not(:first-child) {
      margin-left: -10px;
    }
  }
}
</style>
import {
    CustomerAPIInstance
} from "../../../../../components/api";
import {
    GraphUI
} from "../../../GrapUI.config.js";
import {
    CustomerDetails
} from "../app.gh.js";


// Create a new store instance.
export const CustomerStore = {
    namespaced: true,
    state() {
        return {
            activeCustomerId: undefined,
            displayedCustomers: new Map(),
            customer: {},
            customers: {},
            pagination: {
                page: 1,
                pageSize: 10,
            },
            filter: {
                search: "",
                typeId: "",
            },
        }
    },
    getters: {
        customer: (state) => (id) => {
            const customer = state.displayedCustomers.get(id)
            const response = customer ? customer : {
                user: {
                    profile: {

                    }
                }
            };

            return response;
        }
    },
    mutations: {
        setCustomer(state, customer) {
            state.displayedCustomers.set(customer.id, customer);
        },
        setActiveId(state, customerId) {
            state.activeCustomerId = customerId;
        },
        setCustomers(state, customers) {
            state.customers = customers;
        },
        setPagination(state, pagination) {
            state.pagination = pagination;
        },
        setFilter(state, filter) {
            state.filter = filter;
        }
    },
    actions: {
        SetActiveCustomer({
            commit
        }, {
            from,
            customer
        }) {

            GraphUI.scene.add(from, CustomerDetails, {
                customerId: customer.id,
            });

            commit('setActiveId', customer.id)
        },
        // Customers API 
        async GetCustomersList({
            commit,
            state
        }, pagination, filter) {
            const resp = await CustomerAPIInstance
                .list(
                    pagination ? pagination : state.pagination,
                    filter ? filter : state.filter);

            commit('setCustomers', resp.items);
            commit('setPagination', pagination);
            commit('setFilter', filter);
        },
        async CreateNewCustomer({
            dispatch
        }, {
            from,
            newCustomer
        }) {

            const customer = await CustomerAPIInstance
                .create(newCustomer);

            dispatch("GetCustomersList");

            GraphUI.scene.add(from, CustomerDetails, {
                customerId: customer.id
            });
        },
        async GetCustomer({
            commit
        }, id) {
            const customer = await CustomerAPIInstance
                .get(id);

            commit('setCustomer', customer)
            return customer;
        },

    }
}
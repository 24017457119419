import {
    API
} from '../Api.class';
import {
    TermsAPI
} from './Terms.api';


export class GlossaryAPI extends API {

    constructor(baseUrl) {
        super(baseUrl)

        this.Terms = new TermsAPI(baseUrl);
    }

}
import Vue from "vue";
import {
    DocAssistantAPInstance
} from "../../../../../components/api";
import {
    GUI
} from "../../../GrapUI.config.js";
import {
    ProposalAssistant,
} from "../app.gh.js";


export const ProposalsStore = {
    namespaced: true,
    state() {
        return {
            proposals: {},

            // Timeouts
            updateTimeout: undefined,
            loadingMap: new Map()
        }
    },

    mutations: {
        setProposal(state, {
            proposal
        }) {
            Vue.set(state.proposals, proposal.id, proposal)
        },
        setActiveId(state, proposalId) {
            state.activeProposalId = proposalId;
        }
    },
    actions: {
        openProposalAssistant(prop, {
            from,
            lead,
            proposal
        }) {
            const fromParams = from.relation && from.relation.params ? {
                ...from.relation.params
            } : undefined


            if (fromParams && fromParams.fromParams)
                delete fromParams.fromParams

            GUI.scene.add(from, ProposalAssistant, {
                leadId: lead ? lead.id : undefined,
                proposalId: proposal ? proposal.id : undefined,
                fromParams
            });

        },
        async CreateNewProposal({
            dispatch,
            commit
        }, {
            newProposal
        }) {
            const proposal = await DocAssistantAPInstance
                .Proposals
                .create(newProposal, {
                    toast: {
                        message: "Proposal Created",
                    },
                });

            commit('setProposal', {
                proposal
            })

            if (newProposal.lead_id)
                dispatch('LeadStore/GetLead', {
                    id: newProposal.lead_id,
                    refresh: true
                }, {
                    root: true
                });


            return proposal;
        },
        async GetProposal({
            commit,
            state,
        }, {
            id,
            refresh
        }) {

            switch (true) {

                // when  loading in progress
                // -> just wait 
                case state.loadingMap.get(id) === 'LOADING': {
                    await new Promise((resolve) => {
                        let i = 0;

                        const handler = () => {
                            if (state.loadingMap.get(id) === 'COMPLETED') {
                                return resolve()
                            } else
                                setTimeout(handler, ++i + 100);
                        }

                        setTimeout(handler, 100);
                    });


                    break;
                }

                // when loaded and Cache Exists
                // -> return existed cache 
                case state.loadingMap.get(id) === 'COMPLETED' && !refresh: {
                    return state.proposals[id];
                }

                // when  haven't been ever loaded
                //   OR
                // when  loaded but cache requires refresh
                default: {
                    state.loadingMap.set(id, 'LOADING');
                    const proposal = await DocAssistantAPInstance
                        .Proposals
                        .get(id);

                    commit('setProposal', {
                        proposal
                    })

                    state.loadingMap.set(id, 'COMPLETED');
                    return proposal;

                }
            }
        },

        async UpdateProposal({
            state,
            commit
        }, {
            proposal,
            refresh = true
        }) {
            if (state.updateTimeout)
                clearTimeout(state.updateTimeout)

            state.updateTimeout = setTimeout(async () => {

                const updatedProposal = await DocAssistantAPInstance
                    .Proposals
                    .update(proposal, {
                        toast: {
                            message: "Proposal Updated",
                        },
                    });



                if (refresh)
                    commit('setProposal', {
                        proposal: updatedProposal
                    });

            }, 1000);

        },

        async DeleteProposal({
            dispatch
        }, proposal) {
            await DocAssistantAPInstance
                .Proposals
                .delete(proposal, {
                    toast: {
                        message: "Proposal Updated",
                    },
                });

            dispatch('GetProposal', {
                id: proposal.id,
                refresh: true
            });
        },

    }
}
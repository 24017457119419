//|##########################################################|
//|                      GraphUI V2.0.0                      |
//|----------------------------------------------------------|
//|                © Copyright 2022 adaas.org                |
//|                All rights reserved ADAAS                 |
//|----------------------------------------------------------|
//| Created At: 11.16.2022                                   |
//| Created By: Andrew Tsariuk (andrew.t@adaas.org)          |
//|__________________________________________________________|
//|###########################################################


/* eslint-disable no-unused-vars */
import {
    ViewGH
} from "./entities/View.gh.js";

/* eslint-enable no-unused-vars */


/**
 * @typedef {Object} GraphUIWeightConfig
 * @property {number} multipliers
 * @property {number} multipliers.close - multiplier that will be applied to the weight on closed operation
 * @property {number} multipliers.activate - multiplier that will be applied to the weight on activate operation
 * @property {number} multipliers.focus - multiplier that will be applied to the weight on focus operation
 * @property {number} multipliers.expand - multiplier that will be applied to the weight on expand operation
 * @property {number} multipliers.collapse - multiplier that will be applied to the weight on collapse operation
 * @property {number} multipliers.maximize - multiplier that will be applied to the weight on maximize operation
 * @property {number} multipliers.add - multiplier that will be applied to the weight on Add operation
 * 
 * 
 * 
 * @typedef {Object} GraphUIDrawConfig
 * @property {number} drawDelay - the delay on draw operations
 * @property {number} removeDelay - the delay on remove operations
 * @property {number} replaceDelay - the delay on replace operations
 * @property {number} adoptDelay - the delay on adopt operations
 * 
 * 
 * 
 * @typedef {Object} GraphUIConfigProps
 * @property {'web'|'neo4j'} driver - the driver to store Relations/GraphDB  
 * @property {Boolean} debug - will turn on advanced logging   
 * @property {number} cols - will turn on advanced logging   
 * @property {string} container - the container ID. Will be used to calculate height and elements positions
 * @property {GraphUIWeightConfig} weightsConfig 
 * @property {GraphUIDrawConfig} drawConfig 
 * @property {ViewGH} emptyView - a view that will be used as a default in case of height offset 
 * @property {string[]} globalPermissions - a set of permissions that are not related to any view or app
 * 
 * 
 * 
 * 
 */
export class GraphUIConfigManager {


    /**
     * This class is responsible for the config parsing and preparation. 
     * It stores all parameters and configurations that was provided to GraphUI
     * 
     * @param {GraphUIConfigProps} props
     */
    constructor(props = {}) {

        //TODO: add config parsing 
        this.props = props

        /**
         * the main container/desktop where GraphUI should be rendered
         * 
         * @type {string}
         */
        this.container = props.container || 'desktop';

        /**
         * The number of cols to display
         * 
         * @type {number}
         */
        this.cols = props.cols || 3;

        /**
         * Advanced Logs flag
         * 
         * @type {Boolean}
         */
        this.debug = props.debug || false

        /**
         * The config that will be used by Renderer to draw items in a proper way
         * 
         * @type {GraphUIDrawConfig}
         */
        this.drawConfig = props.drawConfig || {
            drawDelay: 1200,
            removeDelay: 1200,
            replaceDelay: 1200,
        }

        /**
         * The config that will be used to adjust a relation weight in a proper way
         * 
         * @type {GraphUIWeightConfig}
         */
        this.weightConfig = props.weightsConfig || {
            add: 1.2
        }


        /**
         * 
         * This is a default view that will be used for offset height
         * 
         * @type {ViewGH}
         */
        this.emptyView = props.emptyView || new ViewGH('EMPTY_GRAPH_UI_COMPONENT', {
            display: {}
        })

        /**
         * 
         * This is a default view that will be used for offset height
         * 
         * @type {ViewGH}
         */
        this.addView = props.emptyView || new ViewGH('ADD_GRAPH_UI_COMPONENT', {
            display: {
                constHeight: 60
            }
        })


        /**
         * Describes what driver should be used
         * @type {'web'|'neo4j'} 
         */
        this.driver = props.driver || 'web'


        /**
         * a set of global permissions
         * @type {string[]}
         */
        this.globalPermissions = props.globalPermissions && props.globalPermissions.length ? props.globalPermissions : [];

    }

    /**
     * Will rewrite config based on received options 
     * 
     *@param {GraphUIConfigProps} props
     *@returns {GraphUIConfigManager}
     */
    init(props) {

        this.drawConfig = props.drawConfig || this.drawConfig;
        this.debug = props.debug || this.debug;
        this.cols = props.cols || this.cols;
        this.globalPermissions = props.globalPermissions && props.globalPermissions.length ? props.globalPermissions : [];

        return this;
    }

}
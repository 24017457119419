import {
    API
} from '../Api.class';


export class TermsAPI extends API {

    constructor(baseUrl) {
        super(baseUrl)

    }


    async list(pagination, filter, meta) {
        this.loading = true

        return await this.__axiosInstance.get('api/v1/glossary/terms', {
            meta,
            params: {
                ...pagination,
                ...filter
            }
        })

    }

    async letters(meta) {
        this.loading = true

        return await this.__axiosInstance.get('api/v1/glossary/terms/letters', {
            meta,
        })

    }

    async get(id, meta) {
        this.loading = true

        return await this.__axiosInstance.get(`api/v1/glossary/terms/${id}`, {
            meta
        });

    }


    async create(body, meta) {
        this.loading = true;

        return await this.__axiosInstance.post('api/v1/glossary/terms', body, {
            meta
        });
    }


    async update(term, meta) {
        this.loading = true

        return await this.__axiosInstance.put(`api/v1/glossary/terms/${term.id}`, term, {
            meta
        });
    }

    async delete(term, meta) {
        this.loading = true

        return await this.__axiosInstance.delete(`api/v1/glossary/terms/${term.id}`, {
            meta
        });
    }


    async createLink(term, body, meta) {
        this.loading = true;

        return await this.__axiosInstance.post(`api/v1/glossary/terms/${term.id}/links`, body, {
            meta
        });
    }

    async deleteLink(term, link, meta) {
        this.loading = true;

        return await this.__axiosInstance.delete(`api/v1/glossary/terms/${term.id}/links/${link.id}`, {
            meta
        });
    }

}
import {
    API
} from '../Api.class';


export class SlideAPI extends API {

    constructor(baseUrl) {
        super(baseUrl)

    }


    async list(pagination, filter, meta) {
        this.loading = true

        return await this.__axiosInstance.get('api/v1/doc-assistant/slides', {
            meta,
            params: {
                ...pagination,
                ...filter
            }
        })

    }

    async get(id, meta) {
        this.loading = true

        return await this.__axiosInstance.get(`api/v1/doc-assistant/slides/${id}`, {
            meta
        });

    }


    async create(formData, meta) {
        this.loading = true;

        return await this.__axiosInstance.post('api/v1/doc-assistant/slides', formData, {
            headers:{
                'Content-Type': 'multipart/form-data'
            },
            meta
        });
    }


    async update(slide, meta) {
        this.loading = true

        return await this.__axiosInstance.put(`api/v1/doc-assistant/slides/${slide.id}`, slide, {
            meta
        });
    }

    async delete(slide, meta) {
        this.loading = true

        return await this.__axiosInstance.delete(`api/v1/doc-assistant/slides/${slide.id}`, {
            meta
        });

    }

}
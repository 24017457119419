import {
    CommonHelper
} from './common.helper'

class AuthHelperClass {


    constructor() {

        this.id = 'adaas_sso';

        this.SSOUrl = process.env.VUE_APP_SSO_API;

        this.token = '';

        this.DEFAULT_TIMEOUT = 5000;

        this.EVENTS = {
            REQUEST: {
                SET_TOKEN: 'SET_TOKEN',
                GET_TOKEN: 'GET_TOKEN',
                SET_USER: 'SET_USER',
                GET_USER: 'GET_USER',
                INIT: 'INIT',
            },
            RESPONSE: {
                READY: 'READY',
                TOKEN_RECEIVED: 'TOKEN_RECEIVED',
                USER_RECEIVED: 'USER_RECEIVED',
            }
        }

        this.connected = false;
    }



    async getToken() {
        await this.init();

        this.channel.postMessage({
                message: this.EVENTS.REQUEST.GET_TOKEN,
            },
            this.SSOUrl
        );

        const response = await this.messageHandler(this.EVENTS.RESPONSE.TOKEN_RECEIVED)


        return response.token;
    }

    async setToken(token) {
        await this.init()

        this.token = token;

        this.channel.postMessage({
                message: this.EVENTS.REQUEST.SET_TOKEN,
                payload: {
                    token: token,

                }
            },
            this.SSOUrl
        );
    }

    async getUser() {
        await this.init();

        this.channel.postMessage({
                message: this.EVENTS.REQUEST.GET_USER,

            },
            this.SSOUrl
        );

        const response = await this.messageHandler(this.EVENTS.RESPONSE.USER_RECEIVED)

        let user;
        try {
            user = JSON.parse(response.user)
        } catch (error) {
            console.log('User Parse Error: ', error);
        }

        return user;
    }

    async setUser(user) {
        await this.init()

        this.user = user;

        this.channel.postMessage({
                message: this.EVENTS.REQUEST.SET_USER,
                payload: {
                    user: JSON.stringify(user),

                }
            },
            this.SSOUrl
        );
    }


    init() {
        if (this.initializationPromise)
            return this.initializationPromise;

        console.log('INIT')

        this.initializationPromise = new Promise((resolve, reject) => {

            // To save the link to FN to be removed after ready
            this.messageHandler(this.EVENTS.RESPONSE.READY)
                .then((resp) => {
                    this.connected = true;

                    console.log(`ADAAS SSO | Connection established.`)

                    return resolve(resp);
                })
                .catch(reject);

            const iterations = [
                1, 2, 3, 4, 5, 6, 7, 8, 9, 10
            ];

            (async () => {
                for (const iterator of iterations) {
                    if (this.connected)
                        return;


                    console.log(`${iterator} attempt to connect SSO...`)

                    try {
                        this.iFrame = document.getElementById(this.id);
                        this.channel = this.iFrame.contentWindow;

                        await CommonHelper.delay(200 * iterator)
                        this.channel.postMessage({
                                message: this.EVENTS.REQUEST.INIT,
                            },
                            this.SSOUrl
                        );
                    } catch (error) {
                        console.log('READ ERROR: ', error);
                        await CommonHelper.delay(200 * iterator)
                    }


                }
                reject(new Error('ADAAS SSO Failed To load. Too many requests'))
            })()

        })

        return this.initializationPromise;
    }


    messageHandler(targetMessage) {
        return new Promise((resolve, reject) => {
            const fnWrapper = (event) => {

                if (event.origin !== this.SSOUrl) return;

                if (event.data.message === targetMessage) {

                    window.removeEventListener('message', fnWrapper)
                    return resolve(event.data.payload);
                }

                setTimeout(() => {
                    return reject(new Error('ADAAS SSO Failed To load'));
                }, this.DEFAULT_TIMEOUT);
            };

            window.addEventListener(
                "message",
                fnWrapper,
                false
            );


            setTimeout(() => {
                window.removeEventListener('message', fnWrapper)
                return reject(new Error(`ADAAS SSO | Default Timeout error during message: ${targetMessage}`));

            }, this.DEFAULT_TIMEOUT);
        });

    }
}

export const AuthHelper = new AuthHelperClass()
import {
    KnowledgeBaseAPIInstance
} from "../../../../../components/api";
import {
    GUI
} from "../../../GrapUI.config.js";
import {
    BusinessDomainCreation,
    BusinessDomainDetails,
    BusinessDomainEditor,
    BusinessDomainList,
} from "../app.gh.js";


// Create a new store instance.
export const BusinessDomainStore = {
    namespaced: true,
    state() {
        return {
            activeBusinessDomainId: undefined,
            displayedBusinessDomains: [],
            businessDomain: {},
            businessDomains: [],
            letters: [],
            pagination: {
                page: 1,
                pageSize: 10,
            },
            filter: {
                search: "",
                typeId: "",
            },
            // Timeouts
            updateTimeout: undefined,
            loadingMap: new Map()
        }
    },
    getters: {
        businessDomainById: (state) => (id) => {

            const index = state.displayedBusinessDomains.findIndex(s => s.id === id)

            if (index !== -1)
                return state.displayedBusinessDomains[index]
            else
                return {
                    id,
                    searchableKeyword: {},
                }

        }
    },
    mutations: {
        setBusinessDomain(state, businessDomain) {
            let index = state.displayedBusinessDomains.findIndex(s => s.id === businessDomain.id)
            let listIndex = state.businessDomains.findIndex(s => s.id === businessDomain.id)

            if (index !== -1) {
                if (JSON.stringify(businessDomain) === JSON.stringify(state.displayedBusinessDomains[index]))
                    return;
                else
                    state.displayedBusinessDomains.splice(index, 1, businessDomain);
            } else
                state.displayedBusinessDomains.push(businessDomain);


            if (listIndex !== -1) {
                state.businessDomains.splice(listIndex, 1, businessDomain)
            }

        },
        setActiveId(state, businessDomainId) {
            state.activeBusinessDomainId = businessDomainId;
        },
        setBusinessDomains(state, {
            items,
            reset
        } = {}) {
            if (reset)
                state.businessDomains = items;

            else
                items.forEach(businessDomain => {
                    const businessDomainIndex = state.businessDomains.findIndex(cs => cs.id === businessDomain.id);

                    if (businessDomainIndex !== -1)
                        state.businessDomains.splice(businessDomainIndex, 1, businessDomain)
                    else
                        state.businessDomains.push(businessDomain)
                });
        },
        setLetters(state, letters) {
            state.letters = letters;
        },
        setPagination(state, pagination) {
            state.pagination = pagination;
        },
        setFilter(state, filter) {
            state.filter = filter;
        }
    },
    actions: {
        openBusinessDomainList(props, {
            from,
        }) {
            GUI.scene.add(from, BusinessDomainList);
        },
        openBusinessDomainCreation(props, {
            from,
        }) {
            GUI.scene.add(from, BusinessDomainCreation);
        },
        openBusinessDomainDetails(props, {
            from,
            businessDomain
        }) {
            GUI.scene.add(from, BusinessDomainDetails, {
                businessDomainId: businessDomain.id,
            });
        },
        openBusinessDomainGeneralEditor(props, {
            from,
            businessDomain
        }) {

            GUI.scene.add(from, BusinessDomainEditor, {
                businessDomainId: businessDomain.id,
            });
        },
        openBusinessDomainSettingsEditor(props, {
            from,
            businessDomain
        }) {

            GUI.scene.add(from, BusinessDomainEditor, {
                businessDomainId: businessDomain.id,
            });
        },

        SetActiveBusinessDomain({
            commit
        }, {
            from,
            businessDomain
        }) {

            GUI.scene.add(from, BusinessDomainDetails, {
                businessDomainId: businessDomain.id,
            });

            commit('setActiveId', businessDomain.id)
        },
        // BusinessDomains API 
        async GetBusinessDomainsList({
            commit,
            state
        }, {
            pagination,
            filter,
            reset,
            next
        } = {}) {
            if (reset) {
                pagination = pagination ? pagination : {}
                filter = filter ? filter : {}
            } else {
                pagination = pagination ? pagination : state.pagination
                filter = filter ? filter : state.filter
            }

            if (next)
                pagination.page++;


            const resp = await KnowledgeBaseAPIInstance
                .BusinessDomains
                .list(
                    pagination,
                    filter);

            commit('setBusinessDomains', {
                items: resp.items,
                reset
            });

            commit('setPagination', resp.pagination);
            commit('setFilter', filter);
        },
        async CreateNewBusinessDomain({
            dispatch
        }, {
            from,
            newBusinessDomain
        }) {

            const businessDomain = await KnowledgeBaseAPIInstance
                .BusinessDomains
                .create(newBusinessDomain, {
                    toast: {
                        message: "Domain Created",
                    },
                });

            dispatch("GetBusinessDomainsList", {
                reset: true
            });

            GUI.scene.add(from, BusinessDomainDetails, {
                businessDomainId: businessDomain.id
            });
        },
        async GetBusinessDomain({
            commit,
            state,
            getters
        }, {
            id,
            refresh
        }) {

            switch (true) {

                // when  loading in progress
                // -> just wait 
                case state.loadingMap.get(id) === 'LOADING': {
                    await new Promise((resolve) => {
                        let i = 0;

                        const handler = () => {
                            if (state.loadingMap.get(id) === 'COMPLETED') {
                                return resolve()
                            } else
                                setTimeout(handler, ++i + 100);
                        }

                        setTimeout(handler, 100);
                    });


                    break;
                }

                // when loaded and Cache Exists
                // -> return existed cache 
                case state.loadingMap.get(id) === 'COMPLETED' && !refresh: {
                    break;
                }

                // when  haven't been ever loaded
                //   OR
                // when  loaded but cache requires refresh
                default: {
                    state.loadingMap.set(id, 'LOADING');

                    const businessDomain = await KnowledgeBaseAPIInstance.BusinessDomains
                        .get(id);

                    commit('setBusinessDomain', businessDomain)

                    state.loadingMap.set(id, 'COMPLETED');

                    break;
                }
            }

            return getters.businessDomainById(id);
        },

        async UpdateBusinessDomain({
            state,
            commit
        }, {
            businessDomain
        }) {

            commit('setBusinessDomain', businessDomain);


            if (state.updateTimeout)
                clearTimeout(state.updateTimeout)

            state.updateTimeout = setTimeout(async () => {
                // commit('setBusinessDomain', businessDomain);

                await KnowledgeBaseAPIInstance.BusinessDomains.update(businessDomain, {
                    toast: {
                        message: "BusinessDomain Updated",
                    },
                });
                // commit('setBusinessDomain', updatedBusinessDomain)
            }, 1000);

        },

        async DeleteBusinessDomain({
            dispatch
        }, businessDomain) {
            await KnowledgeBaseAPIInstance.BusinessDomains.delete(businessDomain, {
                toast: {
                    message: "BusinessDomain Updated",
                },
            });

            dispatch('GetBusinessDomain', {
                id: businessDomain.id,
                refresh: true
            });
        },

    }
}
import {
    API
} from '../Api.class';
import {
    LinksAPI
} from './Links.api';
import {
    TagsAPI
} from './Tags.api';
import {
    CaseStudyAPI
} from './CaseStudy.api';
import {
    ExperienceNoteAPI
} from './ExperienceNote.api';
import {
    QuestionAPI
} from './Question.api';
import {
    BusinessDomainAPI
} from './BusinessDomains.api';


export class KnowledgeBaseAPI extends API {

    constructor(baseUrl) {
        super(baseUrl)

        this.Tags = new TagsAPI(baseUrl);
        this.Links = new LinksAPI(baseUrl);
        this.ExperienceNotes = new ExperienceNoteAPI(baseUrl);
        this.CaseStudies = new CaseStudyAPI(baseUrl);
        this.Questions = new QuestionAPI(baseUrl);
        this.BusinessDomains = new BusinessDomainAPI(baseUrl);
    }
}
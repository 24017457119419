import Vue from "vue";
import {
    FolderAPIInstance
} from "../../../../../components/api";
import {
    GUI
} from "../../../GrapUI.config.js";
import {
    FolderCreation,
    // FolderDetails,
    // FolderEditor,
    Folder,
} from "../app.gh.js";


// Create a new store instance.
export const FolderStore = {
    namespaced: true,
    state() {
        return {
            activeFolderId: undefined,
            folders: {},
            allowBulkOperations: false,
            selected: [],
            itemsMoveInProgress: false,
            showMenu: false,
            actionCandidate: undefined,
            x: 0,
            y: 0,

            // Timeouts
            updateTimeout: undefined,
            loadingMap: new Map()
        }
    },

    mutations: {
        setMenu(state, {
            event,
            candidate
        }) {
            if ((!(state.selected && state.selected.length) || state.selected && state.selected.length === 1))

                if (candidate) {
                    state.selected = [candidate];
                    state.allowBulkOperations = true
                } else if (!candidate) {
                state.selected = []
                state.allowBulkOperations = false;
            }

            state.showMenu = false;
            state.x = event.clientX;
            state.y = event.clientY;
            Vue.nextTick(() => {
                state.showMenu = true;
            });
        },
        setAllowBulkOperations(state) {
            state.allowBulkOperations = !state.allowBulkOperations
            state.selected = []
        },
        setSelected(state, {
            value,
            item
        }) {
            // If true then we need to add to the selection
            if (value) {
                state.selected.push(item);
            } else {
                // if false then we need remove from the selection
                const index = state.selected.findIndex(
                    (sel) =>
                    sel.id === item.id && item.u_type === sel.u_type
                );
                state.selected.splice(index, 1);
            }
        },
        setFolder(state, {
            folder,
            content
        }) {
            Vue.set(state.folders, folder.id, {
                folder,
                content
            })


        },
        setActiveId(state, folderId) {
            state.activeFolderId = folderId;
        }
    },
    actions: {
        openFolderList(props, {
            from,
        }) {
            GUI.scene.add(from, Folder);
        },
        openFolderCreation(props, {
            from,
        }) {
            const fromParams = from.relation && from.relation.params ? {
                ...from.relation.params
            } : undefined

            if (fromParams && fromParams.fromParams)
                delete fromParams.fromParams

            GUI.scene.add(from, FolderCreation, {
                fromParams
            });
        },
        openFolderDetails({
            commit
        }, {
            from,
            folder,
        }) {
            const fromParams = from.relation && from.relation.params ? {
                ...from.relation.params
            } : undefined


            if (fromParams && fromParams.fromParams)
                delete fromParams.fromParams

            GUI.scene.add(from, Folder, {
                parentId: folder ? folder.id : undefined,
                fromParams
            });

            commit('setActiveId', folder ? folder.id : undefined)
        },
        async CreateNewFolder({
            dispatch
        }, {
            newFolder
        }) {
            await FolderAPIInstance
                .create(newFolder, {
                    toast: {
                        message: "Folder Created",
                    },
                });

            if (newFolder.parent_id)
                dispatch('GetFolder', {
                    id: newFolder.parent_id,
                    refresh: true
                });
            else {
                dispatch('GetFolder', {
                    id: 'home',
                    refresh: true
                });
            }
        },
        async GetFolder({
            commit,
            state,
        }, {
            id,
            refresh,
            pagination,
            filter
        }) {

            if (!id)
                id = 'home';

            switch (true) {

                // when  loading in progress
                // -> just wait 
                case state.loadingMap.get(id) === 'LOADING': {
                    await new Promise((resolve) => {
                        let i = 0;

                        const handler = () => {
                            if (state.loadingMap.get(id) === 'COMPLETED') {
                                return resolve()
                            } else
                                setTimeout(handler, ++i + 100);
                        }

                        setTimeout(handler, 100);
                    });


                    break;
                }

                // when loaded and Cache Exists
                // -> return existed cache 
                case state.loadingMap.get(id) === 'COMPLETED' && !refresh: {
                    break;
                }

                // when  haven't been ever loaded
                //   OR
                // when  loaded but cache requires refresh
                default: {
                    state.loadingMap.set(id, 'LOADING');
                    const [folder, content] = await Promise.all([
                        FolderAPIInstance
                        .get(id),
                        FolderAPIInstance
                        .getFolderContent(id, pagination, filter)
                    ]);

                    console.log('folder, content: ', folder, content)

                    commit('setFolder', {
                        folder,
                        content
                    })

                    state.loadingMap.set(id, 'COMPLETED');

                    break;
                }
            }
        },

        async UpdateFolder({
            state,
            dispatch,
        }, {
            folder
        }) {
            if (state.updateTimeout)
                clearTimeout(state.updateTimeout)

            state.updateTimeout = setTimeout(async () => {
                // commit('setFolder', folder);

                await FolderAPIInstance.update(folder, {
                    toast: {
                        message: "Folder Updated",
                    },
                });
                dispatch('GetFolder', {
                    id: folder.id,
                    refresh: true
                });

                // commit('setFolder', updatedFolder)
            }, 1000);

        },

        async DeleteFolder({
            dispatch
        }, folder) {
            await FolderAPIInstance.delete(folder, {
                toast: {
                    message: "Folder Updated",
                },
            });

            dispatch('GetFolder', {
                id: folder.id,
                refresh: true
            });
        },
        initMoveItems({
            state,
            commit
        }) {
            state.itemsMoveInProgress = true

            commit('MainStore/setSystemAlert', {
                code: 'FOLDER_ITEMS_MOVE',
                type: 'info',
                message: 'Please select the destination Folder'
            }, {
                root: true
            });

        },

        closeMenu({
            state,
            commit
        }) {
            if (state.itemsMoveInProgress)
                setTimeout(() => {
                    state.itemsMoveInProgress = false;

                }, 200);

            state.selected = [];
            state.showMenu=false;
            // state.allowBulkOperations = false;

            commit('MainStore/setSystemAlert', undefined, {
                root: true
            });
        },

        async moveItems({
            state,
            dispatch
        }, {
            destination
        }) {
            await FolderAPIInstance.bulkMove({
                items: state.selected,
                destination_id: destination.id
            }, {
                toast: {
                    message: "Items moved",
                },
            });

            dispatch('closeMenu')

            for (const id in state.folders) {
                dispatch('GetFolder', {
                    id,
                    refresh: true
                });
            }
        },

        async removeItems({
            state,
            dispatch
        }) {
            await FolderAPIInstance.bulkRemove({
                items: state.selected
            }, {
                toast: {
                    message: "Items Removed",
                },
            });

            for (const id in state.folders) {
                dispatch('GetFolder', {
                    id,
                    refresh: true
                });
            }
        }
    }
}
import {
    AppGH
} from '../../../../../lib/graph-ui/corev2/entities/App.gh.js';
import {
    ViewGH
} from '../../../../../lib/graph-ui/corev2/entities/View.gh.js';
import {
    TAGS_PERMISSIONS
} from '../knowledge-base/constants/permissions.gh.js';
import {
    DOC_ASSISTANT_PERMISSIONS,
    SLIDES_GALLERY_PERMISSIONS,
    PRESENT_MASTER_PERMISSIONS
} from './constants/permissions.gh.js';


export const DocAssistantMain = new ViewGH('DOC_ASSISTANT_MAIN', {
    display: {
        icon: 'mdi-file-sign',
        // color: 'red',
        name: 'DocAssistant Home View',
        description: `Your personal assistant in corporate documents`
    },
    permissions: [
        DOC_ASSISTANT_PERMISSIONS.ALLOW_USE_DOC_ASSISTANT,
        DOC_ASSISTANT_PERMISSIONS.ALLOW_USE_PROPOSAL_ASSISTANT
    ],
    component: () => import('./views/DocAssistantMain.vue')
});

export const ProposalAssistant = new ViewGH('PROPOSAL_ASSISTANT', {
    display: {
        icon: 'mdi-chart-timeline',
        name: 'Proposal Assistant',
        description: `With this application you're able to generate a new proposal`

    },
    component: () => import('./views/ProposalAssistant.vue'),
    permissions: [
        DOC_ASSISTANT_PERMISSIONS.ALLOW_USE_PROPOSAL_ASSISTANT,
        PRESENT_MASTER_PERMISSIONS.ALLOW_USE_PRESENT_MASTER
    ]
});


export const SlidesGallery = new ViewGH('SLIDES_GALLERY', {
    display: {
        icon: 'mdi-presentation-play',
        name: 'Slides Gallery',
        description: `This is a set of slides that can be used for the presentation creation`

    },
    component: () => import('./views/SlidesGallery.vue'),
    permissions: [
        SLIDES_GALLERY_PERMISSIONS.ALLOW_USE_SLIDES_GALLERY,
        SLIDES_GALLERY_PERMISSIONS.ALLOW_UPLOAD_NEW_SLIDES,
    ]
});


export const PresentMaster = new ViewGH('PRESENT_MASTER', {
    display: {
        icon: 'mdi-gift-open-outline',
        name: 'Present Master',
        description: `With this application you're able to create a new presentation`

    },
    component: () => import('./views/PresentMaster.vue'),
    permissions: [
        PRESENT_MASTER_PERMISSIONS.ALLOW_USE_PRESENT_MASTER,
    ]
});

export const SlideUpload = new ViewGH('SLIDE_UPLOAD', {
    display: {
        icon: 'mdi-view-grid-plus',
        name: 'Upload New Slide',
        description: `You can Upload a new slide on this view`

    },
    component: () => import('./views/SlideUpload.vue'),
    permissions: [
        SLIDES_GALLERY_PERMISSIONS.ALLOW_USE_SLIDES_GALLERY,
        SLIDES_GALLERY_PERMISSIONS.ALLOW_UPLOAD_NEW_SLIDES,
        TAGS_PERMISSIONS.ALLOW_CREATE_TAG,
    ]
});





export const DocAssistantApp = new AppGH('DOC_ASSISTANT_APP', {
    display: {
        icon: 'mdi-file-sign',
        color: '#3397bf',
        name: 'Doc Assistant',
        description: 'Your Assistant in corporate documents creation',
    },
    main: [DocAssistantMain, SlidesGallery, PresentMaster, ProposalAssistant],
}).use([
    DocAssistantMain,
    ProposalAssistant,
    SlidesGallery,
    SlideUpload,
    PresentMaster
]);
import {
    AppGH
} from '../../../../../lib/graph-ui/corev2/entities/App.gh.js';
import {
    ViewGH
} from '../../../../../lib/graph-ui/corev2/entities/View.gh.js';
import {
    TAGS_PERMISSIONS
} from '../knowledge-base/constants/permissions.gh.js';
import {
    FOLDER_PERMISSIONS
} from './constants/permissions.gh.js';


export const Folder = new ViewGH('FOLDER', {
    display: {
        icon: 'mdi-folder-settings',
        // color: 'red',
        name: 'Files System',
        description: 'Your own file system in Adaas Portal'
    },
    permissions: [
        FOLDER_PERMISSIONS.ALLOW_USE_FOLDER,
        FOLDER_PERMISSIONS.ALLOW_CREATE_NEW_FOLDER
    ],
    component: () => import('./views/Folder.vue')
});

export const FolderCreation = new ViewGH('FOLDER_CREATION', {
    display: {
        icon: 'mdi-help-box',
        name: 'New Folder',
        description: 'A new form to create a Folder'

    },
    component: () => import('./views/FolderCreation.vue'),
    permissions: [
        TAGS_PERMISSIONS.ALLOW_CREATE_TAG,
        FOLDER_PERMISSIONS.ALLOW_CREATE_NEW_FOLDER
    ]
});

export const FolderFilePreview = new ViewGH('FOLDER_FILE_PREVIEW', {
    display: {
        icon: 'mdi-folder-play',
        name: 'File Preview',
        description: 'File Preview'

    },
    component: () => import('./views/FolderFilePreview.vue'),
    permissions: [
        TAGS_PERMISSIONS.ALLOW_CREATE_TAG,
        FOLDER_PERMISSIONS.ALLOW_CREATE_NEW_FOLDER
    ]
});


export const FolderApp = new AppGH('FOLDER_APP', {
    display: {
        icon: 'mdi-folder-settings',
        // color: 'red',
        name: 'Files System',
        description: 'Your own file system in Adaas Portal',
    },
    main: Folder,
}).use([
    Folder,
    FolderCreation,
    FolderFilePreview
]);
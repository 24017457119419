var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"desktop-wrapper"},[_c('fullscreen',{staticClass:"fullscreen",style:({
      '--bgImage': `url(${
        _vm.user && _vm.user.settings && _vm.user.settings.system_background
          ? _vm.user.settings.system_background
          : '/static/images/backgrounds/49.webp'
      })`,
    }),model:{value:(_vm.fullscreen),callback:function ($$v) {_vm.fullscreen=$$v},expression:"fullscreen"}},[_c('BreadcrumbsVue',{attrs:{"value":_vm.breadcrumbs},on:{"onBreadcrumb":_vm.onBreadcrumb}}),_c('v-icon',{staticClass:"maximize-window",attrs:{"color":"white"},on:{"click":function($event){_vm.fullscreen = !_vm.fullscreen}}},[_vm._v("mdi-window-maximize")]),_c('div',{staticClass:"desktop-container",attrs:{"id":"desktop"},on:{"mousedown":_vm.startDrag,"mousemove":_vm.doDrag}},[_c('TreeViewDesktop',{ref:"treeView",attrs:{"levels":_vm.levels},on:{"onActivate":_vm.onActivate,"onAdd":_vm.onAdd,"onMaximize":_vm.onMaximize,"onClose":_vm.onClose,"onExpand":_vm.onExpand,"onCollapse":_vm.onCollapse}})],1),_c('AppsBarVue',{attrs:{"value":_vm.apps},on:{"onAppSelected":_vm.onAppSelected,"onProfileOpen":function($event){_vm.drawer = true}}}),_c('v-navigation-drawer',{staticClass:"transparent profile",attrs:{"dark":"","right":"","temporary":"","width":"375","app":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('UserProfile',{attrs:{"value":_vm.user}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
import {
    AppGH
} from "../../../../../lib/graph-ui/corev2/entities/App.gh.js";
import {
    ViewGH
} from "../../../../../lib/graph-ui/corev2/entities/View.gh.js";
import {
    LEAD_ANALYZE_CATEGORIES_PERMISSIONS,
    LEAD_PERMISSIONS
} from './constants/permissions.gh.js'



export const LeadsBacklog = new ViewGH('LEADS_BACKLOG', {
    display: {
        icon: 'mdi-briefcase',
        name: 'Leads Backlog',
        description: 'This is a table of all new and unassign leads in the system'
    },
    component: () => import('./views/LeadsBacklog.vue'),
    permissions: [
        LEAD_PERMISSIONS.ALLOW_SEE_LEAD_LIST
    ]
});

export const LeadsBoard = new ViewGH('LEADS_BOARD', {
    display: {
        name: 'My Leads',
        icon: 'mdi-table',
        description: 'Board with leads assigned to me'
    },
    component: () => import('./views/LeadsBoard.vue'),
    permissions: [
        LEAD_PERMISSIONS.ALLOW_CREATE_LEAD,
        LEAD_PERMISSIONS.ALLOW_SEE_LEAD_LIST
    ]
});
export const LeadDetails = new ViewGH('LEAD_DETAILS', {
    display: {
        name: 'Lead Details',
        icon: 'mdi-table'
    },
    component: () => import('./views/LeadDetails.vue'),
    permissions: [
        LEAD_PERMISSIONS.ALLOW_EDIT_LEAD,
        LEAD_PERMISSIONS.ALLOW_SEE_LEAD_ATTACHMENTS,
        LEAD_PERMISSIONS.ALLOW_SEE_LEAD_HISTORY
    ]
});

export const LeadAttachments = new ViewGH('LEAD_ATTACHMENTS', {
    display: {
        name: 'Lead Attachments',
        icon: 'mdi-table'
    },
    component: () => import('./views/LeadAttachments.vue'),
    permissions: [
        LEAD_PERMISSIONS.ALLOW_EDIT_LEAD,
        LEAD_PERMISSIONS.ALLOW_SEE_LEAD_ATTACHMENTS

    ]
});

export const LeadCreation = new ViewGH('LEAD_CREATION', {
    display: {
        name: 'New Lead',
        icon: 'mdi-table-plus'
    },
    component: () => import('./views/LeadCreation.vue'),
    permissions: [
        LEAD_PERMISSIONS.ALLOW_CREATE_LEAD
    ]

});

export const LeadHistory = new ViewGH('LEAD_HISTORY', {
    display: {
        name: 'Lead History',
        icon: 'mdi-table'
    },
    component: () => import('./views/LeadHistory.vue'),
    permissions: [
        LEAD_PERMISSIONS.ALLOW_SEE_LEAD_HISTORY,
        LEAD_PERMISSIONS.ALLOW_CREATE_LEAD_HISTORY
    ]

});
export const LeadComments = new ViewGH('LEAD_COMMENTS', {
    display: {
        name: 'Lead Comments',
        icon: 'mdi-table'
    },
    permissions: [
        'LEADS_LIST_VIEW_ACCESS'
    ],
    component: () => import('./views/LeadComments.vue')
});


// ================ANALYZE CATEGORIES=====================
export const LeadsAnalyzeCategories = new ViewGH('LEADS_ANALYZE_CATEGORIES', {
    display: {
        icon: 'mdi-table-cog',
        name: 'Analyze Categories',
        description: 'This is a list of analyze categories'
    },
    component: () => import('./views/analyzeCategories/AnalyzeCategoriesList.vue'),
    permissions: [
        LEAD_ANALYZE_CATEGORIES_PERMISSIONS.ALLOW_SEE_ANALYZE_CATEGORIES_LIST,
        LEAD_ANALYZE_CATEGORIES_PERMISSIONS.ALLOW_CREATE_ANALYZE_CATEGORIES
    ]
});

export const LeadsAnalyzeCategoryCreation = new ViewGH('LEADS_ANALYZE_CATEGORY_CREATION', {
    display: {
        icon: 'mdi-table-cog',
        name: 'New Analyze Category',
        description: 'This is a new Analyze Category Creation form'
    },
    component: () => import('./views/analyzeCategories/AnalyzeCategoryCreation.vue'),
    permissions: [
        LEAD_ANALYZE_CATEGORIES_PERMISSIONS.ALLOW_CREATE_ANALYZE_CATEGORIES
    ]
});

export const LeadsAnalyzeCategoryDetails = new ViewGH('LEADS_ANALYZE_CATEGORY_DETAILS', {
    display: {
        icon: 'mdi-table-cog',
        name: 'Analyze Category',
        description: 'This is a view with category details'
    },
    component: () => import('./views/analyzeCategories/AnalyzeCategoryDetails.vue'),
    permissions: [
        LEAD_ANALYZE_CATEGORIES_PERMISSIONS.ALLOW_EDIT_ANALYZE_CATEGORIES
    ]
});



export const LeadManagement = new AppGH('LEADS_MANAGEMENT', {
        display: {
            icon: 'mdi-table',
            name: 'Leads',
            description: 'This app provide an ability to manage leads',
        },
        main: [
            LeadsBacklog,
            LeadsBoard,
            LeadsAnalyzeCategories
        ],
    })
    .use([
        LeadsBoard,
        LeadsBacklog,
        LeadDetails,
        LeadCreation,
        LeadHistory,
        LeadComments,
        LeadAttachments,
        LeadsAnalyzeCategories,
        LeadsAnalyzeCategoryCreation,
        LeadsAnalyzeCategoryDetails
    ])
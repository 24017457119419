/* eslint-disable no-unused-vars */
import {
    RenderingViewGH
} from "../render/RenderingView.gh.js";
import {
    ViewGH
} from "./View.gh.js";
/* eslint-enable no-unused-vars */


/**
 * @typedef {Object} SerializedRelationGH
 * @property {string} from - a key of from View
 * @property {string} to - a key of to View
 * @property {number} weight - a key of to View
 * @property {Object} params - a key of to View
 * 
 * 
 * @typedef {Object} RelationConstructorOptions
 * @property {number} weight - the weight of the relation, uses to track user behavior and to present a proper results. The height of the final Rendering View depends on this parameter.
 * @property {Object} params - a custom object of the entities that you want to store in the relation
 * 
 * 
 */
export class RelationGH {


    /**
     * THis class represents a relation and should be used to find associated Views.
     * In general this is wrapper on any Relation from any storage 
     *  
     * 
     * @param {ViewGH} from
     * @param {ViewGH} to
     * @param {RelationConstructorOptions} options
     * 
     */
    constructor(from, to, options) {

        /**
         * From what View relation begins
         * 
         * @type {ViewGH}
         */
        this.from = from;

        /**
         * The Destination View
         * 
         * @type {ViewGH}
         */
        this.to = to;


        /**
         * This is weight of the relation.
         * Uses to display a Rendering View in a proper way
         * 
         * 
         * @type {number}
         */
        this.weight = options.weight || 1;


        /**
         * This is a custom payload to store with relation. 
         * It might be userID, view display options, etc
         * 
         * @type {Object}
         */
        this.params = options.params;

    }

    /**
     * Will create a new Rendering View based on the parameters inside relations
     * @return {RenderingViewGH}
     */
    toRView() {
        return new RenderingViewGH(this);
    }


    /**
     * Should check is the received relation has the same props
     * 
     * TODO: add custom comparator
     * @param {RelationGH} relation 
     */
    isEqual(relation) {
        return this.from.key === relation.from.key&& this.to.key === relation.to.key && JSON.stringify(this.params) === JSON.stringify(relation.params);
    }



    /**
     * Uses to serialize object in a proper way for easy parsing and optimal storage.
     * Uses by default in JSON.stringify 
     * 
     * 
     * 
     * 
     * @returns {SerializedRelationGH}
     */
    toJSON() {
        return {
            weight: this.weight,
            from: this.from.key,
            to: this.to.key,
            params: this.params
        }
    }

}

export const LEAD_PERMISSIONS = {
    ALLOW_SEE_LEAD_LIST: 'ALLOW_SEE_LEAD_LIST',
    ALLOW_CREATE_LEAD: 'ALLOW_CREATE_LEAD',
    ALLOW_EDIT_LEAD: 'ALLOW_EDIT_LEAD',
    ALLOW_SEE_LEAD_ATTACHMENTS: 'ALLOW_SEE_LEAD_ATTACHMENTS',
    ALLOW_SEE_LEAD_HISTORY:'ALLOW_SEE_LEAD_HISTORY',
    ALLOW_CREATE_LEAD_HISTORY:'ALLOW_CREATE_LEAD_HISTORY',
}

export const LEAD_ANALYZE_CATEGORIES_PERMISSIONS = {
    ALLOW_SEE_ANALYZE_CATEGORIES_LIST: 'ALLOW_SEE_ANALYZE_CATEGORIES_LIST',
    ALLOW_CREATE_ANALYZE_CATEGORIES: 'ALLOW_CREATE_ANALYZE_CATEGORIES',
    ALLOW_EDIT_ANALYZE_CATEGORIES: 'ALLOW_EDIT_ANALYZE_CATEGORIES'
}
import {
    API
} from './Api.class';
import {
    PlansAPI
} from './Plans.api';
import {
    ServiceDeliverableAPI
} from './services/ServiceDeliverables.api';
import {
    ServiceSectionsAPI
} from './services/ServiceSections.api';
import {
    UseCaseAPI
} from './services/UseCases.api';
import {
    ServiceTypesAPI
} from './ServiceTypes.api';


export class ServiceAPI extends API {

    constructor(baseUrl) {
        super(baseUrl)

        this.TypesApi = new ServiceTypesAPI(baseUrl);
        this.PlansAPI = new PlansAPI(baseUrl);
        this.UseCasesAPI = new UseCaseAPI(baseUrl);
        this.DeliverablesAPI = new ServiceDeliverableAPI(baseUrl);
        this.SectionsAPI = new ServiceSectionsAPI(baseUrl);
    }


    async list(pagination, filter, meta) {
        this.loading = true

        return await this.__axiosInstance.get('api/v1/services', {
            meta,
            params: {
                ...pagination,
                ...filter
            }
        })

    }

    async get(id, meta) {
        this.loading = true

        return await this.__axiosInstance.get(`api/v1/services/${id}`, {
            meta
        });

    }


    async create(body, meta) {
        this.loading = true;

        return await this.__axiosInstance.post('api/v1/services', body, {
            meta
        });
    }


    async update(service, meta) {
        this.loading = true

        return await this.__axiosInstance.put(`api/v1/services/${service.id}`, service, {
            meta
        });
    }

    async updateOverview(service, meta) {
        this.loading = true

        const body = {
            overview: service.overview,
            idea: service.fitRate.idea,
            startup: service.fitRate.startup,
            product: service.fitRate.product,
            enterprise: service.enterprise
        }

        return await this.__axiosInstance.put(`api/v1/services/${service.id}/sections/overview`, body, {
            meta
        });
    }


    async updateFreeSection(serviceId, freeSection, {
        meta
    }) {
        this.loading = true

        const body = {
            name: freeSection.name,
        }

        return await this.__axiosInstance.put(`api/v1/services/${serviceId}/sections/free-sections/${freeSection.id}`, body, {
            meta
        });
    }


    async statuses(pagination, filter, meta) {
        this.loading = true

        return await this.__axiosInstance.get('api/v1/service-statuses', {
            meta,
            params: {
                ...pagination,
                ...filter
            }
        })
    }


}
// import _ from 'lodash';
/* eslint-disable no-unused-vars */

import {
    RenderingLevelGH
} from "../RenderingLevel.gh.js";
import {
    RenderingViewGH
} from "../RenderingView.gh.js";
import {
    BreadcrumbGH
} from "./Breadcrumb.js";

/* eslint-enable no-unused-vars */

export class BreadcrumbsGH {


    /**
     * This is container for existing breadcrumbs 
     * This class provides methods over all breadcrumbs and control on them
     * 
     * 
     */
    constructor() {

        /**
         * 
         * Shows current active breadcrumb
         * @type {BreadcrumbGH}
         */
        this.active;

        /**
         * This is a set of used breadcrumbs
         * It should contain all activated breadcrumbs
         * 
         * @type {BreadcrumbGH[]}
         * 
         */
        this.used = []
    }



    //==========================PUBLIC METHODS===========================

    /**
     * 
     * @param {RenderingViewGH | RenderingLevelGH | BreadcrumbGH} target 
     * @param {RenderingLevelGH[]} levels 
     */
    activate(target, levels) {

        const usedBreadcrumbIndex = this.findIndex(target);
        const activeUsedIndex = this.findIndex(this.active);

        console.log('this.used: ', this.used)
        console.log('target: ', target)
        console.log('usedBreadcrumbIndex: ', usedBreadcrumbIndex)
        console.log('activeUsedIndex: ', activeUsedIndex)

        const newBreadcrumb = new BreadcrumbGH(
                target instanceof BreadcrumbGH ?
                (target.view || target.level) :
                target,



                levels)
            .increment(this.active);

        this.active = newBreadcrumb;

        if (usedBreadcrumbIndex !== -1)
            this.used.splice(usedBreadcrumbIndex, 1, newBreadcrumb)
        else
            this.used.splice(activeUsedIndex + 1, this.used.length, newBreadcrumb)

        return this.active;
    }


    /**
     * Should find an Index of provided breadcrumb in used.
     * If not found will return -1
     * 
     * @param {BreadcrumbGH} breadcrumb 
     * @returns {number}
     */
    findIndex(breadcrumb = {}) {
        let foundIndex = -1;

        for (let i = 0; i < this.used.length; i++) {

            if (this.used[i].isEqual(breadcrumb)) {
                foundIndex = i;
                break;
            }
        }

        return foundIndex;
    }

    /**
     * Should reset breadcrumbs state to the initial
     * 
     * @returns {BreadcrumbsGH}
     */
    reset() {
        this.used = [];
        this.active && this.active.reset();
        return this;
    }




    //==========================PRIVATE METHODS===========================

    /**
     * Will remove all elements from used Breadcrumbs starting from provided index
     * 
     * @private
     * @param {number} usedBreadcrumbIndex 
     */
    __clearUnused(usedBreadcrumbIndex) {
        this.used.splice(usedBreadcrumbIndex + 1, this.used.length);

        console.log('this.used: ', this.used)
    }

}
import {
    AppGH
} from '../../../../../lib/graph-ui/corev2/entities/App.gh.js';
import {
    ViewGH
} from '../../../../../lib/graph-ui/corev2/entities/View.gh.js';


export const YoutubePlayer = new ViewGH('YOUTUBE_PLAYER', {
    display: {
        icon: 'mdi-youtube',
        color: 'red',
        name: 'Youtube',
        description: 'Built in Youtube player'
    },
    component: () => import('./views/YoutubePlayer.vue')
});


export const Youtube = new AppGH('YOUTUBE', {
    display: {
        icon: 'mdi-youtube',
        color: 'red',
        name: 'Youtube Player',
        description: 'Built in Youtube player',
    },
    main: YoutubePlayer,
}).use([
    YoutubePlayer,
]);
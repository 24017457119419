import {
    ServiceAPIInstance
} from "../../../../../components/api";
import {
    GUI
} from "../../../GrapUI.config.js";
import {
    ServiceCreation,
    ServiceDeliverablesEditor,
    ServiceDetails,
    ServiceFreeSectionEditor,
    ServiceHeaderEditor,
    ServiceOverviewEditor,
    ServicePlansEditor,
    ServiceSettingsEditor,
    ServicesList,
    ServiceUseCasesEditor
} from "../app.gh.js";


// Create a new store instance.
export const ServiceStore = {
    namespaced: true,
    state() {
        return {
            activeServiceId: undefined,
            displayedServices: [],
            service: {},
            services: [],
            activeSectionId: 'head',
            pagination: {
                page: 1,
                pageSize: 10,
            },
            filter: {
                search: "",
                typeId: "",
            },

            // Timeouts
            serviceUpdateTimeout: undefined,
            loadingMap: new Map()
        }
    },
    getters: {
        serviceById: (state) => (id) => {
            const index = state.displayedServices.findIndex(s => s.id === id)

            if (index !== -1)
                return state.displayedServices[index];

            else
                return {
                    id,
                    sections: []
                }

        }
    },
    mutations: {
        setService(state, service) {
            let index = state.displayedServices.findIndex(s => s.id === service.id)
            let listIndex = state.services.findIndex(s => s.id === service.id)

            if (index !== -1)
                state.displayedServices.splice(index, 1, service);
            else
                index = state.displayedServices.push(service);


            if (listIndex !== -1) {
                state.services.splice(listIndex, 1, service)
            }

        },
        setActiveId(state, serviceId) {
            state.activeServiceId = serviceId;
        },
        setActiveSectionId(state, activeSectionId) {
            state.activeSectionId = activeSectionId;
        },
        setServices(state, {
            items,
            reset
        } = {}) {
            if (reset)
                state.services = items;

            else
                items.forEach(service => {
                    const serviceIndex = state.services.findIndex(ser => ser.id === service.id);

                    if (serviceIndex !== -1)
                        state.services.splice(serviceIndex, 1, service)
                    else
                        state.services.push(service)
                });
        },
        setPagination(state, pagination) {
            state.pagination = pagination;
        },
        setFilter(state, filter) {
            state.filter = filter;
        }
    },
    actions: {
        //============PAGES==========================
        openServiceList(props, {
            from,
        }) {
            GUI.scene.add(from, ServicesList);
        },

        openServiceDetails(props, {
            from,
            service
        }) {
            GUI.scene.add(from, ServiceDetails, {
                serviceId: service.id,
                fromParams: from.relation && from.relation.params ? from.relation.params : undefined

            });
        },

        openServiceCreation(props, {
            from,
        }) {
            GUI.scene.add(from, ServiceCreation);
        },

        openServiceSettingsEditor(props, {
            from,
            service
        }) {
            GUI.scene.add(from, ServiceSettingsEditor, {
                serviceId: service.id,
            });
        },
        openServiceHeaderEditor(props, {
            from,
            service
        }) {
            GUI.scene.add(from, ServiceHeaderEditor, {
                serviceId: service.id,
            });
        },
        openServiceOverviewEditor(props, {
            from,
            service
        }) {
            GUI.scene.add(from, ServiceOverviewEditor, {
                serviceId: service.id,
            });
        },
        openServiceFreeSectionEditor(props, {
            from,
            service,
            section
        }) {
            GUI.scene.add(from, ServiceFreeSectionEditor, {
                serviceId: service.id,
                sectionId: section.id
            });
        },
        openServiceUseCaseEditor(props, {
            from,
            service
        }) {
            GUI.scene.add(from, ServiceUseCasesEditor, {
                serviceId: service.id,
            });
        },
        openServiceDeliverablesEditor(props, {
            from,
            service
        }) {
            GUI.scene.add(from, ServiceDeliverablesEditor, {
                serviceId: service.id,
            });
        },
        openServicePlansEditor(props, {
            from,
            service
        }) {
            GUI.scene.add(from, ServicePlansEditor, {
                serviceId: service.id,
            });
        },
        SetActiveService({
            commit
        }, {
            from,
            service
        }) {

            GUI.scene.add(from, ServiceDetails, {
                serviceId: service.id,
            });

            commit('setActiveId', service.id)
        },

        setActiveSection({
            commit
        }, {
            serviceId,
            section
        }) {
            commit('setActiveSectionId', undefined)

            setTimeout(() => {

                commit('setActiveSectionId', section)
            }, 0);
            commit('setActiveId', serviceId)
        },
        // Services API 
        async GetServicesList({
            commit,
            state
        }, {
            pagination,
            filter,
            reset,
            next
        } = {}) {
            if (reset) {
                pagination = pagination ? pagination : {}
                filter = filter ? filter : {}
            } else {
                pagination = pagination ? pagination : state.pagination
                filter = filter ? filter : state.filter
            }

            if (next)
                pagination.page++;


            const resp = await ServiceAPIInstance
                .list(
                    pagination,
                    filter);

            commit('setServices', {
                items: resp.items,
                reset
            });
            commit('setPagination', resp.pagination);
            commit('setFilter', filter);

        },
        async CreateNewService({
            dispatch
        }, {
            from,
            newService
        }) {

            const service = await ServiceAPIInstance
                .create(newService);

            dispatch("GetServicesList");

            GUI.scene.add(from, ServiceDetails, {
                serviceId: service.id
            });
        },
        async GetService({
            commit,
            state,
            getters
        }, {
            id,
            refresh
        }) {
            switch (true) {

                // when service loading in progress
                // -> just wait 
                case state.loadingMap.get(id) === 'LOADING': {
                    await new Promise((resolve) => {
                        let i = 0;

                        const handler = () => {
                            if (state.loadingMap.get(id) === 'COMPLETED') {
                                return resolve()
                            } else
                                setTimeout(handler, ++i + 100);
                        }

                        setTimeout(handler, 100);
                    });


                    break;
                }

                // when service loaded and Cache Exists
                // -> return existed cache 
                case state.loadingMap.get(id) === 'COMPLETED' && !refresh: {
                    break;
                }

                // when service haven't been ever loaded
                //   OR
                // when service loaded but cache requires refresh
                default: {
                    state.loadingMap.set(id, 'LOADING');

                    const service = await ServiceAPIInstance
                        .get(id);

                    commit('setService', service)

                    state.loadingMap.set(id, 'COMPLETED');

                    break;
                }

            }

            return getters.serviceById(id);
        },


        // =========++EDITORS ACTIONS==========

        //------------HEADER--------------
        async OpenEditor({
            commit
        }, {
            from,
            editor,
            props
        }) {
            let target;
            switch (true) {
                case editor === 'head':
                    target = ServiceHeaderEditor
                    break;

                case editor === 'overview':
                    target = ServiceOverviewEditor
                    break;

                case editor === 'deliverables':
                    target = ServiceDeliverablesEditor
                    break;

                case editor === 'pricing':
                    target = ServicePlansEditor
                    break;

                case editor === 'use_cases':
                    target = ServiceUseCasesEditor
                    break;
                case editor === 'free_section_':
                    target = ServiceFreeSectionEditor
                    break;

                default:
                    target = ServiceHeaderEditor

                    break;
            }

            GUI.scene.add(from, target, props);

            commit('setActiveSectionId', editor)

        },
        // ==============GENERAL===============
        async UpdateService({
            state,
            commit
        }, {
            service
        }) {

            if (state.serviceUpdateTimeout)
                clearTimeout(state.serviceUpdateTimeout)

            state.serviceUpdateTimeout = setTimeout(async () => {
                console.log('Timeout Update')

                commit('setService', service);

                await ServiceAPIInstance.update(service, {
                    toast: {
                        message: "Service Updated",
                    },
                });
            }, 1000);

        },

        // ==============FREE SECTION===============
        async onFreeSectionCreate({
            dispatch
        }, {
            service
        }) {
            await ServiceAPIInstance.SectionsAPI.create(
                service.id, {}, {
                    toast: {
                        message: "Section Created",
                    },
                }
            );

            dispatch('GetService', {
                id: service.id,
                refresh: true
            });
        },

        async onFreeSectionRemove({
            dispatch
        }, {
            service,
            section,
            viewToRemove
        }) {
            if (viewToRemove)
                await GUI.scene.remove(viewToRemove)

            await ServiceAPIInstance.SectionsAPI.delete(service.id, section, {
                toast: {
                    message: "Section Removed",
                },
            });

            dispatch('GetService', {
                id: service.id,
                refresh: true
            });
        },

        async onFreeSectionStepCreate({
            dispatch
        }, {
            service,
            freeSection
        }) {
            await ServiceAPIInstance.SectionsAPI.createFreeSectionStep(
                service.id,
                freeSection, {
                    toast: {
                        message: "Section Step Created",
                    },
                }
            );

            dispatch('GetService', {
                id: service.id,
                refresh: true
            });
        },

        async onFreeSectionStepRemove({
            dispatch
        }, {
            service,
            freeSection,
            step
        }) {
            await ServiceAPIInstance.SectionsAPI.deleteFreeSectionStep(
                service.id,
                freeSection,
                step, {
                    toast: {
                        message: "Section Step Removed",
                    },
                }
            );

            dispatch('GetService', {
                id: service.id,
                refresh: true
            });
        },

        // ==============USE CASES===============
        async onUseCaseCreate({
            dispatch
        }, {
            service
        }) {
            await ServiceAPIInstance.UseCasesAPI.create(
                service.id, {}, {
                    toast: {
                        message: "Use Case Created",
                    },
                }
            );

            dispatch('GetService', {
                id: service.id,
                refresh: true
            });
        },
        async onUseCaseRemove({
            dispatch
        }, {
            service,
            useCase
        }) {
            await ServiceAPIInstance.UseCasesAPI.delete(service.id, useCase, {
                toast: {
                    message: "Use Case Removed",
                },
            });

            dispatch('GetService', {
                id: service.id,
                refresh: true
            });
        },

        // ==============PRICING===============
        async onPlansUpdate({
            dispatch
        }, {
            service
        }) {
            if (service.plans && service.plans.length) {
                const promises = service.plans.map((plan, i, arr) =>
                    ServiceAPIInstance.PlansAPI
                    .update(plan.service_id, plan, {
                        toast: {
                            message: i === arr.length - 1 ? "Plans Updated" : undefined
                        }
                    })
                );

                await Promise.all(promises);

                dispatch('GetService', {
                    id: service.id,
                    refresh: true
                });
            }
        },
        async onPlanCreate({
            dispatch
        }, {
            service
        }) {

            await ServiceAPIInstance.PlansAPI.create(
                service.id, {}, {
                    toast: {
                        message: "Plan Created",
                    },
                }
            );

            dispatch('GetService', {
                id: service.id,
                refresh: true
            });
        },
        async onPlanRemove({
            dispatch
        }, {
            service,
            plan
        }) {
            await ServiceAPIInstance.PlansAPI.delete(service.id, plan, {
                toast: {
                    message: "Plan Removed",
                },
            });

            dispatch('GetService', {
                id: service.id,
                refresh: true
            });
        },
        async onPlanPackageItemCreate({
            dispatch
        }, {
            service,
            plan,
            items
        }) {
            const promises = items.map((del, i, arr) =>
                ServiceAPIInstance.PlansAPI.createPackageItem(service.id, plan.id, {
                    deliverableId: del.id,
                }, {
                    toast: {
                        message: i === arr.length - 1 ? "Deliverables Attached to Plan" : undefined
                    }
                })
            );
            await Promise.all(promises);

            dispatch('GetService', {
                id: service.id,
                refresh: true
            });
        },


        async onPlanPackageItemRemove({
            dispatch
        }, {
            service,
            plan,
            item
        }) {
            await ServiceAPIInstance.PlansAPI.deletePackageItem(service.id, plan.id, item, {
                toast: {
                    message: "Deliverable Detached",
                },
            });

            dispatch('GetService', {
                id: service.id,
                refresh: true
            });
        },
    }
}
//|##########################################################|
//|                      GraphUI V2.0.0                      |
//|----------------------------------------------------------|
//|                © Copyright 2022 adaas.org                |
//|                All rights reserved ADAAS                 |
//|----------------------------------------------------------|
//| Created At: 11.16.2022                                   |
//| Created By: Andrew Tsariuk (andrew.t@adaas.org)          |
//|__________________________________________________________|
//|###########################################################

import {
    CommonHelper
} from "../helpers/common.helper.js";
// import {
//     RendererManagerGH
// } from "./RendererManager.gh.js";
/* eslint-disable no-unused-vars */
import {
    RenderingLevelGH
} from "./RenderingLevel.gh.js";
import {
    RenderingViewGH
} from "./RenderingView.gh.js";
import {
    GraphUI
} from "../GraphUI.class.js";
import {
    BreadcrumbGH
} from "./breadcrumbs/Breadcrumb.js";
import {
    RendererHelper
} from "../helpers/renderer.helper.js";
import { RendererManagerGH_V2 } from "./RendererManager_v2.gh.js";
/* eslint-enable no-unused-vars */

CommonHelper

export class RendererGH {

    /**
     * This class is responsible for all operations over display and rendering
     * 
     * 
     * 
     * @param {GraphUI} graphUI
     */
    constructor(graphUI) {

        // this.manager = new RendererManagerGH(graphUI);
        this.manager = new RendererManagerGH_V2(graphUI);


        /**
         * Main class 
         * @private 
         * @type {GraphUI}
         */
        this.__graphUI = graphUI;

    }



    /**
     * Should draw new Items.
     * This method should transform the current level to destination 
     * 
     * @void
     * @param {BreadcrumbGH}  breadcrumb - the active element
     * @param {RenderingLevelGH}  level - the current level that displays 
     * @param {RenderingViewGH[]}  newViews - the destination value that level should be transformed 
     */
    async draw(breadcrumb, level, newViews) {
        //Get instructions 
        // const instructions = this.manager.getDrawInstructions(
        //     breadcrumb,
        //     level.views,
        //     newViews
        // )


        await this.manager.getDrawInstructions(
            breadcrumb,
            level,
            newViews
        )

        //draw using instructions 
        // await this.manager.drawByInstructions(instructions, level.views);
    }



    /**
     * Should calculate a heights for new views based on the previous level to get a proper grid
     * 
     * @param {BreadcrumbGH}  breadcrumb - the active element
     * @param {RenderingLevelGH}  prevLevel - previous level 
     * @param {RenderingViewGH[]} newViews - new views, received from Store
     * @returns {RenderingViewGH[]}
     */
    calcHeight(breadcrumb, prevLevel, newViews) {

        //get Active slice from prevArray

        const prevViews = this.manager.getDisplaySlice(breadcrumb, prevLevel.views)

        const updatedHeights = this.manager.calcHeight(prevViews, newViews);

        return updatedHeights;
    }

    /**
     * Will expand a height to maximum of the provided element
     * 
     * @param {RenderingViewGH} rView
     * @param {RenderingLevelGH} level
     * @returns {RendererGH}
     */
    activate(rView, level) {

        //TODO add a proper height calc
        rView.height = RendererHelper.getActualHeight();
        rView.width = level.width;

        return this;
    }
}
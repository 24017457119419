import {
    API
} from './Api.class';


export class PlansAPI extends API {

    constructor(baseUrl) {
        super(baseUrl)
    }


    async list(pagination, filter, serviceId, meta) {
        this.loading = true

        return await this.__axiosInstance.get(`api/v1/services/${serviceId}/plans`, {
            meta,
            params: {
                ...pagination,
                ...filter
            }
        })
    }

    async get(serviceId, id, meta) {
        this.loading = true
        return await this.__axiosInstance.get(`api/v1/services/${serviceId}/plans/${id}`, {
            meta
        });
    }


    async create(serviceId, plan = {}, meta) {
        return await this.__axiosInstance.post(`api/v1/services/${serviceId}/plans`, plan, {
            meta
        })
    }

    async update(serviceId, plan = {}, meta) {
        return await this.__axiosInstance.put(`api/v1/services/${serviceId}/plans/${plan.id}`, plan, {
            meta
        })
    }

    async delete(serviceId, plan, meta) {
        return await this.__axiosInstance.delete(`api/v1/services/${serviceId}/plans/${plan.id}`, {
            meta
        })
    }

    // Plan Deliverables
    async createPackageItem(serviceId, planId, packageItem, meta) {
        return await this.__axiosInstance.post(`api/v1/services/${serviceId}/plans/${planId}/package`, packageItem, {
            meta
        })
    }

    async updatePackageItem(serviceId, planId, packageItem = {}, meta) {
        return await this.__axiosInstance.put(`api/v1/services/${serviceId}/plans/${planId}/package/${packageItem.id}`, packageItem, {
            meta
        });
    }

    async deletePackageItem(serviceId, planId, packageItem = {}, meta) {
        return await this.__axiosInstance.delete(`api/v1/services/${serviceId}/plans/${planId}/package/${packageItem.id}`, {
            meta
        });
    }

}
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app-icon-container"},[_c('v-btn',{staticClass:"mx-2 app-icon",attrs:{"id":'app-bar-' + _vm.value.key,"dark":"","small":"","color":_vm.value.display.color ? _vm.value.display.color : 'transparent app-icon'},on:{"click":() => _vm.$emit('click'),"mouseover":() => {
        _vm.tooltip = true;
        _vm.$emit('mouseover', _vm.value);
      },"mouseleave":() => {
        _vm.tooltip = false;
        _vm.$emit('mouseleave', _vm.value);
      }}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" "+_vm._s(_vm.value.display.icon)+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }